import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import TopLayout from "../../components/Dashboard/toplayout";
import DashboardLayout from "../../components/Dashboard/layout";
import { useSettingsData } from "../../hooks/useSettingsData";
import { useSearchParams } from "react-router-dom";

const Settings = () => {
  const { tabs } = useSettingsData();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get("keyword");
  const subscription = searchParams.get("subscription");

  const [activeTabIndex, setActiveTabIndex] = useState(
    (keyword ? 1 : 0) || (subscription ? 3 : 0)
  );

  return (
    <DashboardLayout title="Account Settings">
      <div className="p-4 md:p-8 w-full">
        <div className="hidden md:block">
          <TopLayout title="Account Settings" />
        </div>
        <div className="">
          <Tabs
            selectedIndex={activeTabIndex}
            onSelect={(index) => setActiveTabIndex(index)}
          >
            <TabList>
              {tabs.map((item, index) => {
                return (
                  <Tab key={index}>
                    <p className="text-sm md:text-base hidden md:block">
                      {item.title}
                    </p>
                    <p className="text-sm md:text-base block md:hidden">
                      {item.short}
                    </p>
                  </Tab>
                );
              })}
            </TabList>

            {tabs.map((item, index) => {
              return (
                <TabPanel key={index}>
                  <div className="pt-4">{item.tabpanel}</div>
                </TabPanel>
              );
            })}
          </Tabs>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Settings;
