import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Paper,
  TablePagination,
  Button,
  Box,
  Typography,
  Tooltip,
  Checkbox,
  ListItemText,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { sendTakeDown, startTakeDown } from "../../api";
import SnackBarWrapper from "../wrappers/snackbar-wrapper";

const TakeDownDomainTable = ({ analysis_id, monitoringRespones, onSelectedRowsChange, hasPOA, uploadedFile }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([]);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState({});
  const [takedownId, setTakeDownId] = useState("");

  const blacklistOptions = ["Google", "Microsoft", "Spamhaus"];

  const [error, setError] = useState({ show: false, message: "" });
  const [success, setSuccess] = useState({ show: false, message: "" });

  useEffect(() => {
    if (monitoringRespones) {
      setData(
        monitoringRespones.map((item) => ({
          ...item,
          notifyRegistrar: false,
          notifyHosting: false,
          submitToBlacklist: [],
          showAccordion: false,
          additionalNotes: "",
        }))
      );
    }
  }, [monitoringRespones]);

  const handleSnackbarClose = () => {
    setError({ ...error, show: false });
    setSuccess({ ...success, show: false });
  }

  const handleToggle = (domain, field, value) => {
    const updatedData = data.map((item) =>
      item.domain === domain ? { ...item, [field]: value } : item
    );
    setData(updatedData);
    onSelectedRowsChange(updatedData);
  };

  const handleBlacklistChange = (domain, selectedOptions) => {
    const updatedData = data.map((item) =>
      item.domain === domain ? { ...item, submitToBlacklist: selectedOptions } : item
    );
    setData(updatedData);
  };

  const handleAdditionalNotesChange = (domain, value) => {
    setNotes((prev) => ({ ...prev, [domain]: value }));
  };

  const handleTakeDown = (row) => {
    const formData = new FormData();
    formData.append("analysis_id", analysis_id);
    formData.append("domain", row.domain);
    formData.append("registrar", row.notifyRegistrar);
    formData.append("hosting", row.notifyHosting);
    formData.append("blacklists", row.submitToBlacklist);

    if (uploadedFile) {
      formData.append("signed_power", uploadedFile);
    }

    setLoading(true)

    startTakeDown(formData).then((response) => {
      setResponseData((prev) => ({
        ...prev,
        [row.domain]: response?.data,
      }));

      setData((prev) =>
        prev.map((item) =>
          item.domain === row.domain ? { ...item, showAccordion: true } : item
        )
      );
      setLoading(false)
      setTakeDownId(response?.data?.uid);
      setSuccess({ show: true, message: "Take-down started successfully" });
    })
      .catch((error) => {
        setLoading(false)
        if (
          error.response.status === 400 &&
          Object.keys(error.response.data).includes("signed_power")
        ) {
          setError({ show: true, message: error.response.data.signed_power });
        }
      });
  };

  const handleSendTakeDown = (monitor) => {
    const additionalNotes = notes[monitor.domain] || "";

    const data = {
      additional_notes: additionalNotes
    }

    setLoading(true)
    sendTakeDown(takedownId, data).then((response) => {
      setLoading(false)
      setSuccess({ show: true, message: "Take-down created successfully" });
    }).catch((error) => {
      setLoading(false)
      setError({ show: true, message: "Something went wrong. Please try again or contact support." });
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentRows = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
        </Box>
      ) : (

        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Domain</TableCell>
                  <TableCell>Notify Registrar</TableCell>
                  <TableCell>Notify Hosting Provider</TableCell>
                  <TableCell>Submit to Blacklist</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRows.map((row) => {
                  const isTakeDownDisabled = !hasPOA || (!row.notifyRegistrar && !row.notifyHosting);

                  return (
                    <React.Fragment key={row.domain}>
                      <TableRow>
                        <TableCell>
                          <Typography>{row.domain}</Typography>
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={row.notifyRegistrar}
                            onChange={(e) =>
                              handleToggle(row.domain, "notifyRegistrar", e.target.checked)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={row.notifyHosting}
                            onChange={(e) =>
                              handleToggle(row.domain, "notifyHosting", e.target.checked)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Select
                            multiple
                            value={row.submitToBlacklist}
                            onChange={(e) =>
                              handleBlacklistChange(row.domain, e.target.value)
                            }
                            renderValue={(selected) => selected.join(", ")}
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            {blacklistOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                <Checkbox
                                  checked={row.submitToBlacklist.includes(option)}
                                />
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              isTakeDownDisabled
                                ? !hasPOA
                                  ? "Please upload a power of attorney document"
                                  : "Enable Notify Registrar or Notify Hosting Provider to proceed"
                                : ""
                            }
                          >
                            <span>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleTakeDown(row)}
                                disabled={isTakeDownDisabled}
                              >
                                Request Take-Down
                              </Button>
                            </span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      {row.showAccordion && (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Response Details</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box display="flex" gap={2}>
                                  {/* Domain Data */}
                                  <Box flex={1}>
                                    <Typography variant="subtitle1" gutterBottom>
                                      Domain Data
                                    </Typography>
                                    <Typography>
                                      <strong>Malicious Domain:</strong>{" "}
                                      {responseData[row.domain]?.malicious_domain}
                                    </Typography>
                                    <Typography>
                                      <strong>Original Domain:</strong>{" "}
                                      {responseData[row.domain]?.original_domain}
                                    </Typography>
                                  </Box>

                                  {/* Screenshot */}
                                  <Box flex={1}>
                                    <Typography variant="subtitle1" gutterBottom>
                                      Screenshots
                                    </Typography>
                                    <img
                                      src={
                                        responseData[row.domain]?.malicious_screenshot_link
                                      }
                                      alt="Malicious Screenshot"
                                      width="100%"
                                      style={{ marginBottom: "8px" }}
                                    />
                                    <img
                                      src={
                                        responseData[row.domain]?.original_screenshot_link
                                      }
                                      alt="Original Screenshot"
                                      width="100%"
                                    />
                                  </Box>

                                  {/* Additional Notes */}
                                  <Box flex={1}>
                                    <Typography variant="subtitle1" gutterBottom>
                                      Additional Notes
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      multiline
                                      rows={4}
                                      placeholder="Enter additional notes..."
                                      value={notes[row.domain] || ""}
                                      onChange={(e) =>
                                        handleAdditionalNotesChange(
                                          row.domain,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      sx={{ mt: 2 }}
                                      onClick={() =>
                                        handleSendTakeDown(row)
                                      }
                                    >
                                      Create Takedown
                                    </Button>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={data.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[50, 100]}
          />
        </Paper>
      )}

      {error.show &&
        <SnackBarWrapper onSnackbarClose={handleSnackbarClose} status={"error"} message={error.message} />
      }

      {success.show &&
        <SnackBarWrapper onSnackbarClose={handleSnackbarClose} status={"success"} message={success.message} />
      }
    </>
  );
};

export default TakeDownDomainTable;
