import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import editicon from "../../../assets/images/icons/edit.png";
import trashicon from "../../../assets/images/icons/trash.png";
import { EditorState } from "draft-js";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

const EmailTemplateSettings = () => {
  const data = [
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
    {
      subject: "Luctus et cras velit arcu vitae consequat erat.",
      body: "Nisi aliquam massa in sagittis praesent mauris. Sagittis et cras enim nulla pulvinar eleifend molestie etiam. Vitae molestie lectus a purus commodo at amet hendrerit. Ipsum sed ac quam enim sit et justo odio. Elit nulla eu tempor amet bibendum fusce. Adipiscing elit lectus eu feugiat urna risus ultrices augue. Id quis magna nibh sed mi etiam pretium. Pellentesque augue mattis amet nibh mauris fringilla commodo nisl consequat. Facilisis malesuada quis faucibus arcu augue id. Varius cras mauris lacus aliquet purus tincidunt integer felis.",
      createddate: "Jan 24, 2024",
      updateddate: "Apr 24, 2024",
    },
  ];

  const [isOpen, setOpen] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleClick = () => {
    setOpen(!isOpen);
  };

  const [tbData, setTbData] = useState(data.slice(0, 5));

  const totalPages = Math.ceil(data.length / 5);
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(page) {
    setCurrentPage(page);
    setTbData(data.slice((page - 1) * 5, page * 5));
  }

  return (
    <div>
      {!isOpen ? (
        <div>
          <button
            onClick={handleClick}
            className="before:ease relative h-[40px] min-w-[200px] px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-3xl hover:before:-translate-x-40"
          >
            <span relative=" text-sm lg:text-base relative z-10">
              Add Email template
            </span>
          </button>
          <div className="pt-4 rounded-xl min-h-[500px] overflow-scroll md:overflow-hidden">
            <table className="rounded-xl shadow-xl table-fixed overflow-hidden w-max md:w-full">
              <thead className="text-start h-[60px] rounded-xl bg-light">
                <tr>
                  <th className="md:w-[360px] text-start px-3">subject</th>
                  <th className="text-start px-3" width="600px">
                    Body
                  </th>
                  <th className="md:w-[150px] text-start px-3">Created Date</th>
                  <th className="md:w-[150px] text-start px-3">Updated Date</th>
                  <th className="md:w-[100px] text-start px-3">Action</th>
                </tr>
              </thead>
              <tbody>
                {tbData.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className="odd:bg-white even:bg-slate-50 h-[80px] text-grey"
                    >
                      <td className="px-3">{item.subject}</td>
                      <td className="px-3">
                        <p className=" line-clamp-2">{item.body}</p>
                      </td>
                      <td className="px-3">{item.createddate}</td>
                      <td className="px-3">{item.updateddate}</td>
                      <td>
                        <div className="flex gap-2 items-center ">
                          <img src={editicon} alt="" />
                          <img src={trashicon} alt="" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
                <tr></tr>
              </tbody>
            </table>
          </div>
          <div className="flex justify-end pr-[45px]">
            <div className="w-[300px]">
              <ResponsivePagination
                total={totalPages}
                current={currentPage}
                onPageChange={(page) => handlePageChange(page)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="pt-4 pb-8">
            <div className="border border-[#F1F1F1] rounded-lg p-4 h-[50vh]">
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
              />
            </div>
          </div>
          <div className="flex justify-end items-center gap-4">
            <button
              onClick={handleClick}
              className="before:ease relative h-[36px] md:h-[40px] w-auto md:min-w-[150px] px-4 md:px-8 rounded-full overflow-hidden bg-black text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-black hover:before:-translate-x-40"
            >
              <span relative=" text-sm lg:text-base relative z-10">Cancel</span>
            </button>
            <button
              onClick={handleClick}
              className="before:ease relative h-[36px] md:h-[40px] w-auto md:min-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
            >
              <span relative=" text-sm lg:text-base relative z-10">
                Create template
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailTemplateSettings;
