import { Line } from "rc-progress";
import React, { useState, useEffect } from "react";

const CustomProgressBar = ({ value, status, count, setCount, isLoading }) => {
  useEffect(() => {
    if (count !== value && !isLoading) {
      const timer = setInterval(() => {
        if (count < value) {
          setCount((prevCount) => {
            const newCount = prevCount + 469;
            return newCount > value ? value : newCount;
          });
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [count, value, isLoading, setCount]);

  return (
    <Line
      percent={status && !isLoading ? parseInt((count / value) * 100) : 100}
      strokeWidth={2}
      strokeColor={isLoading ? "grey" : "#30AFEE"}
    />
  );
};

const ProgressBar = (props) => {
  const [count, setCount] = useState(0);

  return (
    <div>
      <div className="flex justify-between pb-2">
        <p className="text-xs lg:text-sm">{props.label}</p>
        <p className="text-xs lg:text-sm font-semibold">
          {props.isLoading ? 0 : props.status ? count : props.total}
        </p>
      </div>
      <div className="flex flex-col gap-6 w-full">
        <CustomProgressBar
          value={props.total}
          status={props.status}
          isLoading={props.isLoading}
          count={count}
          setCount={setCount}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
