import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Switch,
    Button,
    Collapse,
    IconButton,
    TextField,
    FormControlLabel,
    LinearProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSelector } from 'react-redux';
import { getFileFromLocalStorage } from '../../utiles/general';
import { startSetupMonitor } from '../../api';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

const MonitoringSection = ({
    title,
    description,
    isExpanded,
    onToggle,
    tooltipText,
    children,
    isAllSelected,
    onSelectAll
}) => (
    <Box sx={{
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        '&:last-child': {
            borderBottom: 'none'
        }
    }}>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            cursor: 'pointer',
            '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.02)'
            }
        }}
            onClick={onToggle}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    size="small"
                    sx={{ mr: 1, color: 'text.secondary' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onToggle();
                    }}
                >
                    {isExpanded ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
                <Typography variant="body1" sx={{ mr: 1 }}>{title}</Typography>
                {tooltipText && (
                    <Tooltip title={tooltipText}>
                        <HelpOutlineIcon sx={{ color: 'grey', fontSize: 20 }} />
                    </Tooltip>
                )}
            </Box>
            <FormControlLabel
                control={
                    <Switch
                        checked={isAllSelected}
                        onChange={(e) => {
                            e.stopPropagation();
                            onSelectAll(e.target.checked);
                        }}
                        size="small"
                    />
                }
                label="Select All"
                sx={{ mr: 0 }}
            />
        </Box>
        <Collapse in={isExpanded}>
            <Box sx={{ p: 2, bgcolor: 'rgba(0, 0, 0, 0.02)' }}>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {description}
                </Typography>
                {children}
            </Box>
        </Collapse>
    </Box>
);

const ToggleOption = ({ label, checked, onChange, tooltipText }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">{label}</Typography>
            {tooltipText && (
                <Tooltip title={tooltipText}>
                    <HelpOutlineIcon sx={{ color: 'grey', fontSize: 16, ml: 1 }} />
                </Tooltip>
            )}
        </Box>
        <Switch
            checked={checked}
            onChange={onChange}
            size="small"
        />
    </Box>
);

export default function MonitorModal({ open, onClose, selectedDomains, onMonitorsEnabled }) {
    const [expandedSections, setExpandedSections] = useState({});
    const { analysis_id } = useParams();

    const configConditions = useSelector(state => state.scan.config.alert_conditions);

    const [conditions, setConditions] = useState({
        content_change_and_spoof: false,
        a_record_change: false,
        mx_record_change: false,
        whois_change: false,
        new_similar_domain: false,
        port_change: false,
        phishing_status: false,
        urlscore_risk_change: false,
        content_change: false,
        is_spoofing: false,
    });

    const stateBrand = useSelector(state => state.scan.brand);
    const stateBrandLogo = useSelector(state => state.scan.brandLogo)

    const [loading, isLoading] = useState(false);

    const [brandInfo, setBrandInfo] = useState({
        brandName: '',
        logo: null
    });


    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const handleConditionChange = (condition) => (event) => {
        setConditions(prev => ({
            ...prev,
            [condition]: event.target.checked
        }));
    };

    const handleSelectAll = (sectionId, isSelected) => {
        const sectionConditions = {
            contentChangedAndSpoofed: ['content_change_and_spoof'],
            dnsRecords: ['a_record_change', 'mx_record_change'],
            whoisChanges: ['whois_change', 'new_similar_domain'],
            infrastructureMonitoring: ['port_change', 'phishing_status'],
            contentAndRiskAnalysis: ['urlscore_risk_change', 'content_change', 'is_spoofing'],
        };

        setConditions(prev => {
            const newConditions = { ...prev };
            sectionConditions[sectionId].forEach(condition => {
                newConditions[condition] = isSelected;
            });
            return newConditions;
        });
    };

    const isAllSelected = (sectionCode) => {
        const sectionConditions = {
            contentChangedAndSpoofed: ['content_change_and_spoof'],
            dnsRecords: ['a_record_change', 'mx_record_change'],
            whoisChanges: ['whois_change', 'new_similar_domain'],
            infrastructureMonitoring: ['port_change', 'phishing_status'],
            contentAndRiskAnalysis: ['urlscore_risk_change', 'content_change', 'is_spoofing'],
        };

        return sectionConditions[sectionCode].every(condition => conditions[condition]);
    };

    const handleBrandNameChange = (event) => {
        setBrandInfo(prev => ({
            ...prev,
            brandName: event.target.value
        }));
    };

    const handleLogoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setBrandInfo(prev => ({
                ...prev,
                logo: file
            }));
        }
    };

    const monitoringSections = [
        {
            id: 'contentChangedAndSpoofed',
            title: 'Website Content Changed and Spoofed',
            description: 'Track website content changes and detect unauthorized use of your brand or logo',
            tooltipText: 'Detects if website content has been modified and is impersonating the original content'
        },
        {
            id: 'dnsRecords',
            title: 'DNS Record Monitoring',
            description: 'Monitor changes in NS, A, and MX records of the domain.',
            tooltipText: 'Tracks changes in domain name system records'
        },
        {
            id: 'whoisChanges',
            title: 'WHOIS and Domain Changes',
            description: 'Monitor changes in domain registration, ownership information, and similar domains.',
            tooltipText: 'Monitors changes in domain ownership, registration details, and detects similar domains'
        },
        {
            id: 'infrastructureMonitoring',
            title: 'Infrastructure Monitoring',
            description: 'Monitor for new open ports and appearances in phishing databases.',
            tooltipText: 'Tracks infrastructure changes and potential security threats'
        },
        {
            id: 'contentAndRiskAnalysis',
            title: 'Content and Risk Analysis',
            description: 'Monitor changes in URL risk score, website content, and detect spoofing attempts.',
            tooltipText: 'Analyzes website content, risk factors, and potential impersonation'
        }
    ];


    const enableMonitors = async (e) => {
        const formData = new FormData();

        formData.append("brand", stateBrand);

        if (stateBrandLogo) {
            const brandLogo = getFileFromLocalStorage(stateBrandLogo)
            formData.append("logo", brandLogo);
        }

        formData.append("auto_track_new_domains", true);
        formData.append("enabled", true);

        selectedDomains.forEach((domain) => {
            formData.append("domains", domain);
        });

        const conditionsArray = Object.keys(conditions)
            .filter(key => conditions[key]) // Keep only keys with `true` values
            .map(key => key); // Transform the keys into an array

        conditionsArray?.forEach((condition) => {
            formData.append("conditions", condition);
        });

        onMonitorsEnabled(true)

        startSetupMonitor(analysis_id, formData).then((response) => {
            console.log(response)
            onMonitorsEnabled(false)
            enqueueSnackbar("Your monitors have been enabled successfully", {
                variant: "success",
            });
        }).catch((error) => {
            onMonitorsEnabled(false)

            if (error?.response?.status === 400 && error?.response?.data?.details === "The following domains are already being monitored.") {
                enqueueSnackbar("The following domains are already being monitored.", {
                    variant: "error",
                });
            } else {
                enqueueSnackbar("The was an error when trying to enable the domain monitors", {
                    variant: "error",
                });
            }
        })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="monitor-modal-title"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                maxHeight: '90vh',
                overflow: 'auto'
            }}>
                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h5" component="h2" sx={{ mr: 1 }}>
                            Monitor Conditions
                        </Typography>
                        <Tooltip title="Set up monitoring conditions for selected domains">
                            <InfoIcon fontSize="small" sx={{ color: '#1976d2' }} />
                        </Tooltip>
                    </Box>

                    <Box sx={{
                        bgcolor: '#f8f9fa',
                        borderRadius: 1,
                        overflow: 'hidden',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        mb: 3
                    }}>
                        {monitoringSections.map((section) => (
                            <MonitoringSection
                                key={section.id}
                                title={section.title}
                                description={section.description}
                                isExpanded={expandedSections[section.id]}
                                onToggle={() => toggleSection(section.id)}
                                tooltipText={""}
                                isAllSelected={isAllSelected(section.id)}
                                onSelectAll={(isSelected) => handleSelectAll(section.id, isSelected)}
                            >
                                {section.id === 'contentChangedAndSpoofed' && (
                                    <Box>
                                        <ToggleOption
                                            label="Enable monitoring"
                                            checked={conditions.content_change_and_spoof}
                                            onChange={handleConditionChange('content_change_and_spoof')}
                                            tooltipText="Enable monitoring for website content changes and spoofing"
                                        />
                                        {conditions.content_change_and_spoof && !stateBrand && !stateBrandLogo && (
                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    fullWidth
                                                    label="Brand Name"
                                                    variant="outlined"
                                                    value={brandInfo.brandName}
                                                    onChange={handleBrandNameChange}
                                                    sx={{ mb: 2 }}
                                                />
                                                <Button
                                                    component="label"
                                                    variant="outlined"
                                                    startIcon={<CloudUploadIcon />}
                                                    fullWidth
                                                    sx={{
                                                        color: '#1976d2',
                                                        borderColor: '#1976d2',
                                                        '&:hover': {
                                                            borderColor: '#1565c0',
                                                            bgcolor: 'rgba(25, 118, 210, 0.04)',
                                                        },
                                                    }}
                                                >
                                                    Upload Logo
                                                    <input
                                                        type="file"
                                                        hidden
                                                        accept="image/*"
                                                        onChange={handleLogoUpload}
                                                    />
                                                </Button>
                                                {brandInfo.logo && (
                                                    <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                                                        Selected file: {brandInfo.logo.name}
                                                    </Typography>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                {section.id === 'dnsRecords' && (
                                    <Box>
                                        {/* <ToggleOption
                                            label="NS Record Changed"
                                            checked={conditions.nsRecordChanged}
                                            onChange={handleConditionChange('nsRecordChanged')}
                                            tooltipText="Monitor changes in Name Server records"
                                        /> */}
                                        <ToggleOption
                                            label="A Record Changed"
                                            checked={conditions.a_record_change}
                                            onChange={handleConditionChange('a_record_change')}
                                            tooltipText="Monitor changes in A records (IPv4 addresses)"
                                        />
                                        <ToggleOption
                                            label="MX Record Changed"
                                            checked={conditions.mx_record_change}
                                            onChange={handleConditionChange('mx_record_change')}
                                            tooltipText="Monitor changes in Mail Exchanger records"
                                        />
                                    </Box>
                                )}
                                {section.id === 'whoisChanges' && (
                                    <Box>
                                        <ToggleOption
                                            label="WHOIS Info Changed"
                                            checked={conditions.whois_change}
                                            onChange={handleConditionChange('whois_change')}
                                            tooltipText="Monitor changes in domain registration and ownership information"
                                        />
                                        <ToggleOption
                                            label="New Similar Domain Found"
                                            checked={conditions.new_similar_domain}
                                            onChange={handleConditionChange('new_similar_domain')}
                                            tooltipText="Detect registration of new domains similar to the monitored domain"
                                        />
                                    </Box>
                                )}
                                {section.id === 'infrastructureMonitoring' && (
                                    <Box>
                                        <ToggleOption
                                            label="New Open Port 80/443 Appears"
                                            checked={conditions.port_change}
                                            onChange={handleConditionChange('port_change')}
                                            tooltipText="Detect new open ports 80 (HTTP) or 443 (HTTPS)"
                                        />
                                        <ToggleOption
                                            label="Website Appears in a Phishing DB"
                                            checked={conditions.phishing_status}
                                            onChange={handleConditionChange('phishing_status')}
                                            tooltipText="Monitor if the website is listed in known phishing databases"
                                        />
                                    </Box>
                                )}
                                {section.id === 'contentAndRiskAnalysis' && (
                                    <Box>
                                        <ToggleOption
                                            label="URLScore Risk Score Changed"
                                            checked={conditions.urlscore_risk_change}
                                            onChange={handleConditionChange('urlscore_risk_change')}
                                            tooltipText="Monitor changes in the website's risk score"
                                        />
                                        <ToggleOption
                                            label="Website Content Changed"
                                            checked={conditions.content_change}
                                            onChange={handleConditionChange('content_change')}
                                            tooltipText="Detect changes in the website's content"
                                        />
                                        <ToggleOption
                                            label="Website is Spoofed"
                                            checked={conditions.is_spoofing}
                                            onChange={handleConditionChange('is_spoofing')}
                                            tooltipText="Detect if the website is being impersonated or spoofed"
                                        />
                                    </Box>
                                )}
                            </MonitoringSection>
                        ))}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "100%" }}>
                        <Button
                            variant="contained"
                            onClick={() => enableMonitors()}
                            sx={{
                                bgcolor: '#1976d2',
                                color: 'white',
                                '&:hover': { bgcolor: '#1565c0' },
                                textTransform: 'none',
                                px: 4
                            }}
                        >
                            Enable Monitors
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

