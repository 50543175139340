import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import { dataPricing, plansData, reviewsData } from "../data/pricingData";
import ReviewCard from "../components/common/reviewcard";
import ArrowRight from "../assets/images/icons/arrow-right.png";
import ArrowLeft from "../assets/images/icons/arrow-left.png";
import PlanCard from "../components/common/planCard";
import { useStripeCheckout } from "../hooks/useCheckout";
import axios from "axios";

const Pricing = () => {
  const [subscriptionData, setSubscriptionData] = useState({});

  const { handleSubmit } = useStripeCheckout();

  const getSubscription = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/memberships/`
      );
      setSubscriptionData(response.data.current);
    } catch (error) {}
  };

  useEffect(() => {
    getSubscription();
  }, []);

  const styles = {
    titleTextColor: "black",
    rowTitleColor: "black",
    rowContentColor: "grey",
    arrowColor: "#30AFEE",
    titleText: "center",
  };

  const config = {
    animate: true,
    expandIcon: "+",
    collapseIcon: "-",
    openOnload: 0,
  };

  const [index, setIndex] = useState(0);
  const { name, job, image, text } = reviewsData[index];

  const checkNumber = (number) => {
    if (number > reviewsData.length - 1) {
      return 0;
    } else if (number < 0) {
      return reviewsData.length - 1;
    }
    return number;
  };

  const nextPerson = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };

  const prevPerson = () => {
    setIndex((index) => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    });
  };

  return (
    <div>
      <div className="max-w-xl lg:max-w-6xl mx-auto py-[3rem] lg:py-[5rem]">
        <div>
          <p className="text-2xl md:text-4xl font-bold pb-[1rem]">Pricing</p>
          <p className="">
          Transparent and affordable pricing with no hidden fees. Choose between monthly subscriptions or opt for an annual plan to receive a 10% discount. If our standard offers don't meet your specific needs, please reach out to us at info@spoofguard.io to discuss customized options
          </p>
        </div>

        <div className="grid grid-cols-3 gap-4 pt-[3rem] lg:pt-[5rem]">
          {plansData.map((item, index) => (
            <PlanCard
              key={index}
              userHasPlan={subscriptionData && subscriptionData.is_active}
              item={item}
              subscriptionData={subscriptionData}
              onSelect={() => handleSubmit(item)}
            />
          ))}
        </div>
      </div>
      <div className="bg-light">
        <div className="max-w-xl lg:max-w-6xl mx-auto py-[3rem] lg:py-[5rem]">
          <div className="flex justify-center">
            <div className="bg-primary rounded-full py-2 w-[150px]">
              <p className="text-sm text-white text-center">Reviews</p>
            </div>
          </div>
          <div className="text-2xl md:text-4xl font-bold pt-2 pb-8 text-center">
            What people say?
          </div>
          <div className="flex items-center justify-center gap-16">
            <button onClick={prevPerson}>
              <div className="w-[50px] h-[50px] rounded-full bg-white flex items-center justify-center">
                <img src={ArrowLeft} alt="" />
              </div>
            </button>
            <ReviewCard name={name} job={job} image={image} text={text} />
            <button onClick={nextPerson}>
              <div className="w-[50px] h-[50px] rounded-full bg-white flex items-center justify-center">
                <img src={ArrowRight} alt="" />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="py-[3rem] lg:py-[5rem] max-w-xl lg:max-w-6xl mx-auto">
        <Faq data={dataPricing} styles={styles} config={config} />
      </div>
    </div>
  );
};

export default Pricing;
