import startupplan from "../assets/images/icons/startup.png";
import standardplan from "../assets/images/icons/standard.png";
import premiumplan from "../assets/images/icons/premium.png";

export const reviewsData = [
  {
    id: 1,
    name: "J. Pence",
    job: "MSSP Australia",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883334/person-1_rfzshl.jpg",
    text: "Spoofguard delivers as promised.",
  },
  {
    id: 2,
    name: "Anonymous",
    job: "",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883409/person-2_np9x5l.jpg",
    text: "Good domain monitoring tool that helped us prevent already one phishing attack.",
  },
  {
    id: 3,
    name: "Magnus K.",
    job: "SOC, Swiss Bank",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg",
    text: "I recently integrated Spoofguard API into our SOC, and the results have been very promising.",
  }
];
export const plansData = [
  {
    name: "Startup",
    icon: startupplan,
    price_id: "price_1QFEpjC8cZhx2iABwYsab75s",
    link: "https://buy.stripe.com/6oEdRedsyb5m2mQ6oo",
    price: 200,
    description: "Spoofguard Startup Monthly Subscription.",
    features: [
      {
        title: "Monitor up to 3 domains",
        value: true,
      },
      {
        title: "Take Down up to 2 Domains per month",
        value: true,
      },
      {
        title: "Advanced Spoofing Detection Enabled*",
        value: true,
      },
    ],
    currentplan: true,
    expiredtime: 3,
  },
  {
    name: "Standard",
    icon: standardplan,
    price_id: "price_1QFEq5C8cZhx2iABNy3CpQYZ",
    link: "https://buy.stripe.com/eVa7sQcou0qI5z2aEH",
    price: 300,
    description: "Spoofguard Standard Monthly Subscription.",
    features: [
      {
        title: "Monitor up to 10 domains",
        value: true,
      },
      {
        title: "Take Down up to 4 Domains per month",
        value: true,
      },
      {
        title: "Advanced Spoofing Detection Enabled*",
        value: true,
      },
    ],
    currentplan: false,
    expiredtime: 3,
  },
  {
    name: "Premium",
    icon: premiumplan,
    price: 400,
    price_id: "price_1QFEqRC8cZhx2iABKfhg1Hxc",
    description: "Spoofguard Premium Monthly Subscription.",
    features: [
      {
        title: "Monitor up to 50 domains",
        value: true,
      },
      {
        title: "Take Down up to 20 Domains per month",
        value: true,
      },
      {
        title: "Advanced Spoofing Detection Enabled*",
        value: true,
      },
    ],
    currentplan: false,
    expiredtime: 3,
  },
];

export const dataPricing = {
  title: "Frequently asked questions",
  rows: [
    {
      title: "Can I obtain a custom pricing model?",
      content: `Absolutely! Please contact our sales team at info@spoofguard.io for personalized pricing options.`,
    },
    {
      title: "What happens if I upgrade during my subscription period?",
      content:
        "When you upgrade, the full amount previously paid will be credited towards your new plan.",
    },
    {
      title: "Does my subscription automatically renew?",
      content: `Yes, both yearly and monthly subscriptions automatically renew. You may cancel up to 5 days before your subscription’s expiry. We'll send reminders 15 days and again 6 days before renewal to give you ample notice.`
    },
    {
      title: "Can I trial SpoofGuard before committing?",
      content: "While we do not offer free trials, you can purchase a one-month subscription to fully evaluate our tool’s capabilities.",
    },
  ],
};
