import moment from "moment";
import csvicon from "../../assets/images/icons/csv.png";
import pdficon from "../../assets/images/icons/pdf.png";

const ReportsTable = ({
  analysisResult,
  currentPage,
  selectedItems,
  selectAll,
  handleSelectAll,
  handleCheckboxChange,
  handleExportCSV,
  handleDownloadCSVSecond,
}) => {
  return (
    <div className="pt-4 md:pt-8 rounded-xl w-full min-h-[600px] overflow-scroll md:overflow-hidden">
      <table className="rounded-xl shadow-xl table-fixed overflow-hidden w-max md:w-full">
        <thead className="text-start h-[60px] rounded-xl bg-light">
          <tr>
            <th className="w-[20px] px-3">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th className="text-start px-3">Domain</th>
            <th className="text-start px-3 ">Date</th>
            <th className="text-start px-3 ">Type</th>
            <th className="text-start px-3 ">Number of Records</th>
            <th className="text-center px-3 ">Download Domain V.</th>
            <th className="text-center px-3 ">Download Analysis R.</th>
          </tr>
        </thead>
        <tbody className="w-full justify-center items-center">
          {Array.isArray(analysisResult) && analysisResult.length > 0 ? (
            analysisResult
              .slice((currentPage - 1) * 10, currentPage * 10)
              .map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="odd:bg-white even:bg-slate-50 h-[50px] text-grey"
                  >
                    <td className="px-3">
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item.uid)}
                        onChange={() => handleCheckboxChange(item.uid)}
                      />
                    </td>
                    <td className="px-3">{item.domain}</td>
                    <td className="px-3">
                      <p>{moment(item.created_at).format("DD-MM-YYYY")}</p>
                    </td>
                    <td className="px-3">CSV</td>
                    <td className="px-3">{item.total_initial_done}</td>
                    <td>
                      <div className="flex justify-center items-center gap-2">
                        {item.type === "pdf" ? (
                          <img src={pdficon} alt="PDF" className="w-6 h-6" />
                        ) : (
                          <img
                            src={csvicon}
                            alt="CSV"
                            className="cursor-pointer w-6 h-6"
                            onClick={() => handleExportCSV(item.uid)}
                          />
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center items-center gap-2">
                        {item.type === "pdf" ? (
                          <img src={pdficon} alt="PDF" className="w-6 h-6" />
                        ) : (
                          <img
                            src={csvicon}
                            alt="CSV"
                            className="cursor-pointer w-6 h-6"
                            onClick={() => {
                              handleDownloadCSVSecond(item.uid);
                            }}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
          ) : (
            <div className="text-grey   w-[300px] text-center ">
              There is no data.
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ReportsTable;
