import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import activateFailed from "../assets/images/activatefailed.png";
import activateSuccess from "../assets/images/activatesuccess.png";
import ShadowBlog from "../components/common/shadowBlog";
import { activateaccount } from "../actions/accountActions";

const Accountactivate = () => {
  const { id, token } = useParams();
  const dispatch = useDispatch();

  const [isSuccess, setSuccess] = useState();

  const data = {
    uid: id,
    token: token,
  };

  const handleVerify = async () => {
    try {
      await dispatch(activateaccount(data));
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
    }
  };

  useEffect(() => {
    handleVerify();
  }, []);

  return (
    <div className="min-h-[100vh] bg-auth flex items-center justify-center">
      <div className=" w-[100vw] lg:w-[500px]">
        {isSuccess === true ? (
          <ShadowBlog>
            <div className="flex flex-col item-center gap-4">
              <div className="flex justify-center">
                <img src={activateSuccess} alt="" className="w-[200px]" />
              </div>
              <p className="font-bold text-2xl lg:text-4xl text-center">
                Verify success
              </p>
              <p className="text-center">
                We activated your account. Please login with your email.
              </p>
              <div className="flex justify-center">
                <a
                  href="/login"
                  className="flex items-center text-sm lg:text-base bg-primary text-white max-w-[200px] px-8 h-[40px] rounded-full"
                >
                  Login
                </a>
              </div>
            </div>
          </ShadowBlog>
        ) : isSuccess === false ? (
          <ShadowBlog>
            <div className="flex flex-col item-center gap-4">
              <div className="flex justify-center">
                <img src={activateFailed} alt="" className="w-[200px]" />
              </div>
              <p className="font-bold text-2xl lg:text-4xl text-center">
                Verify Failed
              </p>
              <p className="text-center">
                We send an activation link to your email address. please confirm
                the link to proceed.
              </p>
              <div className="flex justify-center">
                <a
                  onClick={() => {
                    localStorage.setItem("activate", true);
                    window.location.href = "/";
                  }}
                  className="flex items-center text-sm lg:text-base bg-primary text-white max-w-[200px] px-8 h-[40px] rounded-full"
                >
                  Sure!
                </a>
              </div>
            </div>
          </ShadowBlog>
        ) : (
          <ShadowBlog>
            <div className="min-h-[300px] flex flex-col justify-center items-center">
              <FallingLines
                color="#30AFEE"
                width="100"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
              <p className="text-base md:text-lg font-bold">
                Activating now. Please wait....
              </p>
            </div>
          </ShadowBlog>
        )}
      </div>
    </div>
  );
};

export default Accountactivate;
