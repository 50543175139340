import React, { useState } from "react";
import moment from "moment";
import view from "../../../assets/images/icons/view.png";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

const AnalysisTable = ({
  filteredResult,
  currentPage,
  handleCheckboxChange,
  selectedItems,
  handleSelectAll,
  selectAll,
  handleImageView,
}) => {
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");

  const handleSort = (column) => {
    const newDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortDirection(newDirection);
  };

  const sortData = (data, column, direction) => {
    if (!column) return data;

    return [...data].sort((a, b) => {
      const aValue = a[column] || "N/A";
      const bValue = b[column] || "N/A";

      if (aValue === "N/A") return 1;
      if (bValue === "N/A") return -1;

      if (direction === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  };

  const sortedResult = sortData(filteredResult, sortColumn, sortDirection);

  return (
    <div className="pt-4 rounded-xl w-full overflow-x-scroll">
      <div className="overflow-x-auto">
        <table className="table-auto rounded-xl shadow-xl hover:shadow-2xl w-full ">
          <thead className="bg-light text-start h-[60px]">
            <tr>
              <th className="pl-3 w-[40px]">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th
                className="text-start px-3 cursor-pointer min-w-[150px]"
                onClick={() => handleSort("detected_domain")}
              >
                <div className="flex items-center w-[150px] gap-2">
                  Detected Domain
                  {sortColumn === "detected_domain" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 cursor-pointer min-w-[100px]"
                onClick={() => handleSort("brand")}
              >
                <div className="flex items-center  justify-between w-[100px] gap-2">
                  Brand
                  {sortColumn === "brand" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 cursor-pointer min-w-[100px]"
                onClick={() => handleSort("date")}
              >
                <div className="flex items-center justify-between w-[100px] gap-2">
                  Date
                  {sortColumn === "date" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 min-w-[120px] cursor-pointer"
                onClick={() => handleSort("a_record")}
              >
                <div className="flex items-center justify-between w-[120px] gap-2">
                  A
                  {sortColumn === "a_record" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>

              <th
                className="text-start px-3 min-w-[100px] cursor-pointer"
                onClick={() => handleSort("mx_record")}
              >
                <div className="flex justify-between items-center w-[250px] gap-2">
                  MX
                  {sortColumn === "mx_record" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 min-w-[120px] cursor-pointer"
                onClick={() => handleSort("ns_record")}
              >
                <div className="flex justify-between items-center w-[200px] gap-2">
                  NS
                  {sortColumn === "ns_record" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 min-w-[120px] cursor-pointer"
                onClick={() => handleSort("enumeration")}
              >
                <div className="flex justify-between items-center  min-w-[120px] gap-2">
                  Enumeration
                  {sortColumn === "enumeration" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 min-w-[140px] cursor-pointer"
                onClick={() => handleSort("whois")}
              >
                <div className="flex items-center justify-between min-w-[120px] gap-2">
                  WHOIS
                  {sortColumn === "whois" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 min-w-[120px] cursor-pointer"
                onClick={() => handleSort("web")}
              >
                <div className="flex justify-between items-center w-[100px] gap-2">
                  WEB
                  {sortColumn === "web" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 min-w-[120px] cursor-pointer"
                onClick={() => handleSort("category")}
              >
                <div className="flex items-center w-[100px] gap-2">
                  Category
                  {sortColumn === "category" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 min-w-[120px] cursor-pointer"
                onClick={() => handleSort("spoofing")}
              >
                <div className="flex items-center w-[100px] gap-2">
                  Spoofing
                  {sortColumn === "spoofing" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 min-w-[80px] cursor-pointer"
                onClick={() => handleSort("risk")}
              >
                <div className="flex items-center w-[100px] gap-2">
                  Risk
                  {sortColumn === "risk" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>

              <th
                className="text-start px-3 min-w-[80px] cursor-pointer"
                onClick={() => handleSort("logoSpoofing")}
              >
                <div className="flex items-center w-[100px] gap-2">
                  Logo spoofing
                  {sortColumn === "logoSpoofing" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th
                className="text-start px-3 min-w-[80px] cursor-pointer"
                onClick={() => handleSort("urlscore")}
              >
                <div className="flex items-center w-[100px] gap-2">
                  Urlscore
                  {sortColumn === "urlscore" ? (
                    sortDirection === "asc" ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </div>
              </th>
              <th className="text-start px-3 min-w-[100px]">Screenshot</th>
            </tr>
          </thead>
          <tbody>
            {sortedResult &&
              sortedResult.length > 0 &&
              sortedResult
                .slice((currentPage - 1) * 10, currentPage * 10)
                .map((item, index) => (
                  <tr
                    key={index}
                    className="odd:bg-white min-w-[120px] even:bg-slate-50 h-[50px] text-grey"
                  >
                    <td className="px-3">
                      <input
                        type="checkbox"
                        checked={
                          selectedItems && selectedItems.includes(item.id)
                        }
                        onChange={() =>
                          handleCheckboxChange(item.id, item.detected_domain)
                        }
                      />
                    </td>
                    <td className="px-3 w-[120px]">{item.detected_domain}</td>
                    <td className="px-3 w-[120px]">
                      {item.brand == null ? "N/A" : item.brand}
                    </td>
                    <td className="px-3 w-[120px]">
                      {moment(item.date).format("DD-MM-YYYY")}
                    </td>
                    <td className="px-3 w-[120px]">{item.a_record || "N/A"}</td>

                    <td className="px-3 w-[120px]">
                      {item.mx_record || "N/A"}
                    </td>
                    <td className="px-3 w-[120px]">
                      {item.ns_record || "N/A"}
                    </td>
                    <td className="px-3 w-[120px]">
                      {item.enumeration || "N/A"}
                    </td>
                    <td className="px-3 min-w-[120px]">
                      {item.whois || "N/A"}
                    </td>
                    <td className="px-3 w-[120px]">
                      {item.web.length > 0 ? item.web.join(", ") : "N/A"}
                    </td>
                    <td className="px-3 w-[120px]">
                      {item.category == null ? "N/A" : item.category}
                    </td>
                    <td className="px-3 w-[120px]">
                      {item.spoofing == null ? "N/A" : item.spoofing.toString()}
                    </td>
                    <td className="px-3 w-[120px]">
                      {(item.risk == null) | (item.risk === 0)
                        ? "N/A"
                        : item.risk}
                    </td>

                    <td className="px-3 w-[120px]">
                      {item.urlscore == null ? "N/A" : item.urlscore}
                    </td>

                    <td className="px-3 w-[120px]">
                      {item.logo_spoofing == null
                        ? "N/A"
                        : item.logo_spoofing.toString()}
                    </td>
                    <td className="px-3 w-[120px] text-center">
                      {item.screenshot ? (
                        <button
                          className="flex justify-center cursor-pointer border-0 bg-none"
                          onClick={() => handleImageView(item.screenshot)}
                        >
                          <img src={view} alt="viewImage" />
                        </button>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      {sortedResult && sortedResult.length === 0 && (
        <div className="min-h-[150px] flex justify-center items-center">
          <div className="text-grey">There is no data.</div>
        </div>
      )}
    </div>
  );
};

export default AnalysisTable;
