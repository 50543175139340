import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import * as Yup from "yup";

import lock from "../../../assets/images/icons/lock-svgrepo-com.png";
import unlock from "../../../assets/images/icons/unlock-svgrepo-com.png";
import ShadowBlog from "../../../components/common/shadowBlog";

const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOldPassword, setOldPassword] = useState(false);
  const [isNewPassword, setNewPassword] = useState(false);
  const [isConfirmPassword, setConfirmPassword] = useState(false);

  const handleOldPassword = () => {
    setOldPassword(!isOldPassword);
  };

  const handleNewPassword = () => {
    setNewPassword(!isNewPassword);
  };

  const handleConfirmPassword = () => {
    setConfirmPassword(!isConfirmPassword);
  };

  return (
    <div>
      <ShadowBlog>
        <Formik
          initialValues={{
            old_password: "",
            new_password1: "",
            new_password2: "",
          }}
          validationSchema={Yup.object().shape({
            old_password: Yup.string()
              .min(7)
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                "Password must contain at least one uppercase letter, one lowercase letter, and one number"
              )
              .max(255)
              .required("Password is required"),
            new_password1: Yup.string()
              .min(7)
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                "Password must contain at least one uppercase letter, one lowercase letter, and one number"
              )
              .max(255)
              .required("New Password is required"),
            new_password2: Yup.string()
              .min(7)
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                "Password must contain at least one uppercase letter, one lowercase letter, and one number"
              )
              .max(255)
              .required("Confirm Password is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (values.new_password2 !== values.new_password1) {
                enqueueSnackbar("Not matched Password!", {
                  variant: "error",
                });
              } else {
                // await dispatch(changepassword(values));
                enqueueSnackbar("Password Change Success!", {
                  variant: "success",
                });
              }
            } catch (error) {
              const message = "Old Password is Wrong!";
              enqueueSnackbar(message, {
                variant: "error",
              });
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col item-center gap-4">
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <label className="pb-1 text-sm pl-4">Old Password</label>
                    <div className="relative">
                      <input
                        className="w-full h-[40px] rounded-full border-[0.1px] px-6"
                        name="old_password"
                        type={!isOldPassword ? "password" : "text"}
                        // onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.old_password}
                        placeholder="Old Password"
                        required
                      />
                      {!isOldPassword ? (
                        <img
                          src={lock}
                          className="absolute right-[12px] top-[8px]"
                          alt=""
                          onClick={handleOldPassword}
                        />
                      ) : (
                        <img
                          src={unlock}
                          onClick={handleOldPassword}
                          className="absolute right-[12px] top-[8px]"
                          alt=""
                        />
                      )}
                      {errors.old_password && <p className="text-red px-4 text-xs mt-1">{errors.old_password}</p>}
                    </div>
                  </div>
                  <div>
                    <label className="pb-1 text-sm pl-4">New Password</label>
                    <div className="relative">
                      <input
                        className="w-full h-[40px] rounded-full border-[0.1px] px-6"
                        name="new_password1"
                        type={!isNewPassword ? "password" : "text"}
                        // onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.new_password1}
                        placeholder="New Password"
                        required
                      />
                      {!isNewPassword ? (
                        <img
                          src={lock}
                          className="absolute right-[12px] top-[8px]"
                          alt=""
                          onClick={handleNewPassword}
                        />
                      ) : (
                        <img
                          src={unlock}
                          onClick={handleNewPassword}
                          className="absolute right-[12px] top-[8px]"
                          alt=""
                        />
                      )}
                      {errors.new_password1 && <p className="text-red px-4 text-xs mt-1">{errors.new_password1}</p>}
                    </div>
                  </div>
                  <div>
                    <label className="pb-1 text-sm pl-4">
                      Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        className="w-full h-[40px] rounded-full border-[0.1px] px-6"
                        name="new_password2"
                        type={!isConfirmPassword ? "password" : "text"}
                        // onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.new_password2}
                        placeholder="Confirm Password"
                        required
                      />
                      {!isConfirmPassword ? (
                        <img
                          src={lock}
                          className="absolute right-[12px] top-[8px]"
                          alt=""
                          onClick={handleConfirmPassword}
                        />
                      ) : (
                        <img
                          src={unlock}
                          onClick={handleConfirmPassword}
                          className="absolute right-[12px] top-[8px]"
                          alt=""
                        />
                      )}
                      {errors.new_password2 && <p className="text-red px-4 text-xs mt-1">{errors.new_password2}</p>}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="before:ease relative h-[40px] max-w-[200px] px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
                  >
                    <span relative=" text-sm lg:text-base relative z-10">
                      Change Password
                    </span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </ShadowBlog>
    </div>
  );
};

export default ChangePassword;
