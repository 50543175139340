import React, { useState } from "react";
import TopLayout from "../../../components/Dashboard/toplayout";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import sms from "../../../assets/images/icons/sms.png";
import DashboardLayout from "../../../components/Dashboard/layout";

const TakeDownStatus = () => {
  const data = [
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: true,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: true,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: false,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: true,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: true,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: true,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: false,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: true,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: false,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: false,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: true,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: false,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: true,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: false,
    },
    {
      domain: "example.com",
      takedownrequest: "Jan 24, 2024",
      domainactive: "Yes",
      websiteactive: "Yes",
      replies: "",
      status: false,
    },
  ];

  const [tbData, setTbData] = useState(data.slice(0, 10));

  const totalPages = Math.ceil(data.length / 10);
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(page) {
    setCurrentPage(page);
    setTbData(data.slice((page - 1) * 10, page * 10));
  }
  return (
    <DashboardLayout title="Take Down Status">
      <div className="p-4 md:p-8">
        <div className="hidden md:block">
          <TopLayout title="Take Down Status" />
        </div>
        <div className="pt-4 rounded-xl w-full min-h-[600px] overflow-scroll md:overflow-hidden">
          <table className="rounded-xl shadow-xl table-fixed overflow-hidden w-max md:w-full">
            <thead className="text-start h-[60px] rounded-xl bg-light">
              <tr>
                <th className="text-start px-3">Domain</th>
                <th className="text-start px-3">Take down request</th>
                <th className="text-start px-3">Domain Active</th>
                <th className="text-start px-3">Website Active</th>
                <th className="text-start px-3">Replies</th>
                <th className="text-start px-3">Status</th>
              </tr>
            </thead>
            <tbody>
              {tbData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="odd:bg-white even:bg-slate-50 h-[50px] text-grey"
                  >
                    <td className="px-3">{item.domain}</td>
                    <td className="px-3">
                      <p className="">{item.takedownrequest}</p>
                    </td>
                    <td className="px-3">{item.domainactive}</td>
                    <td className="px-3">{item.websiteactive}</td>
                    <td className="px-3">
                      <img src={sms} alt="" />
                    </td>
                    <td>
                      <div className="flex gap-2 items-center ">
                        {item.status ? (
                          <p className="py-1 px-3 bg-[#27AE60]/10 rounded-full text-[#27AE60]">
                            Active
                          </p>
                        ) : (
                          <p className="py-1 px-3 rounded-full bg-[#EB5757]/10 text-[#EB5757]">
                            Inactive
                          </p>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr></tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-end pr-[45px]">
          <div className="w-[300px]">
            <ResponsivePagination
              total={totalPages}
              current={currentPage}
              onPageChange={(page) => handlePageChange(page)}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TakeDownStatus;
