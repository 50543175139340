import React, { useEffect, useState } from 'react';
import {
    Container, Typography, Box, CircularProgress, Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Paper,
} from '@mui/material';
import AlertsTable from '../../components/alerts-table';
import { getAllAlerts } from '../../api';
import DashboardLayout from '../../components/Dashboard/layout';
import SnackBarWrapper from '../../components/wrappers/snackbar-wrapper';
import TopLayout from '../../components/Dashboard/toplayout';
import { useLocation } from 'react-router-dom';

const Alerts = () => {
    const [alerts, setAlerts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pendingDelete, setPendingDelete] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const [success, setSuccess] = useState({ show: false, message: "" });
    const [error, setError] = useState({ show: false, message: "" });

    const location = useLocation();
    const preselectFilter = location?.state?.filter;


    useEffect(() => {
        setLoading(true)

        getAllAlerts().then((response) => {
            setAlerts(response.data)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    const handleSnackbarClose = () => {
        setError({ ...error, show: false });
        setSuccess({ ...success, show: false });
    }

    const handleDeleteModal = (monitor) => {
        setOpenDialog(true);
        setPendingDelete({
            monitor_id: monitor.uid,
            analysis_id: monitor.analysis_id
        })
    }

    const handleDeleteModalClose = () => {
        setOpenDialog(false);
        setPendingDelete(null)
    }

    const handleAlertDelete = (analysis_id) => {
        const data = {
            monitor_ids: [pendingDelete.monitor_id]
        }
    }

    return (
        <DashboardLayout>
            <Container maxWidth="100%">
                <Box py={3} className="hidden md:block">
                    <TopLayout title="Alerts" />
                </Box>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : (

                    <>
                        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                            <Typography variant="h6" component="h2">
                                Alerts
                            </Typography>
                        </Paper>

                        <AlertsTable alerts={alerts} onDeleteAlert={handleDeleteModal} preselectFilter={preselectFilter} />
                    </>
                )
                }
            </Container>

            {
                error.show &&
                <SnackBarWrapper onSnackbarClose={handleSnackbarClose} status={"error"} message={error.message} />
            }

            {
                success.show &&
                <SnackBarWrapper onSnackbarClose={handleSnackbarClose} status={"success"} message={success.message} />
            }


            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Delete Alert</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this alert? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteModalClose()}>Cancel</Button>
                    <Button onClick={() => handleAlertDelete()} color="error">
                        Delete Alert
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};
export default Alerts;
