import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import aboutus_hero from "../assets/images/aboutus_hero.png";
import contactus from '../assets/images/contact.png'
import moreaboutus from '../assets/images/moreaboutus.png'
import shape from '../assets/images/shape.png';

import "animate.css/animate.compat.css";

const AboutUs = () => {
    return (
        <div>

            {/* About Us Hero */}

            <div className="py-[3rem] lg:py-[5rem] px-4">
                <div className="max-w-xl lg:max-w-6xl mx-auto">
                    <div className="bg-primary rounded-full py-2 max-w-[150px]">
                        <p className="text-sm text-white text-center">Our Vision</p>
                    </div>
                    {/* <p className="text-start text-sm lg:text-base pt-[1rem] text-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ipsum tortor, hendrerit vehicula nisi ut, dapibus eleifend ligula.</p> */}
                </div>
                <div className="max-w-xl lg:max-w-6xl mx-auto">
                    <p className="text-xl lg:text-2xl font-bold py-[2rem] max-w-2xl">We strive to make Domain Spoofing monitoring both accessible and high-quality, offering enhanced capabilities at a competitive price.</p>
                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 lg:gap-0">
                        <div className="lg:col-span-5">
                            <img src={aboutus_hero} alt="" />
                        </div>
                        <div className="hidden lg:block"></div>
                        <div className="lg:col-span-6 flex flex-col justify-center text-grey">
                            <p className="text-sm lg:text-base">Our objective is to surpass the performance of established competitors, while significantly reducing costs. This approach makes advanced detection engines available to businesses of all sizes, from small SMEs to large corporations, allowing them to protect their digital landscapes without financial burden.</p>
                            <br></br>
                            <p className="text-sm lg:text-base">Our solution emphasizes automation to streamline operations and enhance efficiency. We automate the analysis of newly discovered domains—this includes detecting brand or logo spoofing, querying WHOIS records, verifying the existence of SMTP servers, and checking web port activity, all the way through to automating the takedown process. Additionally, all features of our platform are accessible via a REST API, enabling MSSPs and cybersecurity vendors to integrate and leverage our technology seamlessly, thus broadening their service offerings and enhancing their capabilities.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* More About Us */}

            <div className="bg-light bg-moreaboutus py-[3rem] lg:py-[5rem]">
                <div className="max-w-xl lg:max-w-6xl mx-auto px-4">
                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 lg:gap-2">
                        <div className="lg:hidden">
                            <img src={moreaboutus} alt="" />
                        </div>
                        <div className="lg:col-span-6">
                            <div className="bg-primary rounded-full py-2 max-w-[150px]">
                                <p className="text-sm text-white text-center">About Us</p>
                            </div>
                            <p className="text-2xl lg:text-4xl font-bold py-[1.5rem]">In a landscape transformed by AI, Spoofguard stands out as an innovation in cybersecurity. </p>
                            <p className="text-sm lg:text-base text-grey">Crafted by seasoned experts and entrepreneurs in the field, Spoofguard delivers a dependable domain monitoring solution. It not only detects malicious domains swiftly but also conducts automatic analyses and initiates takedowns.</p>
                            <br></br>
                            <p className="text-sm lg:text-base text-grey">More than a mere detection tool, Spoofguard is engineered for proactive defense, aiming to thwart phishing attacks from their onset. It embodies our dedication to advancing in step with, and moving ahead of, emerging digital threats to ensure secure online interactions.</p>
                            <br></br>
                            <p className="text-sm lg:text-base">Our Europe-based team consists of adept developers and researchers who focus primarily on product enhancement, minimizing administrative and sales expenses. Our founder, with a background in penetration testing since 2000, infuses Spoofguard with deep cybersecurity knowledge, propelling our mission to offer a formidable and trustworthy platform.</p>

                            
                            {/* <div className="flex pt-[1.5rem] gap-2">
                                <div className="hidden lg:block w-[15vw] border-t-2 mt-1 border-primary"></div>
                                <p className="text-sm lg:text-base">More than a mere detection tool, Spoofguard is engineered for proactive defense, aiming to thwart phishing attacks from their onset. It embodies our dedication to advancing in step with, and moving ahead of, emerging digital threats to ensure secure online interactions.</p>
                                <p className="text-sm lg:text-base">Our Europe-based team consists of adept developers and researchers who focus primarily on product enhancement, minimizing administrative and sales expenses. Our founder, with a background in penetration testing since 2000, infuses Spoofguard with deep cybersecurity knowledge, propelling our mission to offer a formidable and trustworthy platform.
                                </p>
                            </div> */}
                        </div>
                        <div className="hidden lg:flex items-end">
                            <img src={shape} alt="" />
                        </div>
                        <div className="hidden lg:block lg:col-span-5">
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={moreaboutus} alt="" />
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Us */}
            <div className="max-w-xl lg:max-w-6xl mx-auto py-[3rem] lg:py-[5rem] px-4">
                <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 lg:gap-4">
                    <div className="lg:col-span-5">
                        <img src={contactus} alt="" className="animate-pulse" />
                    </div>
                    <div></div>
                    <div className="lg:col-span-6">
                        <div className="bg-primary rounded-full py-2 max-w-[150px]">
                            <p className="text-sm text-white text-center">Get In Contact</p>
                        </div>
                        <p className="text-2xl lg:text-4xl font-bold py-[1.5rem]">Feel free to get in touch</p>
                        <form className="flex flex-col gap-y-4">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                <input className="w-full h-[40px] rounded-full border-[0.1px] border-grey px-6" placeholder="Name" />
                                <input className="w-full h-[40px] rounded-full border-[0.1px] border-grey px-6" placeholder="Email" />
                            </div>
                            <input className="w-full h-[40px] rounded-full border-[0.1px] border-grey px-6" placeholder="Subject" />
                            <textarea rows={5} className="w-full rounded-xl border-[0.1px] border-grey px-6 py-4" placeholder="Message" />
                            <button className="flex items-center justify-center gap-2 bg-primary text-white w-full h-[40px] rounded-full">
                                <p className="text-sm lg:text-base">Send Message</p>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;