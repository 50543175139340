import React from "react";

import edit from "../../../assets/images/icons/edit.png";
import trush from "../../../assets/images/icons/trash.png";
import ShadowBlog from "../../../components/common/shadowBlog";
import DashboardLayout from "../../../components/Dashboard/layout";
import TopLayout from "../../../components/Dashboard/toplayout";

const RequestTakeDown = () => {
  return (
    <DashboardLayout title="Request Take Down">
      <div className="p-8">
        <div className="hidden md:block">
        <TopLayout title="Request Take Down" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <ShadowBlog>
            <p className="font-bold text-sm md:text-base pb-4">
              Domain selected for takedown
            </p>
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <p>websiteexample.com</p>
                <img src={trush} alt="" />
              </div>
              <div className="flex items-center justify-between">
                <p>websiteexample.com</p>
                <img src={trush} alt="" />
              </div>
              <div className="flex items-center justify-between">
                <p>websiteexample.com</p>
                <img src={trush} alt="" />
              </div>
              <div className="flex items-center justify-between">
                <p>websiteexample.com</p>
                <img src={trush} alt="" />
              </div>
            </div>
          </ShadowBlog>
          <ShadowBlog>
            <p className="font-bold text-sm md:text-base pb-4">
              Send take down message
            </p>
            <div className="text-xs md:text-sm pb-4">
              <label className="flex items-center gap-1 text-grey">
                <input type="checkbox" className="accent-primary" /> Send take
                down request to domain provider
              </label>
            </div>
            <div className="text-xs md:text-sm pb-4">
              <label className="flex items-center gap-1 text-grey">
                <input type="checkbox" className="accent-primary" /> Send take
                down request to hosting provider
              </label>
            </div>
            <p className="font-semibold text-sm md:text-base">
              Edit take down template or upload additional data
            </p>
            <div className="flex items-center gap-2">
              <img src={edit} alt="" />
              <p className="text-primary text-sm md:text-base py-2">
                Edit Template
              </p>
            </div>
            <p className="font-semibold text-sm md:text-base py-2">
              Send report to security vendors
            </p>
            <div className="text-xs md:text-sm pb-4">
              <label className="flex items-center gap-1 text-grey">
                <input type="checkbox" className="accent-primary" /> Submit to
                Blocklist
              </label>
            </div>
          </ShadowBlog>
          <ShadowBlog>
            <p className="font-bold text-sm md:text-base pb-4">
              Submit Domains
            </p>
            <button className="before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40">
              <span className=" text-sm md:text-base relative z-10">
                Start Takedown
              </span>
            </button>
            <p className="text-grey pt-4">
              Please note that some providers do not react or take their time to
              disable a domain
            </p>
            <p className="text-grey pt-4">
              Please monitor the status of your take down requests on this page
            </p>
          </ShadowBlog>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default RequestTakeDown;
