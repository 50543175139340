import { Alert, Box, Button, CircularProgress, Container, Paper, Snackbar, Typography } from "@mui/material";
import DashboardLayout from "../../components/Dashboard/layout";
import TopLayout from "../../components/Dashboard/toplayout";
import { useCallback, useEffect, useState } from "react";
import { InfoOutlined } from "@mui/icons-material";
import MonitorSettings from "../../components/monitor-settings";
import SetupMonitoringTable from "../../components/setup-monitoring-table";
import { getDomainTakeDown, getFurtherDomainAnalysis, getModuleConfig, getSingleDomainAnalysis, startSetupMonitor } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";

const TakeDownSite = () => {
    const location = useLocation();
    const uid = location.state?.uid;
    const initialSelectedDomains = location.state?.selectedDomains;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedDomains, setSelectedDomains] = useState(initialSelectedDomains);
    const [alertConditions, setAlertConditions] = useState([]);
    const [selectedAlertConditons, setSelectedAlertCondition] = useState([]);
    const [error, setError] = useState({ show: false, message: "" });

    const handleSelectedRowsChange = (selectedRows) => {
        setSelectedDomains(selectedRows); // Update selected domains in state when rows are changed
    };

    // If selectedDomains from location state is null, make the request to update selectedDomains with the resultData
    useEffect(() => {
        getDomainTakeDown().then((response) => {
            console.log(response)
        })
    }, []);

    const handleCloseSnackbar = () => {
        setError({ ...error, show: false });
    };

    const handleConditionChange = useCallback((alertConditions) => {
        setSelectedAlertCondition(alertConditions)
    }, []);

    const setupMonitoringHandler = (e) => {
        e.preventDefault();

        const data = {
            domains: selectedDomains.map((domain) => (domain.domain)),
            conditions: selectedAlertConditons,
            auto_track_new_domains: true,
            enabled: true,
            custom_domains: []
        }

        startSetupMonitor(uid, data).then((response) => {
            console.log(response)
            const data = response.data

            navigate('/dashboard/takedown-domains', {state: {uid: uid, monitoringRespones: data}})
            //go to 
        })
    }

    return (
        <DashboardLayout> 
            {/* <Container maxWidth="100%">
                <Box py={3} className="hidden md:block">
                    <TopLayout title="Analyze Results" />
                </Box>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : (
                    selectedDomains && (
                        <>
                            <Paper elevation={3} sx={{ p: 2, mb: 3, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6" component="h2">
                                        Setup Monitoring
                                    </Typography>
                                </Box>
                                <Button variant="contained" color="primary" onClick={(e) => setupMonitoringHandler(e)}>
                                    Setup Monitoring
                                </Button>
                            </Paper>

                            <Paper
                                elevation={3}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 2,
                                    backgroundColor: "#e8f4fc",
                                    borderLeft: "5px solid #2196f3",
                                    borderRadius: 1,
                                    margin: "auto",
                                    mb: 3,
                                }}
                            >
                                <InfoOutlined sx={{ color: "#2196f3", marginRight: 1 }} />
                                <Typography variant="body1" sx={{ color: "#333" }}>
                                    Choose domains to be monitored and configure your domain monitoring settings
                                </Typography>
                            </Paper>

                            {alertConditions &&
                                <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                                    <MonitorSettings alertConditions={alertConditions} onConditionChange={handleConditionChange} />
                                </Paper>
                            }

                            <Paper elevation={3} sx={{ p: 3 }}>
                                <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" mb={2}>
                                    <Typography variant="h6" component="h2">
                                        Domains to monitor
                                    </Typography>
                                </Box>

                                <SetupMonitoringTable
                                    furtherResultsFormatted={selectedDomains}
                                    onSelectedRowsChange={handleSelectedRowsChange}
                                />
                            </Paper>
                        </>
                    )
                )}
            </Container>

           
            <Snackbar
                open={error.show}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {error.message}
                </Alert>
            </Snackbar> */}
        </DashboardLayout>
    );
}

export default TakeDownSite;