import React from "react";

const ShadowBlog = ({children}) => {
    return(
        <div className="rounded-lg shadow-xl p-[12px] md:p-[25px] bg-white hover:shadow-2xl h-full">
            {children}
        </div>
    );
}

export default ShadowBlog;