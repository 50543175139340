import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import call from "../../../assets/images/icons/call.png";
import location from "../../../assets/images/icons/location.png";
import orangization from "../../../assets/images/icons/orangization_primary.png";
import sms from "../../../assets/images/icons/sms_primary.png";
import useraccount from "../../../assets/images/user_account_large.png";
import { deleteaccount, updateProfile } from "../../../actions/accountActions";

const Profile = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const handleDelete = async () => {
    try {
      if (!isChecked) {
        const message = "Please confirm check box!";
        enqueueSnackbar(message, {
          variant: "error",
        });
      } else {
        await dispatch(deleteaccount());
        enqueueSnackbar("Account close success!", {
          variant: "success",
        });
        window.location.href = "/";
      }
    } catch (error) {
      const message = "Account close failed!";
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  return (
    <div className="block md:flex gap-4">
      <div className="md:min-w-[400px] col-span-2 pb-4 md:pb-0">
        <div className="rounded-lg shadow-xl p-[12px] md:p-[25px] bg-white hover:shadow-2xl">
          <p className="font-bold text-sm md:text-base pb-4">Introduction</p>
          <div className="flex gap-4">
            <img src={useraccount} alt="" className="w-[100px] h-[100px]" />
            <div className="flex flex-col gap-2 justify-center">
              <p className="font-semibold">
                {props.account ? props.account.first_name : null}{" "}
                {props.account ? props.account.last_name : null}
              </p>
              {/* <button
                type="submit"
                className="before:ease relative h-[40px] min-w-[160px] rounded-lg flex items-center justify-center gap-1 overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
              >
                <img src={avataredit} alt="" />
                <span relative=" text-sm lg:text-base relative z-10">
                  Change Profile
                </span>
              </button> */}
            </div>
          </div>
          <div className="border-t-[0.5px] my-6"></div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-2 items-center">
              <img src={sms} alt="" className="w-[24px] h-[24px]" />
              <p>{props.account ? props.account.email : null}</p>
            </div>
            {props.account && props.account.phone_number ? (
              <div className="flex gap-2 items-center">
                <img src={call} alt="" className="w-[24px] h-[24px]" />
                <p>{props.account.phone_number}</p>
              </div>
            ) : null}
            {props.account && props.account.city ? (
              <div className="flex gap-2 items-center">
                <img src={location} alt="" className="w-[24px] h-[24px]" />
                <p>{props.account.city}</p>
              </div>
            ) : null}
            {props.account && props.account.organization ? (
              <div className="flex gap-2 items-center">
                <img src={orangization} alt="" className="w-[24px] h-[24px]" />
                <p>{props.account.organization}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="rounded-lg shadow-xl p-[12px] md:p-[25px] bg-white hover:shadow-2xl">
          <Formik
            initialValues={{
              first_name: props.account.first_name,
              last_name: props.account.last_name,
              email: props.account.email,
              organization: props.account.organization,
              phone_number: props.account.phone_number,
              country: props.account.country,
              salutation: props.account.salutation,
              street: props.account.street,
              postal_code: props.account.postal_code,
              city: props.account.city,
              organization_url: props.account.organization_url,
            }}
            validationSchema={Yup.object().shape({
              first_name: Yup.string()
                .max(255)
                .required("First name is required"),
              last_name: Yup.string()
                .max(255)
                .required("Last name is required"),
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              organization: Yup.string()
                .max(255)
                .required("Organization is required"),
              phone_number: Yup.string()
                .min(7)
                .max(255)
                .required("Password is required"),
              country: Yup.string().max(255).required("Country is required"),
              city: Yup.string().max(255),
              zipcode: Yup.string().max(255),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const request_body = {
                  ...props.account,
                  ...values,
                };
                await dispatch(updateProfile(request_body));
                enqueueSnackbar("Profile updated!", {
                  variant: "success",
                });
              } catch (error) {
                const message = "Update failed!";
                enqueueSnackbar(message, {
                  variant: "error",
                });
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col item-center gap-4 text-sm md:text-base">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div>
                      <label className="text-sm pl-4">
                        First Name <span className="text-[#EB5757]">*</span>
                      </label>
                      <div className="relative">
                        <input
                          className="w-full h-[36px] md:h-[40px] rounded-full border-[0.1px] px-4 md:px-6"
                          name="first_name"
                          type="first_name"
                          // onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.first_name}
                          placeholder="First Name"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm pl-4">
                        Last Name <span className="text-[#EB5757]">*</span>
                      </label>
                      <div className="relative">
                        <input
                          className="w-full h-[36px] md:h-[40px] rounded-full border-[0.1px] px-4 md:px-6"
                          name="last_name"
                          type="last_name"
                          // onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.last_name}
                          placeholder="Last Name"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm pl-4">
                        Email <span className="text-[#EB5757]">*</span>
                      </label>
                      <div className="relative">
                        <input
                          className="w-full h-[36px] md:h-[40px] rounded-full border-[0.1px] px-4 md:px-6"
                          name="email"
                          type="email"
                          // onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm pl-4">Organization</label>
                      <div className="relative">
                        <input
                          className="w-full h-[36px] md:h-[40px] rounded-full border-[0.1px] px-4 md:px-6"
                          name="organization"
                          type="organization"
                          // onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.organization}
                          placeholder="Organization"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm pl-4">
                        Phone Number <span className="text-[#EB5757]">*</span>
                      </label>
                      <div className="relative">
                        <input
                          className="w-full h-[36px] md:h-[40px] rounded-full border-[0.1px] px-4 md:px-6"
                          name="phone_number"
                          type="phone_number"
                          // onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.phone_number}
                          placeholder="ex: +1234567890"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm pl-4">
                        Country <span className="text-[#EB5757]">*</span>
                      </label>
                      <div className="relative">
                        <input
                          className="w-full h-[36px] md:h-[40px] rounded-full border-[0.1px] px-4 md:px-6"
                          name="country"
                          type="country"
                          // onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.country}
                          placeholder="Country"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm pl-4">
                        City <span className="text-[#EB5757]">*</span>
                      </label>
                      <div className="relative">
                        <input
                          className="w-full h-[36px] md:h-[40px] rounded-full border-[0.1px] px-4 md:px-6"
                          name="city"
                          type="city"
                          // onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.city}
                          placeholder="City"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm pl-4">
                        ZipCode <span className="text-[#EB5757]">*</span>
                      </label>
                      <div className="relative">
                        <input
                          className="w-full h-[36px] md:h-[40px] rounded-full border-[0.1px] px-4 md:px-6"
                          name="postal_code"
                          type="postal_code"
                          // onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.postal_code}
                          placeholder="postal_code"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
                    >
                      <span relative=" text-sm lg:text-base relative z-10">
                        Save Changes
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="py-3"></div>
        <div className="rounded-lg shadow-xl p-[12px] md:p-[25px] bg-white hover:shadow-2xl">
          <p className="font-bold text-[#EB5757]">Delete your account</p>
          <p className="text-sm md:text-base text-grey py-4">
            When you delete your account, you lose access to account service,
            and we permanently delete your personal data. You can cancel the
            deletion for 14 days.
          </p>
          <div className="text-xs md:text-sm pb-4">
            <label className="flex items-center gap-1">
              <input
                type="checkbox"
                className={`accent-primary`}
                checked={isChecked}
                onChange={handleOnChange}
              />{" "}
              Confirm that i want to delete my account
            </label>
          </div>
          <div className="flex justify-end gap-2">
            {/* <button className="before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-8 rounded-full overflow-hidden bg-black text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-black hover:before:-translate-x-40">
              <span className=" text-sm lg:text-base relative z-10">
                Learn more
              </span>
            </button> */}
            <button
              onClick={handleDelete}
              className="before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-8 rounded-full overflow-hidden bg-[#EB5757] text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-[#EB5757] hover:before:-translate-x-40"
            >
              <span className=" text-sm lg:text-base relative z-10">
                Delete
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
