import { useEffect, useRef, useCallback, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const usePolling = () => {
  const [status, setStatus] = useState(true);
  const analysisId = localStorage.getItem("analysis_id") || "";

  const [moduleIsLoaded, setModuleIsLoaded] = useState(false);
  const [analysisResult, setAnalysisResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultCount, setResultCount] = useState(0);
  const [scanProgress, setScanProgress] = useState({
    totalVariants: 0,
    completed: 0,
  });

  const intervalRef = useRef(null);

  const fetchAnalysisResults = useCallback(async () => {
    setLoading(true);
    if (loading) {
      return;
    }
    const response_ip = await fetch("https://api.ipify.org?format=json");
    const ip_json = await response_ip.json();
    try {
      const headers = {
        accept: "application/json",
        "Ip-Address": ip_json.ip,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/analysis/${analysisId}/`,
        { params: { analysis_id: analysisId }, headers }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching analysis result:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [analysisId, loading]);

  const processResults = useCallback((data) => {
    const { dns_lookups_formatted, total_variations, total_initial_done } =
      data;

    setScanProgress({
      totalVariants: total_variations,
      completed: total_initial_done,
    });

    const formattedData = dns_lookups_formatted.map((_item) => ({
      id: uuidv4(),
      domain: data.domain,
      detected_domain: _item.domain,
      brand: null,
      date: data.created_at,
      mx_record: _item.mx_record,
      ns_record: _item.ns_record,
      a_record: _item.a_record,
      dns_record: _item.dns_server,
      enumeration: _item.module_name,
      whois: _item.whois?.whois_server,
      web: [_item.http_open && "http", _item.https_open && "https"].filter(
        Boolean
      ),
      phishing: _item.phishing,
      screenshot: _item.screenshot,
      category: null,
      spoofing: null,
      risk: null,
    }));

    return formattedData;
  }, []);

  const handleAnalysisResults = useCallback((newResults) => {
    const newResultCount = newResults.length;

    localStorage.setItem("analysis_request", JSON.stringify(newResults));
    setAnalysisResult(newResults);
    setResultCount(newResultCount);
  }, []);

  const getAnalysisResult = useCallback(async () => {
    if (!analysisId) return;

    try {
      const data = await fetchAnalysisResults();

      if (data.detail && data.detail.includes("does not exist")) {
        console.error("Analysis does not exist:", data.detail);
        setStatus(false);
        return;
      }

      const formattedData = processResults(data);

      handleAnalysisResults(formattedData);
      if (
        data.status === "initial_complete" ||
        data.status === "further_complete"
      ) {
        setStatus(false);
      }
      if (scanProgress.completed === 0 && scanProgress.totalVariants === 0) {
        setStatus(true);
      } else if (scanProgress.completed === scanProgress.totalVariants) {
        setStatus(false);
      }
    } catch (error) {
      console.error("Error during analysis result fetching:", error);
      setStatus(false);
    }
  }, [
    analysisId,
    fetchAnalysisResults,
    processResults,
    handleAnalysisResults,
    scanProgress,
  ]);

  useEffect(() => {
    if ((status && analysisId && !moduleIsLoaded) || moduleIsLoaded) {
      intervalRef.current = setInterval(getAnalysisResult, 12000);
    } else if (!status) {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [status, analysisId, getAnalysisResult, moduleIsLoaded]);

  return {
    status,
    setStatus,
    analysisId,
    analysisResult,
    loading,
    resultCount,
    scanProgress,
    setAnalysisResult,
    setModuleIsLoaded,
  };
};

export default usePolling;
