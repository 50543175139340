import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const planName = params.get("plan");

    if (planName) {
      const handleSuccess = async () => {
        const requestBody = {
          plan_type: planName,
          payment_status: "Success",
          payment_data: {},
        };
        try {
          await axios.post(
            `${process.env.REACT_APP_BASIC_API_URL}/memberships/upgrade/`,
            requestBody
          );
        } catch (error) {
          console.error("Error handling success:", error);
        }
      };

      handleSuccess();
    }
  }, [location]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md text-center">
        <h2 className="text-2xl font-bold text-green-600 mb-4">
          Thank you for your purchase!
        </h2>
        <p className="text-gray-700 mb-6">
          Your payment was successful. You can now enjoy the benefits of the{" "}
          <strong>{params.get("plan")?.toUpperCase()}</strong> plan!
        </p>
        <button
          onClick={() =>
            navigate("/dashboard/settings?subscription=" + params.get("plan"))
          }
          className="bg-primary hover:bg-primary-dark text-white font-semibold py-2 px-4 rounded-full transition duration-200 shadow-md"
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;
