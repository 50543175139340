import React, { useState } from 'react';
import {
    Switch,
    FormControlLabel,
    Box,
    Typography,
    IconButton,
    Tooltip,
    MenuItem,
    Menu,
    TextField,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const ModuleSettings = ({ modules, onModulesChange, openWordlistModal, onWordlistSelect, customWordLists }) => {
    const [enabledModules, setEnabledModules] = useState(
        modules.reduce((acc, module) => {
            acc[module.code] = false; // Initialize all modules as disabled
            return acc;
        }, {})
    );

    const [selectedWordlist, setSelectedWordlist] = useState(''); // Only for 'module_domain_word_combination'
    const [searchTerm, setSearchTerm] = useState(''); // Search term for wordlist dropdown
    const [anchorEl, setAnchorEl] = useState(null);

    const handleToggleModule = (moduleCode) => {
        setEnabledModules((prev) => {
            const newEnabledModules = { ...prev, [moduleCode]: !prev[moduleCode] };
            const selectedModules = Object.keys(newEnabledModules).filter((code) => newEnabledModules[code]);
            onModulesChange(selectedModules); // Notify parent component of changes
            return newEnabledModules;
        });
    };

    const handleWordlistChange = (value) => {
        onWordlistSelect(customWordLists.filter(x => x.name === value)[0].code);
        setSelectedWordlist(value)
        handleCloseMenu();
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleToggleAllModules = () => {
        const allEnabled = Object.values(enabledModules).every(Boolean);
        const newEnabledModules = modules.reduce((acc, module) => {
            acc[module.code] = !allEnabled;
            return acc;
        }, {});
        setEnabledModules(newEnabledModules);
        const selectedModules = Object.keys(newEnabledModules).filter((code) => newEnabledModules[code]);
        onModulesChange(selectedModules);
    };

    const wordlistOptions = customWordLists.map((list) => list.name);
    const filteredWordlistOptions = wordlistOptions.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box>
            <Typography variant="h6" mb={2}>
                Module Settings
            </Typography>

            <FormControlLabel
                control={
                    <Switch
                        checked={Object.values(enabledModules).every(Boolean)}
                        onChange={handleToggleAllModules}
                        color="primary"
                        size="small"
                    />
                }
                label="Enable All"
                sx={{ marginBottom: 2 }}
            />

            <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={1}>
                {modules.map((module) => {
                    const isWordlistMissing =
                        module.code === 'module_domain_word_combination' &&
                        enabledModules[module.code] &&
                        !selectedWordlist;

                    return (
                        <Box
                            key={module.code}
                            p={1}
                            display="flex"
                            alignItems="center"
                            gap={1}
                            border={`1px solid ${isWordlistMissing ? '#f44336' : '#ccc'}`} // Red border if wordlist is missing
                            borderRadius={2}
                            sx={{
                                cursor: 'pointer',
                                backgroundColor: isWordlistMissing ? '#fff4e5' : 'inherit', // Light warning background if wordlist is missing
                                '&:hover': { backgroundColor: isWordlistMissing ? '#ffe6cc' : '#f0f0f0' }, // Slightly darker warning background on hover
                            }}
                        >
                            <Tooltip title={module.description} arrow>
                                <IconButton size="small" color="primary">
                                    <InfoOutlinedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>

                            <Typography variant="subtitle2" sx={{ flexGrow: 1, fontSize: '0.9rem' }}>
                                {module.name}
                            </Typography>

                            {module.code === 'module_domain_word_combination' && enabledModules[module.code] && (
                                <>
                                    {isWordlistMissing && (
                                        <Tooltip title="No wordlist selected" arrow>
                                            <WarningAmberIcon color="error" fontSize="small" />
                                        </Tooltip>
                                    )}
                                    <IconButton onClick={handleOpenMenu} size="small" color="primary">
                                        <Typography variant="body2" sx={{ marginRight: 0.5 }}>
                                            {selectedWordlist || 'Select Wordlist'}
                                        </Typography>
                                        <ArrowDropDownIcon fontSize="small" />
                                    </IconButton>
                                </>
                            )}

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={enabledModules[module.code]}
                                        onChange={() => handleToggleModule(module.code)}
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label={enabledModules[module.code] ? 'On' : 'Off'}
                                sx={{ marginLeft: 'auto' }}
                            />

                            {module.code === 'module_domain_word_combination' && (
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                    PaperProps={{
                                        style: { maxHeight: 300, width: '250px' },
                                    }}
                                >
                                    <Box p={1}>
                                        <TextField
                                            size="small"
                                            placeholder="Search..."
                                            fullWidth
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                        />
                                    </Box>
                                    {filteredWordlistOptions.map((option) => (
                                        <MenuItem key={option} onClick={() => handleWordlistChange(option)}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                    <MenuItem
                                        onClick={openWordlistModal}
                                        sx={{ fontWeight: 'bold', color: 'primary.main' }}
                                    >
                                        + Create New Wordlist
                                    </MenuItem>
                                </Menu>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default ModuleSettings;
