export const initialModules = [
  { module_name: "module_tld", count: 0 },
  { module_name: "module_prepend", count: 0 },
  { module_name: "module_common_word", count: 0 },
  { module_name: "module_prepend_number", count: 0 },
  { module_name: "module_letter_swap", count: 0 },
  { module_name: "module_hyphenated_domains", count: 0 },
  { module_name: "module_bit_squatting", count: 0 },
  { module_name: "module_homograph_word_variations", count: 0 },
  { module_name: "module_append_tld", count: 0 },
  { module_name: "module_append_tld_variations", count: 0 },
  { module_name: "module_double_characters", count: 0 },
  { module_name: "module_add_common_numbers", count: 0 },
  { module_name: "module_subdomain_add", count: 0 },
  { module_name: "module_missing_dashes", count: 0 },
  { module_name: "module_levenshtein_typos", count: 0 },
  { module_name: "module_vowel_swap", count: 0 },
  { module_name: "module_omission", count: 0 },
  { module_name: "module_misspellings", count: 0 },
  { module_name: "module_punycode_variations", count: 0 },
  { module_name: "module_tldvariation_addon_tld", count: 0 },
  { module_name: "module_append_numbers_letters", count: 0 },
  { module_name: "module_numeral_swap", count: 0 },
  { module_name: "module_dyndns", count: 0 },
  { module_name: "replacement_letters_next_in_keyboard", count: 0 },
  { module_name: "module_api_domain_search", count: 0 },
  { module_name: "module_api_domain_search_ssl", count: 0 },
  { module_name: "module_subdomain_letter_hyphen", count: 0 },
];
