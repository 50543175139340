import { configureStore as toolkitConfigureStore } from '@reduxjs/toolkit'; // Renaming the imported function
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import rootReducer from '../reducers'; // Import updated rootReducer

const loggerMiddleware = createLogger();

export function createAppStore(preloadedState = {}) { // Renaming your custom function
  const middlewares = [thunkMiddleware];
  const isDevelopment = process.env.NODE_ENV !== 'production';

  // Add logger only in development
  // if (isDevelopment) {
  //   middlewares.push(loggerMiddleware);
  // }

  const store = toolkitConfigureStore({ // Using the renamed import here
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // Disable serializability checks for redux-persist
      }).concat(middlewares),
    preloadedState,
    devTools: isDevelopment,
  });

  const persistor = persistStore(store); // Create persistor

  return { store, persistor };
}
