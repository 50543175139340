import axios from "axios";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import lock from "../assets/images/icons/lock-svgrepo-com.png";
import orangization from "../assets/images/icons/orangization.png";
import sms from "../assets/images/icons/sms.png";
import unlock from "../assets/images/icons/unlock-svgrepo-com.png";
import user from "../assets/images/icons/user.png";
import activateAccount from "../assets/images/accountactivate.png";
import ShadowBlog from "../components/common/shadowBlog";
import { register } from "../actions/accountActions";
import { API_BASE_URL } from "../config";

const Register = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isSent, setSent] = useState(false);
  const [isPassword, setPassword] = useState(false);
  const [isConfirmPassword, setConfirmPassword] = useState(false);

  const [resendEmail, setResendEmail] = useState("");

  const handleLockPassword = () => {
    setPassword(!isPassword);
  };

  const handleLockConfirmPassword = () => {
    setConfirmPassword(!isConfirmPassword);
  };

  const handleResend = async () => {
    if (!!resendEmail) {
      await axios
        .post(`${API_BASE_URL}/account/resend-activation/`, {
          email: resendEmail,
        })
        .then((response) => {
          enqueueSnackbar("Sent activation email again!", {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar("Resend failed!", {
            variant: "error",
          });
        });
    }
  };

  return (
    <div className="min-h-[100vh] bg-auth flex items-center justify-center">
      <div className=" w-[100vw] lg:w-[700px]">
        {!isSent ? (
          <ShadowBlog>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                organization: "",
                password1: "",
                password2: "",
              }}
              validationSchema={Yup.object().shape({
                first_name: Yup.string()
                  .max(255)
                  .required("First name is required"),
                last_name: Yup.string()
                  .max(255)
                  .required("Last name is required"),
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                organization: Yup.string()
                  .max(255)
                  .required("Organization is required"),
                password1: Yup.string()
                  .min(7)
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                    "Password must contain at least one uppercase letter, one lowercase letter, and one number"
                  )
                  .max(255)
                  .required("Password is required"),
                password2: Yup.string()
                  .min(7)
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                    "Password must contain at least one uppercase letter, one lowercase letter, and one number"
                  )
                  .max(255)
                  .required("Confirm Password is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  if (values.password1 !== values.password2) {
                    enqueueSnackbar("Not matched Password!", {
                      variant: "error",
                    });
                  } else {
                    await dispatch(register(values));
                    setSent(true);
                    setResendEmail(values.email);
                    enqueueSnackbar("Check Your Email!", {
                      variant: "success",
                    });
                  }
                } catch (error) {
                  const message = error.response.data.detail;
                  enqueueSnackbar(message, {
                    variant: "error",
                  });
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col item-center gap-4">
                    <p className="font-bold text-2xl lg:text-4xl text-center">
                      Register
                    </p>
                    <p className="font-bold text-center">
                      Register to your Account
                    </p>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div className="relative">
                        <input
                          className={`w-full h-[40px] rounded-full border pl-6 pr-[15px] ${
                            errors.first_name
                              ? "border-[#D05748]"
                              : "border-grey"
                          }`}
                          name="first_name"
                          type="first_name"
                          onChange={handleChange}
                          value={values.first_name}
                          placeholder="First Name"
                          required
                        />
                        <img
                          src={user}
                          className="absolute right-[12px] top-[8px]"
                          alt=""
                        />
                      </div>
                      <div className="relative">
                        <input
                          className={`w-full h-[40px] rounded-full border-[0.1px] pl-6 pr-[15px] ${
                            errors.last_name
                              ? "border-[#D05748]"
                              : "border-grey"
                          }`}
                          name="last_name"
                          type="last_name"
                          onChange={handleChange}
                          value={values.last_name}
                          placeholder="Last Name"
                          required
                        />
                        <img
                          src={user}
                          className="absolute right-[12px] top-[8px]"
                          alt=""
                        />
                      </div>
                      <div className="relative">
                        <input
                          className={`w-full h-[40px] rounded-full border-[0.1px] pl-6 pr-[15px] ${
                            errors.email ? "border-[#D05748]" : "border-grey"
                          }`}
                          name="email"
                          type="email"
                          onChange={handleChange}
                          value={values.email}
                          placeholder="Email"
                          required
                        />
                        <img
                          src={sms}
                          className="absolute right-[12px] top-[8px]"
                          alt=""
                        />
                      </div>
                      <div className="relative">
                        <input
                          className={`w-full h-[40px] rounded-full border-[0.1px] pl-6 pr-[15px] ${
                            errors.organization
                              ? "border-[#D05748]"
                              : "border-grey"
                          }`}
                          name="organization"
                          type="organization"
                          onChange={handleChange}
                          value={values.organization}
                          placeholder="Organization"
                        />
                        <img
                          src={orangization}
                          className="absolute right-[12px] top-[8px]"
                          alt=""
                        />
                      </div>
                      <div className="relative">
                        <input
                          className={`w-full h-[40px] rounded-full border-[0.1px] pl-6 pr-[15px] ${
                            errors.password1
                              ? "border-[#D05748]"
                              : "border-grey"
                          }`}
                          name="password1"
                          type={!isPassword ? "password" : "text"}
                          onChange={handleChange}
                          value={values.password1}
                          placeholder="Password"
                          required
                        />
                        {!isPassword ? (
                          <img
                            src={lock}
                            className="absolute right-[12px] top-[8px]"
                            alt=""
                            onClick={handleLockPassword}
                          />
                        ) : (
                          <img
                            src={unlock}
                            onClick={handleLockPassword}
                            className="absolute right-[12px] top-[8px]"
                            alt=""
                          />
                        )}
                        {errors.password1 && (
                          <p className="text-red px-4 text-xs mt-1">
                            Password must contain at least one uppercase letter,
                            one lowercase letter, and one number
                          </p>
                        )}
                      </div>
                      <div className="relative">
                        <input
                          className={`w-full h-[40px] rounded-full  border-[0.1px] pl-6 pr-[15px] ${
                            errors.password2
                              ? "border-[#D05748]"
                              : "border-grey"
                          }`}
                          name="password2"
                          type={!isConfirmPassword ? "password" : "text"}
                          onChange={handleChange}
                          value={values.password2}
                          placeholder="Confirm Password"
                          required
                        />
                        {!isConfirmPassword ? (
                          <img
                            src={lock}
                            className="absolute right-[12px] top-[8px]"
                            alt=""
                            onClick={handleLockConfirmPassword}
                          />
                        ) : (
                          <img
                            src={unlock}
                            onClick={handleLockConfirmPassword}
                            className="absolute right-[12px] top-[8px]"
                            alt=""
                          />
                        )}
                        {errors.password2 && (
                          <p className="text-red px-4 text-xs mt-1">
                            Password must be matched!
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="text-sm lg:text-base bg-primary text-white max-w-[200px] px-8 h-[40px] rounded-full"
                      >
                        Register Now
                      </button>
                    </div>
                    <div className="flex justify-center text-sm">
                      <span>
                        Already have an acount?{" "}
                        <a
                          href="/login"
                          className="text-primary cursor-pointer underline underline-offset-4"
                        >
                          Login now
                        </a>
                      </span>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </ShadowBlog>
        ) : (
          <ShadowBlog>
            <div className="flex flex-col item-center gap-4">
              <div className="flex justify-center">
                <img src={activateAccount} alt="" className="w-[200px]" />
              </div>
              <p className="font-bold text-2xl lg:text-4xl text-center">
                Verify your account
              </p>
              <p className="text-center">
                We send an activation link to your email address. Please confirm
                the link to proceed.
              </p>
              <div className="flex justify-start mt-8">
                <span>
                  Didn't you receive the email?{" "}
                  <buttin
                    onClick={handleResend}
                    className="text-primary cursor-pointer underline underline-offset-4 bg-none border-none"
                  >
                    Resend
                  </buttin>
                </span>
              </div>
              <div className="flex items-center justify-center">
                <a
                  href="/"
                  className="flex items-center text-sm lg:text-base bg-primary text-white max-w-[200px] px-8 h-[40px] rounded-full"
                >
                  Sure!
                </a>
              </div>
            </div>
          </ShadowBlog>
        )}
      </div>
    </div>
  );
};

export default Register;
