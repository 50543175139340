import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ShadowBlog from "../common/shadowBlog";
import search from "../../assets/images/icons/search.png";
const DomainScanForm = ({ handleDomainSubmit, modulesList }) => {
  return (
    <ShadowBlog>
      <p className="pb-4 text-sm lg:text-base font-bold">Test your Domain</p>
      <Formik
        initialValues={{
          domain: "",
        }}
        validationSchema={Yup.object().shape({
          domain: Yup.string()
            .max(255)
            .matches(
              /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
              "Invalid domain format"
            )
            .required("Domain is required"),
        })}
        onSubmit={handleDomainSubmit}
      >
        {({ errors, handleChange, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
              <div className="col-span-2 lg:col-span-5">
                <input
                  className={
                    errors.domain
                      ? "w-full h-[40px] rounded-full border-[0.1px] border-red focus:outline-red px-6"
                      : "w-full h-[40px] rounded-full border-[0.1px] focus:outline-blue-500 px-6"
                  }
                  placeholder="your domain"
                  name="domain"
                  type="text"
                  onChange={handleChange}
                  value={values.domain}
                  required
                />
                {errors.domain && (
                  <p className="text-sm text-red pl-4 pt-2">{errors.domain}</p>
                )}
              </div>
              <div className="col-span-1 lg:col-span-1">
                {modulesList.length > 0 ? (
                  <button
                    type="submit"
                    className="flex items-center justify-center gap-2 before:ease relative h-[40px] w-full px-8 rounded-full overflow-hidden bg-black text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-black hover:before:-translate-x-40"
                  >
                    <span relative=" text-sm lg:text-base relative z-10">
                      Scan
                    </span>
                    <img src={search} className="w-[15px]" alt="search icon" />
                  </button>
                ) : (
                  <button
                    disabled
                    className="flex items-center justify-center gap-2 before:ease relative h-[36px] md:h-[40px] max-w-[200px] w-full px-4 md:px-8 rounded-full overflow-hidden bg-grey/70 cursor-not-allowed text-white"
                  >
                    <span className=" text-sm md:text-base relative z-10">
                      Scan
                    </span>
                    <img src={search} className="w-[15px]" alt="search icon" />
                  </button>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </ShadowBlog>
  );
};

export default DomainScanForm;
