import { Alert, Snackbar } from "@mui/material"

const SnackBarWrapper = ({ status, message, onSnackbarClose }) => {

    return (
        <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={onSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
            <Alert
                onClose={onSnackbarClose}
                severity={status}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackBarWrapper;