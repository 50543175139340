import { Line } from "rc-progress";
import { useState, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";

const CustomProgressBar = ({ value, count, isLoading }) => {
  return (
    <Line
      percent={Math.min((count / value) * 100, 100)}
      strokeWidth={4}
      strokeColor={isLoading ? "#C0C0C0" : "#30AFEE"}
    />
  );
};

export const ProgressBarWithVariants = ({ totalVariants, completed }) => {
  const [count, setCount] = useState(completed);

  useEffect(() => {
    setCount(completed);

    const timer = setInterval(() => {
      if (count < completed) {
        setCount((prevCount) => {
          const newCount = Math.min(prevCount + 469, completed);
          return newCount;
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [completed, count]);

  const isLoading = totalVariants === 0 && completed === 0;

  return (
    <div className="py-4 w-full flex items-center gap-6">
      {!(totalVariants === completed && totalVariants > 0) || isLoading ? (
        <RotatingLines
          visible={true}
          height="38"
          width="38"
          strokeColor="blue"
          strokeWidth="3"
          animationDuration="10"
          ariaLabel="rotating-lines-loading"
        />
      ) : (
        <></>
      )}

      <div className="flex justify-between">
        <p className="text-[8px] lg:text-[10px] font-semibold">
          {totalVariants === completed && totalVariants > 0
            ? "Analysis is done"
            : "Please Wait. The scan is still in progress..."}
          <br />
          {completed} of {totalVariants} completed (
          {totalVariants > 0
            ? ((completed / totalVariants) * 100).toFixed(2)
            : 0}
          %)
        </p>
      </div>
      <div className="flex flex-col gap-6  w-[200px]">
        <CustomProgressBar
          value={totalVariants}
          count={count}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
