import Analyze from "../pages/Dashboard/scan/analyze";
import DomainInvestigation from "../pages/Dashboard/scan/domainInvestigation";
import ScanContent from "../pages/Dashboard/scan/scan";
import SetupMonitoring from "../pages/Dashboard/scan/setupmonitoring";
import Takedownsite from "../pages/Dashboard/scan/takedownsite";

export const stepdata = [
  {
    stepLabel: "Initial Scan",
    renderNode: <ScanContent />,
    completed: true,
  },
  {
    stepLabel: "Analyze Results",
    renderNode: <Analyze />,
    completed: false,
  },
  {
    stepLabel: "Domain Investigation",
    renderNode: <DomainInvestigation />,
    completed: false,
  },
  {
    stepLabel: "Setup Monitoring",
    renderNode: <SetupMonitoring />,
    completed: false,
  },
  {
    stepLabel: "TakeDown Domain",
    renderNode: <Takedownsite />,
    completed: false,
  },
];
