import { v4 as uuidv4 } from "uuid";

export const convertToCSV = (objArray) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str =
    "ID,Domain,Detected Domain,Brand,Date,A record,DNS record,MX record,NS record,Enumeration,WHOIS,WEB,Category,Spoofing,Risk,Screenshot\r\n";

  for (let i = 0; i < array.length; i++) {
    let line =
      array[i].id +
      "," +
      array[i].domain +
      "," +
      array[i].detected_domain +
      "," +
      array[i].brand +
      "," +
      array[i].date +
      "," +
      array[i].a_record +
      "," +
      array[i].dns_record +
      "," +
      array[i].mx_record +
      "," +
      array[i].ns_record +
      "," +
      array[i].enumeration +
      "," +
      array[i].whois +
      "," +
      array[i].web.join(" ") +
      "," +
      array[i].category +
      "," +
      array[i].spoofing +
      "," +
      array[i].risk +
      "," +
      array[i].screenshot +
      "\r\n";

    str += line;
  }
  return str;
};

export const csvStringToJson = (
  csvString,
  setAddtionalDomains,
  additionalDomains
) => {
  const lines = csvString.split("\n");
  const headers = lines[0]
    .split(",")
    .map((item) => item.toLowerCase().trim().replace(" ", "_"));

  const jsonData = [];
  const extraDomains = [];

  for (let i = 1; i < lines.length; i++) {
    const currentLine = lines[i].split(",");

    if (currentLine.length !== headers.length) {
      // Skip lines that do not have the same number of fields as the headers
      continue;
    }

    const row = {};
    for (let j = 0; j < headers.length; j++) {
      row["id"] = uuidv4();
      if (currentLine[j] === "undefined" || currentLine[j] === "null") {
        row[headers[j]] = null;
      } else {
        row[headers[j]] = currentLine[j];
      }
    }

    row.web = row.web.split(" ");
    if (row.screenshot === "\r") row.screenshot = null;

    jsonData.push(row);
    extraDomains.push(row.detected_domain);
  }

  setAddtionalDomains([...additionalDomains, ...extraDomains]);

  return jsonData;
};
