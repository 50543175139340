import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../components/Dashboard/layout";
import { Box, CircularProgress, Container, Paper, Typography, Snackbar, Alert, Button, Tooltip, Select, MenuItem, Menu } from "@mui/material";
import TopLayout from "../../../components/Dashboard/toplayout";
import BreadcrumbSteps from "../../../components/steps-breadcrumbs";
import { useCallback, useEffect, useState } from "react";
import { getDomainMonitor, getModuleConfig, getPowerFile, startDomainTakeDown, startTakeDown } from "../../../api";
import TakeDownDomainTable from "../../../components/takedown-table";
import { InfoOutlined } from "@mui/icons-material";

const TakeDownDomain = () => {
    const location = useLocation();
    const { analysis_id } = useParams();

    const selectedDomains = location?.state?.selectedDomains;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [monitoringRespones, setMonitoringResponse] = useState();
    const [takeDownDomains, setTakeDownDomains] = useState([]);
    const [error, setError] = useState({ show: false, message: "" });

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [hasPOA, setHasPOA] = useState(false); // Simulating POA state


    const handleDropdownOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDropdownClose = () => {
        setAnchorEl(null);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsButtonDisabled(false); // Enable the button after file upload
            setUploadedFile(file);
            setHasPOA(true)
        }
    };

    const handleSelectedRowsChange = (selectedRows) => {
        setTakeDownDomains(selectedRows); // Update selected domains in state when rows are changed
    };

    useEffect(() => {
        if (!selectedDomains && selectedDomains?.length === 0 && analysis_id) {
            setLoading(true);
            getDomainMonitor(analysis_id)
                .then((response) => {
                    setMonitoringResponse(response?.data);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false);
                });
        }

        if (selectedDomains) {
            setLoading(false);
            setMonitoringResponse(selectedDomains)
        }
    }, [analysis_id, selectedDomains]);

    const handleCloseSnackbar = () => {
        setError({ ...error, show: false });
    };

    return (
        <DashboardLayout>
            <Container maxWidth="100%">
                <Box py={3} className="hidden md:block">
                    <TopLayout title="Take-down Domain" />
                </Box>

                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    <BreadcrumbSteps uid={analysis_id} />
                </Paper>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : (
                    monitoringRespones && (
                        <>
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 2,
                                    mb: 3,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box display="flex" alignItems="center" gap={2}>
                                    <Typography variant="h6" component="h2">
                                        Take-down Domain
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{
                                            color: "primary.main",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            "&:hover": { textDecoration: "none" },
                                        }}
                                        onClick={() => {
                                            getPowerFile().then((response) => {
                                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                                const link = document.createElement("a");
                                                link.href = url;
                                                link.setAttribute("download", "authorization_letter.odt");
                                                document.body.appendChild(link);
                                                link.click();
                                            })

                                        }}
                                    >
                                        Download empty authorization letter
                                    </Typography>
                                </Box>

                                <Box display="flex" alignItems="center" gap={2}>
                                    <label htmlFor="upload-file">
                                        <Button variant="outlined" component="span">
                                            Upload authorization letter
                                        </Button>
                                    </label>
                                    <input
                                        id="upload-file"
                                        type="file"
                                        accept=".odt,.pdf,.doc,.docx"
                                        hidden
                                        onChange={(e) => handleFileUpload(e)}
                                    />
                                </Box>
                            </Paper>


                            <Paper elevation={3} sx={{ p: 3 }}>
                                <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" mb={2}>
                                    <Typography variant="h6" component="h2">
                                        Domains to Take-Down
                                    </Typography>
                                </Box>

                                <TakeDownDomainTable
                                    monitoringRespones={monitoringRespones}
                                    onSelectedRowsChange={handleSelectedRowsChange}
                                    hasPOA={hasPOA}
                                    uploadedFile={uploadedFile}
                                    analysis_id={analysis_id}
                                />
                            </Paper>
                        </>
                    )
                )}
            </Container>

            {/* Snackbar for Error Message */}
            <Snackbar
                open={error.show}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {error.message}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default TakeDownDomain;
