import React, { useState, useEffect } from "react";
import { Line } from "rc-progress";

export const CustomProgressBar = ({ value, active }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count !== value) {
      const timer = setInterval(() => {
        if (count < value) {
          setCount((prevCount) => {
            const newCount = prevCount + 469;
            return newCount > value ? value : newCount;
          });
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [count, value]);

  return (
    <Line
      percent={active ? parseInt((count / value) * 100) : 100}
      strokeWidth={4}
      strokeColor="#30AFEE"
    />
  );
};

export const IncreasingNumber = ({ value, active }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count !== value) {
      const timer = setInterval(() => {
        if (count < value) {
          setCount((prevCount) => {
            const newCount = prevCount + 469;
            return newCount > value ? value : newCount;
          });
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [count, value]);

  return <p>{active ? count : value}</p>;
};
