import React, { useState, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Chip,
    TablePagination,
    TableSortLabel,
    TextField,
    Box,
    IconButton,
    Select,
    MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';

const AlertsTable = ({ alerts, onDeleteAlert, preselectFilter, condensed = false }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState('created_at');
    const [order, setOrder] = useState('desc');

    console.log(preselectFilter)

    const alertConditions = useSelector(state => state.scan.config.alert_conditions)

    const [filters, setFilters] = useState({
        domain: "",
        alertType: preselectFilter ? preselectFilter : "",
        isSeen: ""
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: value,
        }));
    };


    const filteredAlerts = useMemo(() => {
        return alerts.filter((alert) => {
            const matchesDomain = alert.domain.toLowerCase().includes(filters.domain.toLowerCase());
            const matchesAlertType =
                filters.alertType === "" ||
                alertConditions?.some(
                    (condition) => condition.code === alert.condition && condition.code === filters.alertType
                );
            const matchesSeenStatus =
                filters.isSeen === "" ||
                (filters.isSeen === "seen" && alert.seen) ||
                (filters.isSeen === "sent" && !alert.seen);

            return matchesDomain && matchesAlertType && matchesSeenStatus;
        });
    }, [alerts, filters, alertConditions]);

    const sortedAlerts = useMemo(() => {
        const comparator = (a, b) => {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        };
        return filteredAlerts.sort((a, b) => {
            return order === 'desc' ? comparator(a, b) : -comparator(a, b);
        });
    }, [filteredAlerts, order, orderBy]);

    return (
        <Paper>
            <TableContainer>
                <Table stickyHeader aria-label="alerts table">
                    <TableHead>
                        <TableRow>
                            {!condensed &&
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'domain'}
                                        direction={orderBy === 'domain' ? order : 'asc'}
                                        onClick={() => handleRequestSort('domain')}
                                    >
                                        Domain
                                    </TableSortLabel>
                                </TableCell>

                            }
                            <TableCell>Message</TableCell>
                            {!condensed &&
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'alertType'}
                                        direction={orderBy === 'alertType' ? order : 'asc'}
                                        onClick={() => handleRequestSort('alertType')}
                                    >
                                        Alert Type
                                    </TableSortLabel>
                                </TableCell>
                            }
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'created_at'}
                                    direction={orderBy === 'created_at' ? order : 'asc'}
                                    onClick={() => handleRequestSort('created_at')}
                                >
                                    Created At
                                </TableSortLabel>
                            </TableCell>
                            {!condensed &&
                                <TableCell>Status</TableCell>
                            }

                            <TableCell>Actions</TableCell>
                        </TableRow>
                        <TableRow>
                            {!condensed &&
                                <TableCell>
                                    <TextField
                                        variant="standard"
                                        placeholder="Filter by Domain"
                                        value={filters.domain}
                                        onChange={(e) => handleFilterChange("domain", e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                            }
                            <TableCell></TableCell>
                            {!condensed &&
                                <TableCell>
                                    <Select
                                        variant="standard"
                                        value={filters.alertType}
                                        onChange={(e) => handleFilterChange("alertType", e.target.value)}
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {alertConditions?.map((condition) => (
                                            <MenuItem key={condition.code} value={condition.code}>
                                                {condition.name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </TableCell>
                            }
                            <TableCell></TableCell>
                            <TableCell>
                                {!condensed &&
                                    <Select
                                        variant="standard"
                                        value={filters.isSeen}
                                        onChange={(e) => handleFilterChange("isSeen", e.target.value)} // Correct field name
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        <MenuItem value="seen">Seen</MenuItem>
                                        <MenuItem value="sent">Sent</MenuItem>
                                    </Select>
                                }
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedAlerts
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((alert) => (
                                <TableRow key={alert.uid}>
                                    {!condensed &&
                                        <TableCell>{alert.domain}</TableCell>
                                    }
                                    <TableCell>{alert.message}</TableCell>
                                    {!condensed &&
                                        <TableCell>{alertConditions?.filter(x => x.code === alert.condition)[0].name}</TableCell>
                                    }
                                    <TableCell>{formatDate(alert.created_at)}</TableCell>
                                    {!condensed &&
                                        <TableCell>
                                            {/* <Chip
                                                label={alert.seen ? 'Seen' : 'Sent'}
                                                color={alert.seen ? 'success' : 'warning'}
                                                size="small"
                                            /> */}
                                            {alert.seen ? "Seen" : "Sent"}
                                        </TableCell>
                                    }
                                    <TableCell>
                                        <IconButton onClick={() => onDeleteAlert(alert.uid)} size="small">
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredAlerts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};
export default AlertsTable;