import React from "react";

import avatar from "../../assets/images/user_account.png"

const ReviewCard = (props) => {
    return(
        <div>
            <p className="text-sm md:text-base pb-5 max-w-3xl mx-auto">
            {props.text}
            </p>
            <div className="flex items-center justify-center gap-4">
                {/* <img src={avatar} alt="" /> */}
                <div>
                    <p className="text-sm font-bold">{props.name}</p>
                    <p className="text-xs text-grey">{props.job}</p>
                </div>
            </div>
          </div>
    )
}

export default ReviewCard;