import axios from "axios";

import authService from "../services/authService";
import { API_BASE_URL } from "../config";

export const LOGIN_REQUEST = "@account/login-request";
export const LOGIN_SUCCESS = "@account/login-success";
export const LOGIN_FAILURE = "@account/login-failure";
export const SILENT_LOGIN = "@account/silent-login";
export const LOGOUT = "@account/logout";
export const REGISTER = "@account/register";
export const REGISTER_FAILURE = "@account/register-failure";
export const UPDATE_PROFILE = "@account/update-profile";

export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(email, password);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user,
        },
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();
    dispatch({
      type: LOGOUT,
    });
  };
}

export function register(user) {
  return async (dispatch) => {
    try {
      const response_ip = await fetch("https://api.ipify.org?format=json");
      const ip_json = await response_ip.json();
      const instance = axios.create({
        headers: {
          "Ip-Address": ip_json.ip,
        },
      });
      const response = await instance.post(`${API_BASE_URL}/register/`, user);

      if (response.status) {
        localStorage.setItem("uid", response.data.uid);

        // dispatch({
        //   type: REGISTER,
        //   payload: {
        //     user: response.data.user
        //   }
        // });
      }
    } catch (error) {
      // dispatch({ type: REGISTER_FAILURE });
      throw error;
    }
  };
}

export function activateaccount(user) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/account/activate/${user.uid}/${user.token}/`,
        user
      );

      if (response.status) {
        authService.setSession(response.data.token);
        dispatch({
          type: REGISTER,
          payload: {
            user: response.data,
          },
        });
      }
    } catch (error) {
      dispatch({ type: REGISTER_FAILURE });
      throw error;
    }
  };
}

export function forgotpassword(email) {
  return async (dispatch) => {
    try {
      await axios.post(`${API_BASE_URL}/password-reset/`, {
        email: email,
      });
    } catch (error) {
      throw error;
    }
  };
}

export function resetpassword(data) {
  return async (dispatch) => {
    try {
      await axios.post(`${API_BASE_URL}/password-reset/confirm/`, data);
    } catch (error) {}
  };
}

export function updateProfile(update) {
  const request = axios.patch(`${API_BASE_URL}/user-details/`, update);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: response.data,
      })
    );
  };
}

export function deleteaccount() {
  return async (dispatch) => {
    try {
      await axios.post(`${API_BASE_URL}/account/close/`);
    } catch (error) {
      throw error;
    }
  };
}

export function changepassword(data) {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/change-password/`,
        data
      );
      authService.setSession(response.data.token);
    } catch (error) {
      throw error;
    }
  };
}
