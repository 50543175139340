import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState, useEffect, useRef, useCallback } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { RotatingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useSteps } from "../hooks/useSteps";
import Stepper from "@keyvaluesystems/react-stepper";
import AnalysisTable from "../components/Dashboard/Analyze/analyzeTable";
import { initialModules } from "../data/initialModulesData";
import usePolling from "../hooks/usePolling";
import { ProgressBarWithVariants } from "../components/common/progressBarWithVariants";
import Modal from "react-responsive-modal";

import hero from "../assets/images/home_hero.png";
import home_vector from "../assets/images/home_vector.png";
import shape from "../assets/images/shape.png";
import ProgressBar from "../components/common/progressbar";
import ShadowBlog from "../components/common/shadowBlog";
import DomainScanForm from "../components/Home/DomainScanForm";

import "animate.css/animate.compat.css";

function Home() {
  const navigate = useNavigate();
  const hasRunOnce = useRef(false);

  const account = useSelector((state) => state.account);

  const [modulesList, setModulesList] = useState([]);
  const [isOpenImage, setOpenImage] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isEnableNext, setEnableNext] = useState(false);
  const [isLoadingMessage, setLoadingMessage] = useState(true);
  const [isScan, setScan] = useState(false);
  const { currentStepIndex, steps, handleStepClick, setCurrentStepIndex } =
    useSteps();

  const [analysUid, setAnalysUid] = useState(
    localStorage.getItem("analysis_id") || ""
  );

  const [scanResult, setScanResult] = useState(
    localStorage.getItem("previous_scan")
      ? JSON.parse(localStorage.getItem("previous_scan"))
      : []
  );

  const { enqueueSnackbar } = useSnackbar();

  const [scanStatus, setScanStatus] = useState(false);
  const [totalModules, setTotalModules] = useState(0);
  const [domain, setDomain] = useState(sessionStorage.getItem("domain") || "");

  const {
    status,
    analysisId,
    setStatus: setPollingStatus,
    analysisResult,
    scanProgress,
    resultCount,
    setModuleIsLoaded,
  } = usePolling();

  const handleDomainSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting, resetForm }
  ) => {
    setLoading(true);
    setScan(false);
    setCurrentStepIndex(0);
    setEnableNext(false);
    localStorage.removeItem("analysis_id");
    localStorage.removeItem("analysis_request");
    localStorage.removeItem("previous_scan");
    setModulesList([]);
    try {
      if (
        localStorage.getItem("default") === "true" &&
        !localStorage.getItem("accessToken")
      ) {
        navigate("/login");
      }

      const _allModules = modulesList
        .filter((item) => item.value !== "module_domain_word_combination")
        .map((item) => ({
          module_name: item.value,
          enabled: true
        }));

      const request_body = {
        domain: values.domain,
        modules: _allModules,
        config: {},
        keywords: [],
      };

      setLoading(true);
      setScan(true);

      const response_ip = await fetch("https://api.ipify.org?format=json");

      const ip_json = await response_ip.json();

      const token = await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/token/`);

      const instance = axios.create({
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "Ip-Address": ip_json.ip,
          "X-CSRFTOKEN":
            token,
        },
      });

      await instance
        .post(
          `${process.env.REACT_APP_BASIC_API_URL}/analysis/start/`,
          request_body
        )
        .then(async (response) => {
          setLoading(false);
          const analysisId = response.data.analysis_id;
          setAnalysUid(analysisId);

          const _data = response.data.variations.map((item) => ({
            module_name: item.module_name,
            count: item.count,
          }));

          localStorage.setItem("previous_scan", JSON.stringify(response.data));
          localStorage.setItem("analysis_id", response.data.analysis_id);
          setScanResult(_data);
          localStorage.setItem("step", 0);
          setCurrentStepIndex(0);
          setScanStatus(true);
          setStatus({ success: true });
          setSubmitting(false);
          resetForm();
        })
        .catch((error) => {
          const message = "Failed to load response data!";
          enqueueSnackbar(message, { variant: "error" });
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        });
    } catch (error) {
      const message = "Failed to scan a domain!";
      enqueueSnackbar(message, { variant: "error" });
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  const handleImageView = useCallback((url) => {
    setOpenImage(true);
    setPreviewImage(url);
  }, []);

  const handleImageModalClose = useCallback(() => {
    setOpenImage(false);
    setPreviewImage("");
  }, []);

  const handleRegister = () => {
    localStorage.setItem("step", 0);
    localStorage.setItem("anonymous", true);
    navigate("/register");
  };

  const handleLogin = () => {
    localStorage.setItem("step", 1);
    sessionStorage.setItem("start_scan", true);
    navigate("/login?redirecturl=/dashboard/scan");
  };

  const styles = {
    LineSeparator: () => ({ backgroundColor: "#30AFEE" }),
    ActiveNode: () => ({ backgroundColor: "#30AFEE" }),
    CompletedNode: () => ({ backgroundColor: "#30AFEE" }),
  };

  useEffect(() => {
    const getModules = async () => {
      setLoading(true);
      setPollingStatus(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASIC_API_URL}/analysis/configs/`
        );
        const modules = response.data.modules.map((item) => ({
          value: item.code,
          label: item.name,
          loading: false,
        }));
        setTotalModules(modules.length);
        setModulesList(modules);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (!hasRunOnce.current) {
      getModules();
      hasRunOnce.current = true;
    }
  }, []);

  useEffect(() => {
    if (scanResult.length > 0) {
      const counts = scanResult.map((item) => item.count);
      const maxCount = counts.length > 0 ? Math.max(...counts) : 0;

      const totalModulesProgress = (maxCount * 1000) / 469;

      if (scanStatus) {
        const progressInterval = setTimeout(() => {
          setEnableNext(true);
          setModuleIsLoaded(true);
          localStorage.setItem("analysis_id", analysUid);
          setCurrentStepIndex(1);
        }, totalModulesProgress);

        return () => clearTimeout(progressInterval);
      }
    }
  }, [
    scanResult,
    scanStatus,
    setCurrentStepIndex,
    setModuleIsLoaded,
    analysUid,
    setEnableNext,
  ]);

  useEffect(() => {
    setDomain(sessionStorage.getItem("domain"));
  }, []);

  useEffect(() => {
    if (isEnableNext && !!account && !!account.user) {
      setTimeout(() => {
        localStorage.setItem("step", 1);
        setCurrentStepIndex(1);
        setModuleIsLoaded(true);
        navigate("/dashboard/scan");
      }, 5000);
    }
  }, [isEnableNext, account, navigate, setModuleIsLoaded, setCurrentStepIndex]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingMessage(false);
    }, 12000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="max-w-xl lg:max-w-[80rem] px-4 mx-auto py-[3rem] lg:py-[5rem]">
      <div className="grid grid-cols-6 lg:grid-cols-12 gap-y-8 lg:gap-0 pb-[3rem]">
        <div className="col-span-6">
          <div className="bg-hero1 lg:pt-[7vh]">
            <div className=" relative">
              <p className="text-2xl lg:text-4xl font-bold">
                Domain Monitoring
              </p>
              <img
                src={home_vector}
                className="absolute top-[2rem] lg:top-[2.25rem] w-[90px] lg:w-[120px]"
                alt=""
              />
            </div>
            <span className="font-bold text-2xl lg:text-4xl">
              <span className="text-primary">security application </span>that
              automatically detects{" "}
              <span className="text-primary">phishing sites</span> and{" "}
              <span className="text-primary">scams</span>
            </span>
          </div>
          <div className="pt-4">
            <p className="text-xs lg:text-sm leading-relaxed">
              DomainMonitor’s unique detection engine leverages 150 terabytes of
              structured data sets including images, text, and threat
              intelligence. Large language models (LLMs) train on proprietary
              data that addresses real-life security challenges and deliver
              outcomes that deliver the fastest mean time to detect (MTTD) and
              mean time to remediate (MTTR) phishing, impersonations, and other
              fraudulent online activities.
            </p>
          </div>
        </div>
        <div className="hidden lg:flex items-end">
          <ScrollAnimation animateIn="fadeInUp">
            <img src={shape} alt="" />
          </ScrollAnimation>
        </div>
        <div className="col-span-6 lg:col-span-5">
          <ScrollAnimation animateIn="fadeIn">
            <img src={hero} alt="" />
          </ScrollAnimation>
        </div>
      </div>

      <DomainScanForm
        handleDomainSubmit={handleDomainSubmit}
        modulesList={modulesList}
      />

      {((!isLoading && isScan) || (!isLoading && analysisId)) && (
        <div className="border-t-[0.5px] my-6 py-4">
          <div className="w-full flex justify-between items-center">
            <div className="w-full">
              <Stepper
                steps={steps}
                styles={styles}
                orientation="horizontal"
                labelPosition="bottom"
                currentStepIndex={currentStepIndex}
                onStepClick={handleStepClick}
              />
            </div>
          </div>
        </div>
      )}

      {!isLoadingMessage &&
        ((!isLoading && isScan) || (!isLoading && analysisId)) && (
          <div className="mt-5 flex items-center">
            <ProgressBarWithVariants
              totalVariants={scanProgress.totalVariants}
              completed={scanProgress.completed}
            />
            {scanProgress.completed === 0 && (
              <div className="border-[1px] border-primary p-5 rounded-xl">
                <p>
                  If the scan hasn't progressed, you just have to wait longer.
                  Our service tries to find similar domains.
                </p>
              </div>
            )}
          </div>
        )}

      {isLoadingMessage && !isLoading && analysisId && (
        <div className="min-h-[150px] flex justify-center items-center">
          <div className="">
            <div className="w-full flex justify-center">
              <RotatingLines
                visible={true}
                height="48"
                width="48"
                strokeColor="blue"
                strokeWidth="3"
                animationDuration="10"
                ariaLabel="rotating-lines-loading"
              />
            </div>
            <p className="font-bold">Please wait a moment</p>
          </div>
        </div>
      )}
      <div className="h-[3rem]"></div>
      {currentStepIndex === 1 && !isLoadingMessage && (
        <>
          {!isLoadingMessage && !(!!account && !!account.user) && (
            <>
              {analysisResult.length > 0 ? (
                <div className="mt-10 flex justify-between items-center p-4 border-primary border-2 rounded-2xl">
                  <p className="text-black text-xl font-bold">
                    Partial result below. The analysis is still in progress.
                    Please login or register to see the full result.
                  </p>
                  <div className="flex gap-4">
                    <button
                      onClick={handleRegister}
                      className="cursor-pointer before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
                    >
                      <span className="text-sm md:text-base relative z-10">
                        Register
                      </span>
                    </button>
                    <button
                      onClick={handleLogin}
                      className="cursor-pointer before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
                    >
                      <span className="text-sm md:text-base relative z-10">
                        Login
                      </span>
                    </button>
                  </div>
                </div>
              ) : null}

              <AnalysisTable
                currentPage={1}
                handleImageView={handleImageView}
                filteredResult={analysisResult}
              />
            </>
          )}
        </>
      )}

      {currentStepIndex === 0 && isScan && (
        <ShadowBlog>
          {isLoading && (
            <div className="flex flex-col justify-between">
              <div>
                <RotatingLines
                  visible={true}
                  height="48"
                  width="48"
                  color="gray"
                  strokeWidth="3"
                  animationDuration="10"
                  ariaLabel="rotating-lines-loading"
                />
              </div>
              <p className="text-grey">
                Please wait as we prepare to upload the modules.
              </p>
            </div>
          )}

          <p className="pb-4 text-sm lg:text-base font-bold">
            Scanning for similar domains
          </p>

          {!isLoading && scanResult.length > 0 && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6">
              <div className="col-span-1">
                <div className="flex justify-between pb-2">
                  <p className="text-xs lg:text-sm"></p>
                  <p className="text-xs lg:text-sm font-semibold">
                    Possible Domain Variations
                  </p>
                </div>
              </div>
              <div className="col-span-1">
                <div className="flex justify-between pb-2">
                  <p className="text-xs lg:text-sm"></p>
                  <p className="text-xs lg:text-sm font-semibold">
                    Possible Domain Variations
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6">
            {!isLoading &&
              scanResult &&
              scanResult.length > 0 &&
              scanResult.map((item, index) => (
                <ProgressBar
                  key={index}
                  label={item.module_name}
                  total={item.count}
                  status={scanStatus}
                />
              ))}
            {isLoading && (
              <>
                {initialModules.map((item, index) => (
                  <ProgressBar
                    key={index}
                    initialModules={true}
                    isLoading={true}
                    label={item.module_name}
                    total={item.count}
                    status={scanStatus}
                  />
                ))}
              </>
            )}
          </div>

          <div className="min-h-[40px]">
            {!isLoading && scanResult.length === 0 && (
              <div className="flex justify-center items-center text-grey">
                There is no scan result.
              </div>
            )}
          </div>

          {!isLoading && isEnableNext && scanStatus && account?.user && (
            <div className="flex justify-between pt-8">
              <p className="text-grey">
                The scan has finished. Please wait 5 seconds or click next to
                see the results.
              </p>
              <button
                onClick={() => (window.location.href = "/dashboard/scan")}
                className="cursor-pointer before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
              >
                <span className="text-sm md:text-base relative z-10">Next</span>
              </button>
            </div>
          )}

          {!isLoading &&
            !isEnableNext &&
            scanStatus &&
            scanResult.length > 0 ? (
            <div className="flex justify-between items-center pt-4">
              <div className="flex gap-2">
                <RotatingLines
                  visible={true}
                  height="48"
                  width="48"
                  color="blue"
                  strokeWidth="3"
                  animationDuration="10"
                  ariaLabel="rotating-lines-loading"
                />
                <div className="flex flex-col justify-between">
                  <p className="font-bold">Please wait...</p>
                  <p className="text-grey">
                    We are collecting the results. The scan can take up to 15
                    minutes. Please click next once the scan has finished.
                  </p>
                </div>
              </div>
              <button
                disabled
                className="before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-grey/70 cursor-not-allowed text-white"
              >
                <span className="text-sm md:text-base relative z-10">Next</span>
              </button>
            </div>
          ) : (
            <>
              <button className="before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-grey/70 cursor-not-allowed text-white">
                <span className="text-sm md:text-base relative z-10">Next</span>
              </button>
            </>
          )}
        </ShadowBlog>
      )}

      <div className="h-[3rem]"></div>
      <Modal
        open={isOpenImage}
        onClose={handleImageModalClose}
        center
        styles={{
          modal: {
            top: "0%",
            transform: "translateY(-50%)",
            maxHeight: "90%",
            minHeight: "50%",
            minWidth: "50%",
            overflow: "auto",
            borderRadius: "30px",
          },
        }}
      >
        <img src={previewImage} className="" alt="preview" />
      </Modal>
    </div>
  );
}

export default Home;
