import React from "react";
import ShadowBlog from "../../../components/common/shadowBlog";
import video from "../../../assets/images/videowatch.png"

const Help = () => {
  return (
    <div>
      <p className="font-bold pb-2">Help</p>
      <span className="pb-4 font-semibold">
        You find the help to spoofguard under our WIKI:{" "}
        <span>
          <a className="text-primary underline cursor-pointer" target="_blank" href="https://spoofguard.wiki.com/help">
            https://spoofguard.wiki.com/help
          </a>
        </span>.
        For some introduction into the tool we recommend watching the videos
        below
      </span>
      <ShadowBlog>
        <p className="font-bold pb-4">Video Tutorials</p>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
            <div>
                <img src={video} alt="" />
                <p className="font-bold pt-4 pb-2">Lorem ipsum dolor sit amet.</p>
                <p className="text-sm text-grey">Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. </p>
            </div>
            <div>
                <img src={video} alt="" />
                <p className="font-bold pt-4 pb-2">Lorem ipsum dolor sit amet.</p>
                <p className="text-sm text-grey">Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. </p>
            </div>
            <div>
                <img src={video} alt="" />
                <p className="font-bold pt-4 pb-2">Lorem ipsum dolor sit amet.</p>
                <p className="text-sm text-grey">Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. </p>
            </div>
            <div>
                <img src={video} alt="" />
                <p className="font-bold pt-4 pb-2">Lorem ipsum dolor sit amet.</p>
                <p className="text-sm text-grey">Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. </p>
            </div>
        </div>
      </ShadowBlog>
    </div>
  );
};

export default Help;
