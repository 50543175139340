import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    Typography,
    TableCell,
    TableRow,
    IconButton,
    Switch,
    Button,
    Grid,
    Card,
    CardContent,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { getSingleMonitor, getSingleMonitorAlerts, updateSingleMonitor } from "../../api";
import DomainInfoCard from "../domain-info-card";
import AlertsTable from "../alerts-table";
import SnackBarWrapper from "../wrappers/snackbar-wrapper";

const CollapsibleRow = ({ monitor, monitorId, onMonitorDelete, conditionsList, setError, setSuccess }) => {
    const [open, setOpen] = useState(false);
    const [alerts, setAlerts] = useState(false);
    const [rowDetails, setRowDetails] = useState(null);
    const [monitorEnabled, setMonitorEnabled] = useState(monitor.enabled);
    const [monitorConditions, setMonitorConditions] = useState({});
    const [conditionsChanged, setConditionsChanged] = useState({});

    const loadRowDetails = useCallback(() => {
        if (!rowDetails) {
            getSingleMonitor(monitor.uid)
                .then((response) => {
                    setRowDetails(response.data);

                    // Preselect conditions based on the API response
                    setMonitorConditions((prev) => ({
                        ...prev,
                        [response.data.uid]: conditionsList.reduce((acc, condition) => {
                            acc[condition.code] = response.data.conditions.includes(condition.code);
                            return acc;
                        }, {}),
                    }));

                    getSingleMonitorAlerts(monitor.uid).then((alertsResponse) => {
                        setAlerts(alertsResponse.data)
                    }).catch(() => {
                        setError({show: true, message: "Failed to load alerted changes"})
                    })
                })
                .catch((err) => {
                    console.error("Failed to load monitor details", err);
                });
        }
    }, [conditionsList, monitor.uid, rowDetails, setError]);

    useEffect(() => {
        if (monitorId) {
            if (monitorId === monitor.uid) {
                setOpen(true);
                loadRowDetails();
            }
        }
    }, [monitorId, monitor.uid, loadRowDetails])

    const handleRowClick = () => {
        setOpen(!open);
        if (!open) loadRowDetails();
    };

    const handleConditionToggle = (domainId, condition) => {
        setMonitorConditions((prev) => {
            const updatedConditions = {
                ...prev,
                [domainId]: {
                    ...prev[domainId],
                    [condition]: !prev[domainId]?.[condition], // Toggle condition
                },
            };

            setConditionsChanged((prevChanged) => ({
                ...prevChanged,
                [domainId]: JSON.stringify(updatedConditions[domainId]) !== JSON.stringify(prev[domainId]),
            }));

            return updatedConditions;
        });
    };

    const handleUpdateConditions = (domainId) => {
        const updatedConditions = Object.entries(monitorConditions[domainId])
            .filter(([_, value]) => value)
            .map(([key]) => key);

        setConditionsChanged((prev) => ({ ...prev, [domainId]: false }));

        const data = {
            monitor_ids: [monitor.uid],
            conditions: updatedConditions,
        };

        updateSingleMonitor(monitor.analysis_id, data)
            .then((response) => {
                setMonitorEnabled(response.data[0].enabled);
                setSuccess({ show: true, message: "Monitor updated successfully" });
            })
            .catch(() => {
                setError({ show: true, message: "Could not update monitor" });
            });
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation(); // Prevent row click event
        onMonitorDelete(monitor);
    };

    const handleDeleteModal = (monitor) => {

    }

    return (
        <>
            <TableRow
                hover
                onClick={handleRowClick} // Make the row clickable
                style={{ cursor: "pointer" }}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent row click event
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{monitor.domain}</TableCell>
                <TableCell>{new Date(monitor.created_at).toLocaleDateString()}</TableCell>
                <TableCell>
                    <Switch
                        checked={monitorEnabled}
                        onChange={(e) => {
                            e.stopPropagation(); // Prevent row click event
                            updateSingleMonitor(monitor.analysis_id, {
                                monitor_ids: [monitor.uid],
                                enabled: !monitorEnabled,
                            })
                                .then((response) => {
                                    setMonitorEnabled(response.data[0].enabled);
                                    setSuccess({ show: true, message: "Monitor updated successfully" });
                                })
                                .catch(() => {
                                    setError({ show: true, message: "Could not update monitor" });
                                });
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                </TableCell>
                <TableCell>{monitor?.grouped_alerts?.total_count}</TableCell>
                <TableCell>
                    {/* <Chip
                        label={getTakedownStatus()}
                        color={
                            getTakedownStatus() === "Pending"
                                ? "primary"
                                : getTakedownStatus() === "Completed"
                                    ? "success"
                                    : "warning"
                        }
                        sx={{ fontWeight: "bold" }}
                    /> */}
                    N/A
                </TableCell>
                <TableCell>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            setOpen(!open);
                        }}
                    >
                        <RemoveRedEyeOutlined />
                    </IconButton>
                    <IconButton
                        color="error"
                        onClick={handleDeleteClick} // Prevent row click
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            {open && (
                <TableRow>
                    <TableCell colSpan={8} sx={{ pb: 2, pt: 2 }}>
                        {rowDetails ? (
                            <Box sx={{ display: "flex", gap: 4, flexDirection: "column" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                                    <Typography variant="subtitle1">Monitor Conditions</Typography>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        disabled={!conditionsChanged[rowDetails.uid]}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent row click
                                                            handleUpdateConditions(rowDetails.uid);
                                                        }}
                                                    >
                                                        Update Conditions
                                                    </Button>
                                                </Box>
                                                <Grid container spacing={2}>
                                                    {conditionsList.map((condition) => (
                                                        <Grid item xs={6} sm={4} key={condition.code}>
                                                            <Button
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                onClick={(e) => {
                                                                    e.stopPropagation(); // Prevent row click
                                                                    handleConditionToggle(rowDetails.uid, condition.code);
                                                                }}
                                                                sx={{
                                                                    backgroundColor: monitorConditions[rowDetails.uid]?.[condition.code]
                                                                        ? "primary.main"
                                                                        : "transparent",
                                                                    color: monitorConditions[rowDetails.uid]?.[condition.code]
                                                                        ? "white"
                                                                        : "primary.main",
                                                                    "&:hover": {
                                                                        backgroundColor: monitorConditions[rowDetails.uid]?.[condition.code]
                                                                            ? "primary.dark"
                                                                            : "primary.light",
                                                                    },
                                                                }}
                                                            >
                                                                {condition.name}
                                                            </Button>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" gutterBottom>Initial Scan results</Typography>
                                        <Box sx={{ height: 'calc(100% - 32px)' }}>
                                            <DomainInfoCard domain analysis_id={rowDetails.analysis_id} domainInfo={rowDetails} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                                <Typography variant="h6">Alerted Changes</Typography>
                                                {/* <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => console.log(`Start takedown for ${rowDetails.domain}`)}
                                                >
                                                    Start Takedown
                                                </Button> */}
                                            </Box>
                                            <Box sx={{ flexGrow: 1 }}>
                                                {alerts &&
                                                    <AlertsTable alerts={alerts} onDeleteAlert={handleDeleteModal} condensed={true} />
                                                }
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Box>
                        ) : (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress size={24} />
                            </Box>
                        )}
                    </TableCell>
                </TableRow >
            )}
        </>
    );
};

export default CollapsibleRow;
