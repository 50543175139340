import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useState } from "react";

const BrandModal = ({isModalOpen, onModalClose, onModalSubmit}) => {

    const [brandName, setBrandName] = useState("");
    const [logoFile, setLogoFile] = useState(null);

    const handleSubmitModal = () => {
        onModalSubmit({ brand_name: brandName, logo: logoFile })
    }

    return (
        <>
              {/* Modal for adding brand or logo */}
              <Dialog open={isModalOpen} onClose={onModalClose} maxWidth="sm" fullWidth>
                <DialogTitle>Add Brand or Logo</DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} pt={1}>
                        <TextField
                            label="Brand Name"
                            variant="outlined"
                            fullWidth
                            value={brandName}
                            onChange={(e) => setBrandName(e.target.value)}
                        />
                        <Button variant="outlined" component="label">
                            Upload Logo
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => setLogoFile(e.target.files[0])}
                            />
                        </Button>
                        {logoFile && (
                            <Typography variant="body2" color="textSecondary">
                                {logoFile.name}
                            </Typography>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onModalClose} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmitModal}
                        variant="contained"
                        color="primary"
                        disabled={!brandName && !logoFile}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    ) 
}

export default BrandModal;