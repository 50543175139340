import React, { useEffect, useState } from "react";
import axios from "axios";

const LicensedInfo = () => {
  const [subscriptionData, setSubscriptionData] = useState(null);

  const getSubscription = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/memberships/`
      );
      setSubscriptionData(response.data.current);
    } catch (error) {
      console.error("Failed to fetch subscription data", error);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  const getPrice = (planType) => {
    switch (planType) {
      case "Startup":
        return 200;
      case "Standard":
        return 300;
      case "Premium":
        return 400;
      default:
        return 0;
    }
  };
  const price = subscriptionData ? getPrice(subscriptionData.plan_type) : 0;

  const handleCancelSubscription = async (uid) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASIC_API_URL}/memberships/${uid}/cancel/`
      );
      window.location.reload();
    } catch (error) {
      console.error("Failed to cancel subscription", error);
    }
  };
  return (
    <div className="">
      <div className="rounded-lg shadow-xl p-[12px] md:p-[25px] bg-white hover:shadow-2xl w-fit">
        <div className="flex flex-col gap-4 md:gap-8 w-fit">
          {subscriptionData ? (
            <>
              <div className="md:flex items-center">
                <p className="text-grey min-w-[300px]">Current License</p>
                <p className="font-bold">${price.toFixed(2)}/month</p>
              </div>
              <div className="md:flex items-center">
                <p className="text-grey min-w-[300px]">Current ID</p>
                <p className="font-bold">{subscriptionData.uid}</p>
              </div>
              <div className="md:flex items-center">
                <p className="text-grey min-w-[300px]">Expiry Date</p>
                <p className="font-bold">
                  {new Date(subscriptionData.end_date).toLocaleDateString()}
                </p>
              </div>
              <div className="md:flex items-center">
                <p className="text-grey min-w-[300px]">Remaining Analysis</p>
                <p className="font-bold">
                  {subscriptionData.remaining_analysis}
                </p>
              </div>

              <div className="md:flex items-center">
                <p className="text-grey min-w-[300px]">Remaining Takedown</p>
                <p className="font-bold">
                  {subscriptionData.remaining_takedown}
                </p>
              </div>

              <div className="md:flex items-center">
                <p className="text-grey min-w-[300px]">Cancel Subscription</p>
                <button
                  type="submit"
                  className="before:ease relative h-[40px] w-[170px] rounded-full overflow-hidden bg-red text-white shadow-2xl transition-all  before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:before:-translate-x-40"
                  onClick={() => handleCancelSubscription(subscriptionData.uid)}
                >
                  <span className="text-sm lg:text-base relative z-10">
                    Click to Cancel
                  </span>
                </button>
              </div>
              <div className="md:flex items-center">
                <p className="text-grey min-w-[300px]">Upgrade Subscription</p>
                <button
                  type="submit"
                  className="before:ease relative h-[40px] w-[170px] rounded-full overflow-hidden  bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:before:-translate-x-40"
                >
                  <span className="text-sm lg:text-base relative z-10">
                    Click to Upgrade
                  </span>
                </button>
              </div>
            </>
          ) : (
            <p className="text-grey">You don't have an active subscription</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LicensedInfo;
