import React, { useEffect, useState, useRef, useCallback } from "react";

import axios from "axios";
import moment from "moment";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "react-responsive-modal";
import ResponsivePagination from "react-responsive-pagination";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { convertToCSV } from "../../../utiles/convertToCSV";

import DomainScanForm from "../../../components/Dashboard/domainScanForm";
import ScanResults from "../../../components/Dashboard/scanResult";
import warning from "../../../assets/images/icons/warning.png";
import ShadowBlog from "../../../components/common/shadowBlog";
import ScanTable from "../../../components/Dashboard/scanTable";

import "react-responsive-modal/styles.css";
import "react-tooltip/dist/react-tooltip.css";

const ScanContent = ({ setCurrentStepIndex }) => {
  const hasRunOnce = useRef(false);

  const [searchParams] = useSearchParams();
  const domain = searchParams.get("domain");
  const [selectedModule, setSelectedModule] = useState([
    { value: "", label: "" },
  ]);
  const [modulesList, setModulesList] = useState([]);
  // Screenshot
  const [isOpenImage, setOpenImage] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [isLoading1, setLoading1] = useState(false);
  const [isLoading2, setLoading2] = useState(false);
  const [isLoading3, setLoading3] = useState(false);
  const [isLoading4, setLoading4] = useState(false);

  const [isErrorKeywords, setErrorKeywords] = useState(false);
  const [scanResult, setScanResult] = useState(null);
  const [isPreviousScan, setPreviousScan] = useState(false);

  const [analysisId, setAnalysisId] = useState("");
  const [analysisResult, setAnalysisResult] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [isWarning, setWarning] = useState(false);

  const [isBack, setBack] = useState(
    localStorage.getItem("isback") === 0 ? true : false
  );
  const [step, setStep] = useState(localStorage.getItem("step"));

  const handleWarningClose = () => {
    setWarning(false);
  };

  function handleImageView(url) {
    setOpenImage(true);
    setPreviewImage(url);
  }

  function handleImageModalClose() {
    setOpenImage(false);
    setPreviewImage("");
  }
  const handleAnalyzeAll = useCallback(() => {
    localStorage.setItem("analysis_request", JSON.stringify(analysisResult));
    setCurrentStepIndex(1);
  }, [analysisResult, setCurrentStepIndex]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const itemIdList = analysisResult.map((item) => item.id);
      setSelectedItems(itemIdList);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (itemId) => {
    let newItems = [];
    if (
      selectedItems &&
      selectedItems.length > 0 &&
      selectedItems.includes(itemId)
    ) {
      newItems = selectedItems.filter((item) => item !== itemId);
    } else {
      newItems = [...selectedItems, itemId];
    }
    setSelectedItems(newItems);
    if (analysisResult.length === newItems.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  function handlePageChange(page) {
    setCurrentPage(page);
  }

  const handleAnalyze = () => {
    if (selectedItems.length === 0) {
      setWarning(true);
    } else {
      const anlaysis_data =
        selectedItems.length > 0
          ? analysisResult.filter((item) => selectedItems.includes(item.id))
          : analysisResult;
      localStorage.setItem("analysis_request", JSON.stringify(anlaysis_data));
      setCurrentStepIndex(1);
      setSelectedItems([]);
    }
  };

  const handleExportCSV = () => {
    if (analysisResult.length > 0) {
      const _data = analysisResult;
      try {
        const csvData = new Blob([convertToCSV(_data)], { type: "text/csv" });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement("a");
        link.href = csvURL;
        link.download = `analysis-${moment().format(
          "YYYY-MM-DD-hh-mm-SS"
        )}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error creating CSV:", error);
      }
    }
  };

  useEffect(() => {
    if (!hasRunOnce.current && step) {
      axios
        .get(`${process.env.REACT_APP_BASIC_API_URL}/analysis/configs/`)
        .then((response) => {
          setModulesList([
            {
              value: "all",
              label: "All",
            },
            {
              value: "all_no_keyword",
              label: "All except to custom keyword",
            },
            ...response.data.modules.map((item) => {
              return {
                value: item.code,
                label: item.name,
              };
            }),
          ]);
        })
        .catch((error) => console.log(error));

      hasRunOnce.current = true;
    }
  }, [step]);

  useEffect(() => {
    const getAnalysisResult = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASIC_API_URL}/analysis/${analysisId}/`,
          {
            params: {
              analysis_id: analysisId,
            },
          }
        );
        const _data = [];

        response.data.dns_lookups_formatted.forEach((_item) => {
          let web_port = [];
          if (_item.http_open) web_port.push("http");
          if (_item.https_open) web_port.push("https");

          _data.push({
            id: uuidv4(),
            domain: response.data.domain,
            detected_domain: _item.domain,
            brand: null,
            date: response.data.created_at,
            mx_record: _item.mx_record,
            ns_record: _item.ns_record,
            a_record: _item.a_record,
            dns_record: _item.dns_server,
            enumeration: _item.module_name,
            whois: _item.whois?.whois_server,
            web: web_port,
            phishing: _item.phishing,
            screenshot: _item.screenshot,
            category: null,
            spoofing: null,
            risk: null,
          });
        });

        setAnalysisResult(_data);
        setLoading1(false);
        setLoading4(true);
      } catch (error) {
        console.log(error);
      }
    };

    if (isLoading2 && analysisId !== "") {
      setLoading1(true);
      setTimeout(getAnalysisResult, 10000);
    }
  }, [analysisId, isLoading2]);

  useEffect(() => {
    if (isBack) {
      setScanResult(JSON.parse(localStorage.getItem("previous_scan")));
    }
  }, [isBack]);

  useEffect(() => {
    if (!isLoading3 && isLoading2 && !isLoading1 && analysisResult) {
      handleAnalyzeAll();
    }
  }, [isLoading1, isLoading2, isLoading3, analysisResult, handleAnalyzeAll]);

  const total =
    analysisResult && analysisResult.length > 0
      ? parseInt(analysisResult.length / 10) + 1
      : 0;
  const totalCount =
    analysisResult && analysisResult.length > 0 ? analysisResult.length : 0;
  return (
    <div className="py-4 md:py-8">
      <ShadowBlog>
        <p className="pb-4 text-sm lg:text-base font-bold">Test your Domain</p>

        <DomainScanForm
          domain={domain}
          modulesList={modulesList}
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          isErrorKeywords={isErrorKeywords}
          setErrorKeywords={setErrorKeywords}
          setLoading2={setLoading2}
          setLoading3={setLoading3}
          setScanResult={setScanResult}
          setPreviousScan={setPreviousScan}
          setAnalysisId={setAnalysisId}
          setLoading4={setLoading4}
          setBack={setBack}
        />

        {(selectedModule.some((item) => item.value === "all") ||
          selectedModule.some(
            (item) => item.value === "module_domain_word_combination"
          )) && (
          <div
            className={
              isErrorKeywords
                ? "bg-light rounded-lg p-4 mt-6 border border-red focus:outline-none focus:border-red"
                : "bg-light rounded-lg p-4 mt-6"
            }
          >
            {isErrorKeywords ? (
              <>
                <p className="text-xs lg:text-sm text-red font-semibold">
                  Error: "Keywords" must be required since you have chosen
                  "Domain Word Combination" module.
                </p>
              </>
            ) : (
              <>
                <p className="text-xs lg:text-sm">
                  You've chosen the "Domain Word Combination" module. Please
                  deactivate the module or enter keywords relevant to your
                  domain in this section. But first you need to create them on
                  the setting page. For example, if a Swiss bank using the
                  domain ubs.com were to utilize this module, appropriate
                  keywords might include "bank", "financial", "Swiss",
                  "ebanking", and so on. This could generate domain variations
                  like ubs-ebanking.com or ubs-financials.com.
                </p>
              </>
            )}
          </div>
        )}
      </ShadowBlog>
      {((!isLoading3 && !isLoading4) || isBack) && scanResult && (
        <>
          <ScanResults
            scanResult={scanResult}
            isLoading1={isLoading1}
            isLoading2={isLoading2}
            isLoading3={isLoading3}
            isBack={isBack}
            isPreviousScan={isPreviousScan}
            handleAnalyzeAll={handleAnalyzeAll}
          />
        </>
      )}
      {isLoading3 && (
        <>
          <div className="min-h-[150px] flex justify-center items-center">
            <div className="">
              <div className="w-full flex justify-center">
                <RotatingLines
                  visible={true}
                  height="48"
                  width="48"
                  color="grey"
                  strokeWidth="3"
                  animationDuration="10"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
              <p className="font-bold pt-2">Please wait...</p>
            </div>
          </div>
        </>
      )}

      {!isLoading3 &&
        isLoading2 &&
        !isLoading1 &&
        analysisResult &&
        analysisResult.length > 0 && (
          <>
            <div className="flex justify-between items-center py-4">
              <div>
                <button
                  className="flex justify-center items-center cursor-pointer before:ease relative h-[36px] md:h-[40px] w-[130px] px-4 border border-primary rounded-full overflow-hidden bg-white text-primary shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-primary before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
                  onClick={handleExportCSV}
                >
                  <p className="text-sm lg:text-base font-semibold">Export</p>
                </button>
              </div>
            </div>

            <ScanTable
              analysisResult={analysisResult}
              currentPage={currentPage}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
              selectedItems={selectedItems}
              handleCheckboxChange={handleCheckboxChange}
              handleImageView={handleImageView}
            />

            <div className="flex justify-between pr-[45px] pt-4">
              <p className="font-semibold">
                Showing{" "}
                {Math.min(currentPage * 10, totalCount) > 0
                  ? `${(currentPage - 1) * 10 + 1}`
                  : ""}{" "}
                {Math.min(currentPage * 10, totalCount) > 0 ? "to" : ""}{" "}
                {Math.min(currentPage * 10, totalCount)} of {totalCount} entries
              </p>
              <div className="w-[300px]">
                <ResponsivePagination
                  total={total}
                  current={currentPage}
                  onPageChange={(page) => handlePageChange(page)}
                />
              </div>
            </div>
            <div className="flex justify-end pt-4">
              <button
                onClick={handleAnalyze}
                className="cursor-pointer before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
              >
                <span className=" text-sm md:text-base relative z-10">
                  Next step
                </span>
              </button>
            </div>
          </>
        )}
      <Modal
        open={isWarning}
        onClose={handleWarningClose}
        center
        styles={{
          modal: {
            top: "-30%",
            transform: "translateY(-50%)",
            maxHeight: "90%",
            overflow: "auto",
            borderRadius: "30px",
          },
        }}
      >
        <div className="p-4">
          <div className="flex justify-center">
            <img src={warning} width={72} alt="" />
          </div>
          <div className="flex justify-center py-4">
            <div className="text-lg font-semibold">Warning</div>
          </div>
          <div className="w-[400px]">
            <p className="text-grey">
              No selected items. In order to analyze a domain, you must select
              some items of below table.
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={isOpenImage}
        onClose={handleImageModalClose}
        center
        styles={{
          modal: {
            top: "0%",
            transform: "translateY(-50%)",
            maxHeight: "90%",
            minHeight: "50%",
            minWidth: "50%",
            overflow: "auto",
            borderRadius: "30px",
          },
        }}
      >
        <img src={previewImage} className="" alt="preview" />
      </Modal>
    </div>
  );
};

export default ScanContent;
