import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../components/Dashboard/layout";
import { Box, CircularProgress, Container, Paper, Typography, Snackbar, Alert, Button, Chip, Tooltip } from "@mui/material";
import TopLayout from "../../../components/Dashboard/toplayout";
import { useEffect, useRef, useState } from "react";
import { getSingleDomainAnalysis, startFurtherDomainAnalysis, stopAnalysis } from "../../../api";
import AnalyzeResultsTable from "../../../components/analyze-results-table";
import { InfoOutlined } from "@mui/icons-material";
import BreadcrumbSteps from "../../../components/steps-breadcrumbs";
import AnalyzeScanStats from "../../../components/analyze-scan-stats";
import { useDispatch, useSelector } from "react-redux";
import BrandModal from "../../../components/brand-modal";
import { updateSelectedDomains } from "../../../slices/scan-slice";

const AnalyzeResults = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const modules = useSelector(state => state.scan.config.modules);
    //const selectedDomains = useSelector(state => state.scan.selected_domains);


    const pollResults = location.state?.poll;
    const analysisData = location.state?.data;
    const newScan = location.state?.new_scan;

    const { analysis_id } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDomains, setSelectedDomains] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [error, setError] = useState({ show: false, message: "" });
    const [buttonsDisabled, setButtonsDisabled] = useState(true);
    const [isScanning, setIsScanning] = useState(false);
    const intervalIdRef = useRef(null); // Ref to store the interval ID


    const handleSelectedRowsChange = (selectedRows) => {
        setSelectedDomains(selectedRows)
        setButtonsDisabled(selectedRows.length === 0)
    };


    useEffect(() => {

        const fetchData = () => {

            if (analysis_id) {
                getSingleDomainAnalysis(analysis_id)
                    .then((response) => {
                        setIsScanning(true)

                        setLoading(false);
                        const resultData = response?.data;
                        setData(resultData);
                        setCompletionPercentage(
                            Math.round((resultData.total_initial_done / resultData.total_variations) * 100)
                        );

                        if (resultData.total_initial_done === resultData.total_variations) {
                            clearInterval(intervalIdRef.current);
                            setIsScanning(false)
                        }
                    })
                    .catch((err) => console.log(err));
            }
        };

        if (!pollResults) {
            fetchData();
            setLoading(false);
        } else if (pollResults) {
            intervalIdRef.current = setInterval(fetchData, 2000);
        }

        return () => clearInterval();
    }, [analysis_id, pollResults]);

    const handleSubmitModal = async (modalData) => {
        if (selectedDomains?.length === 0) {
            setError({ show: true, message: "Please select at least one domain to proceed" });
            return;
        }

        if (!modalData) {
            setError({ show: true, message: "Please add your brand info in the modal" });
            return;
        }

        const formData = new FormData();
        formData.append("brand", modalData.brand_name);
        formData.append("urlscore_scan", true);
        formData.append("spoofing", true);

        selectedDomains?.forEach((domain) => {
            formData.append("domains", domain.domain);
        });

        if (modalData.logo) {
            formData.append("logo_scan", true);
            formData.append("logo", modalData.logo);
        } else {
            formData.append("logo_scan", false);
        }

        try {

            setLoading(true)
            const response = await startFurtherDomainAnalysis(analysis_id, formData);

            if (response?.data) {
                navigate(`/dashboard/domain-investigation/${response.data.analysis_id}`, { state: { poll: true, selectedDomains: selectedDomains } });
            }
        } catch (error) {
            setError({ show: true, message: "Error starting investigation" });
            console.error("Error submitting brand data:", error);
        }
    };

    const handleCloseSnackbar = () => {
        setError({ ...error, show: false });
    };

    const handleDetectSpoofingClick = () => {
        setIsModalOpen(true)
    }

    const handleTakedownClick = () => {
        if (selectedDomains?.length === 0) {
            setError({ show: true, message: "Please select at least one domain to proceed" });
            return;
        }

        navigate(`/dashboard/takedown-domains/${analysis_id}`, { state: { selectedDomains: selectedDomains } })
    }

    const handleSetupMonitoringClick = () => {
        if (selectedDomains?.length === 0) {
            setError({ show: true, message: "Please select at least one domain to proceed" });
            return;
        }

        navigate(`/dashboard/setup-monitoring/${analysis_id}`, { state: { selectedDomains: selectedDomains } })
    }

    const handleModalClose = () => {
        setIsModalOpen(false)
    }

    const handleStopScan = () => {
        console.log("handleStopScan")
        
        stopAnalysis(analysis_id).then(() => {
            setLoading(false);
            setIsScanning(false)
            clearInterval(intervalIdRef.current);
        })
    }

    return (
        <DashboardLayout>
            <Container maxWidth="100%">
                <Box py={3} className="hidden md:block">
                    <TopLayout title="Analyze Results" />
                </Box>

                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    <BreadcrumbSteps uid={analysis_id} />
                </Paper>

                {loading ? (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="50vh">
                        <Typography mb={2} variant="body1" sx={{ color: "#333" }}>
                            Generating domain variations, please wait…
                        </Typography>
                        <CircularProgress />
                    </Box>
                ) : (
                    data && (
                        <>
                            <Paper
                                elevation={3}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 2,
                                    backgroundColor: "#e8f4fc",
                                    borderLeft: "5px solid #2196f3",
                                    borderRadius: 1,
                                    margin: "auto",
                                    mb: 3,
                                }}
                            >
                                <InfoOutlined sx={{ color: "#2196f3", marginRight: 1 }} />
                                <Typography variant="body1" sx={{ color: "#333" }}>
                                    Select the domains you wish to investigate. Optionally, provide a brand name or upload a logo, then click 'Investigate Domain Spoofing' to proceed with a detailed analysis.
                                </Typography>
                            </Paper>

                            <AnalyzeScanStats onStopScan={handleStopScan} isScanning={isScanning} completionPercentage={completionPercentage} data={data.dns_lookups_formatted} updated_at={data.updated_at} domain={data.domain} />

                            {/* <BrandInputSection onBrandSubmit={handleBrandSubmit} onFileUpload={handleFileUpload} /> */}

                            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                                <Typography variant="h6" component="h2" mb={2}>
                                    Scan Modules
                                </Typography>
                                <Box display="flex" gap={1} flexWrap="wrap">
                                    {data.params.modules.map((module) => (
                                        <Chip
                                            key={module.module_name}
                                            label={modules?.filter(x => x.code === module.module_name)[0].name}
                                            color="primary"
                                            variant="outlined"
                                        />
                                    ))}
                                </Box>
                            </Paper>


                            <Paper elevation={3} sx={{ p: 3 }}>
                                <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" pt={2} mb={2}>
                                    <Typography variant="h6" component="h2">
                                        Results
                                    </Typography>

                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Tooltip title={buttonsDisabled ? "Select at least one domain to enable Spoofing Detection." : ""} arrow>
                                            <span>
                                                <Button
                                                    disabled={buttonsDisabled}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleDetectSpoofingClick()}
                                                >
                                                    Detect Spoofing
                                                </Button>
                                            </span>
                                        </Tooltip>

                                        <Tooltip title={buttonsDisabled ? "Select at least one domain to Setup Monitoring." : ""} arrow>
                                            <span>
                                                <Button
                                                    disabled={buttonsDisabled}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSetupMonitoringClick()}
                                                >
                                                    Setup Monitoring
                                                </Button>
                                            </span>
                                        </Tooltip>

                                        <Tooltip title={buttonsDisabled ? "Select at least one domain to initiate a Takedown." : ""} arrow>
                                            <span>
                                                <Button
                                                    disabled={buttonsDisabled}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleTakedownClick()}
                                                >
                                                    Takedown Domain
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </Box>

                                <AnalyzeResultsTable
                                    selectedDomains={selectedDomains}
                                    dnsLookupsFormatted={data.dns_lookups_formatted}
                                    onSelectedRowsChange={handleSelectedRowsChange}
                                    selectedModules={data?.params?.modules}
                                />
                            </Paper>
                        </>
                    )
                )}
            </Container>

            <Snackbar
                open={error.show}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {error.message}
                </Alert>
            </Snackbar>

            {isModalOpen &&
                <BrandModal isModalOpen={isModalOpen} onModalClose={handleModalClose} onModalSubmit={handleSubmitModal} />
            }
        </DashboardLayout>
    );
};

export default AnalyzeResults;
