import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  TablePagination,
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionDetails,
  TextField,
  MenuItem,
  Select,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ChevronDown } from "lucide-react";
import { useSelector } from "react-redux";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

const DomainInvestigationTable = ({ furtherResultsFormatted, onSelectedRowsChange }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [expandedRow, setExpandedRow] = useState(null);
  const modules = useSelector((state) => state.scan.config.modules);

  const [filters, setFilters] = useState({
    domain: "",
    httpHttps: "", // Replace http and https filters with a combined filter
    domainSpoofing: "",
    logoSpoofing: "",
    module: "",
  });

  const moduleOptions = useMemo(() => {
    const uniqueModules = [...new Set(furtherResultsFormatted.map((item) => item.module_name))];
    return uniqueModules.length > 1 ? uniqueModules : [];
  }, [furtherResultsFormatted]);

  const handleSelectRow = (item) => {
    const isSelected = selectedRows.find((selected) => selected.domain === item.domain);
    const newSelectedRows = isSelected
      ? selectedRows.filter((selected) => selected.domain !== item.domain)
      : [...selectedRows, item];

    setSelectedRows(newSelectedRows);
    onSelectedRowsChange(newSelectedRows);
  };

  const handleSelectAll = (e) => {
    const newSelectedRows = e.target.checked ? [...filteredData] : [];
    setSelectedRows(newSelectedRows);
    onSelectedRowsChange(newSelectedRows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpandRow = (domain) => {
    setExpandedRow(expandedRow === domain ? null : domain);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const filteredData = furtherResultsFormatted
    .filter((row) => {
      const domainSpoofingStatus = row.spoofing ? "True" : "False";
      const logoSpoofingStatus = row.logo_spoofing ? "True" : "False";

      const matchesHttpHttps =
        filters.httpHttps === "HTTP"
          ? row.http_open && !row.https_open
          : filters.httpHttps === "HTTPS"
            ? !row.http_open && row.https_open
            : filters.httpHttps === "HTTP/HTTPS"
              ? row.http_open && row.https_open
              : filters.httpHttps === "N/A"
                ? !row.http_open && !row.https_open
                : true;

      return (
        row.domain.toLowerCase().includes(filters.domain.toLowerCase()) &&
        matchesHttpHttps &&
        (filters.domainSpoofing === "" || domainSpoofingStatus === filters.domainSpoofing) &&
        (filters.logoSpoofing === "" || logoSpoofingStatus === filters.logoSpoofing) &&
        (filters.module === "" || row.module_name === filters.module)
      );
    })
    .sort((a, b) => (b.urlscore || 0) - (a.urlscore || 0));

  const currentRows = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedRows.length === filteredData.length && filteredData.length > 0}
                  onChange={handleSelectAll}
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < filteredData.length
                  }
                />
              </TableCell>
              <TableCell>Domain</TableCell>
              <TableCell>Module</TableCell>
              <TableCell>Web</TableCell>
              <TableCell>Website Spoofing</TableCell>
              <TableCell>Brand Spoofing</TableCell>
              <TableCell>URL Score</TableCell>
              <TableCell>View Results</TableCell>
            </TableRow>
            {/* Filter Row */}
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>
                <TextField
                  variant="standard"
                  placeholder="Filter by Domain"
                  value={filters.domain}
                  onChange={(e) => handleFilterChange("domain", e.target.value)}
                  fullWidth
                />
              </TableCell>
              <TableCell>
                {moduleOptions.length > 1 ? (
                  <Select
                    variant="standard"
                    value={filters.module}
                    onChange={(e) => handleFilterChange("module", e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">All Modules</MenuItem>
                    {moduleOptions.map((module) => (
                      <MenuItem key={module} value={module}>
                        {modules?.filter(x => x.code === module)[0].name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
                    variant="standard"
                    placeholder="Module"
                    value={filters.module}
                    disabled
                    fullWidth
                  />
                )}
              </TableCell>
              <TableCell>
                <Select
                  variant="standard"
                  value={filters.httpHttps}
                  onChange={(e) => handleFilterChange("httpHttps", e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="HTTP">HTTP</MenuItem>
                  <MenuItem value="HTTPS">HTTPS</MenuItem>
                  <MenuItem value="HTTP/HTTPS">HTTP/HTTPS</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  variant="standard"
                  value={filters.domainSpoofing}
                  onChange={(e) => handleFilterChange("domainSpoofing", e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="True">True</MenuItem>
                  <MenuItem value="False">False</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  variant="standard"
                  value={filters.logoSpoofing}
                  onChange={(e) => handleFilterChange("logoSpoofing", e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="True">True</MenuItem>
                  <MenuItem value="False">False</MenuItem>
                </Select>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows.map((lookup) => (
              <React.Fragment key={lookup.domain}>
                <TableRow
                  hover
                  selected={expandedRow === lookup.domain}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleExpandRow(lookup.domain)} // Make the entire row clickable
                >
                  <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      checked={selectedRows.some((selected) => selected.domain === lookup.domain)}
                      onChange={() => handleSelectRow(lookup)}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => e.stopPropagation()}>
                      <ChevronDown />
                    </IconButton>
                    <a
                      href={`https://${lookup.domain}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#1976d2", textDecoration: "underline" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {lookup.domain}
                    </a>
                  </TableCell>
                  <TableCell>
                    {modules?.filter((x) => x.code === lookup.module_name)[0]?.name || "N/A"}
                  </TableCell>
                  <TableCell>
                    {lookup.http_open && lookup.https_open
                      ? "HTTP/HTTPS"
                      : lookup.http_open
                        ? "HTTP"
                        : lookup.https_open
                          ? "HTTPS"
                          : "N/A"}
                  </TableCell>
                  <TableCell>{lookup.spoofing ? "True" : "False"}</TableCell>
                  <TableCell>{lookup.logo_spoofing ? "True" : "False"}</TableCell>
                  <TableCell>{lookup.urlscore || "N/A"}</TableCell>
                  <TableCell>
                    <Tooltip title={"View Results"}>
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                        <RemoveRedEyeOutlined />
                      </Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                {expandedRow === lookup.domain && (
                  <TableRow>
                    <TableCell colSpan={10} sx={{ backgroundColor: "#f0f8ff" }}>
                      <Accordion expanded>
                        <AccordionDetails>
                          <Box sx={{ display: "flex", gap: 4 }}>
                            <Box sx={{ width: "40%", maxHeight: "30vh", minHeight: "30vh", overflow: "scroll" }}>
                              {lookup.screenshot ? (
                                <img
                                  src={lookup.screenshot}
                                  alt="Domain Screenshot"
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    borderRadius: 4,
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                  }}
                                  onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite error loop
                                    e.target.style.display = "none"; // Hide the broken image
                                    e.target.parentNode.innerHTML = `
                            <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; border: 1px dashed #ccc; border-radius: 4px; font-weight: bold; color: #999;">
                              No Webserver
                            </div>
                          `;
                                  }}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px dashed #ccc",
                                    borderRadius: 4,
                                    fontWeight: "bold",
                                    color: "#999",
                                  }}
                                >
                                  No Webserver
                                </Box>
                              )}
                            </Box>
                            <Box sx={{ width: "60%" }}>
                              <Typography variant="h6" mb={2} mt={2}>
                                WHOIS for {lookup.domain}
                              </Typography>
                              <Divider sx={{ my: 1 }} />
                              {[
                                { label: "Domain Name", value: lookup.whois?.domain_name || "N/A" },
                                { label: "Creation Date", value: lookup.whois?.creation_date || "N/A" },
                                { label: "Expiration Date", value: lookup.whois?.expiration_date || "N/A" },
                                { label: "Registrar", value: lookup.whois?.registrar || "N/A" },
                                {
                                  label: "Country",
                                  value: lookup.whois?.country || lookup.whois?.country_code || "N/A",
                                },
                                { label: "Address", value: lookup.whois?.address || "N/A" },
                                {
                                  label: "Abuse email",
                                  value:
                                    lookup.whois?.email ||
                                    lookup.whois?.emails ||
                                    lookup.whois?.abuse_email ||
                                    "N/A",
                                },
                              ].map(({ label, value }) => (
                                <Box key={label} sx={{ mb: 1 }}>
                                  <Typography variant="body2">
                                    <strong>{label}:</strong> {value}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50, 100]}
      />
    </Paper>
  );
};

export default DomainInvestigationTable;
