// import jwtDecode from 'jwt-decode';

import axios from "axios";
import { API_BASE_URL } from "../config";

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password) =>
    new Promise(async (resolve, reject) => {
      const response_ip = await fetch("https://api.ipify.org?format=json");
      const ip_json = await response_ip.json();
      const instance = axios.create({
        headers: {
          "Ip-Address": ip_json.ip,
        },
      });

      instance
        .post(`${API_BASE_URL}/login/`, { email: email, password: password })
        .then((response) => {
          if (response.data.token) {
            this.setSession(response.data.token);
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}/user-details/`)
        .then((response) => {
          if (response.data.uid) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `Token ${accessToken}`;
    } else {
      localStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => {
    return localStorage.getItem("accessToken");
  };

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    // const decoded = jwtDecode(accessToken);
    // const decoded = Date.now() / 1000;
    // const currentTime = Date.now() / 1000;

    // return decoded.exp > currentTime;
    return true;
  };

  isAuthenticated = () => {
    return !!this.getAccessToken();
  };
}

const authService = new AuthService();

export default authService;
