import React, { useState, useEffect } from 'react';
import {
    Box, Card, CardContent, Typography, Grid, Paper, Avatar, TableBody, TableCell, TableHead, TableRow, Tooltip,
    Table, TableContainer, TextField,
    Container,
    CircularProgress
} from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import GavelIcon from '@mui/icons-material/Gavel';
import InfoIcon from '@mui/icons-material/Info';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import DnsIcon from '@mui/icons-material/Dns';
import AnimationIcon from '@mui/icons-material/Animation';
import PolicyIcon from '@mui/icons-material/Policy';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import PhishingIcon from '@mui/icons-material/Phishing';
import TimelineIcon from '@mui/icons-material/Timeline';
import DashboardLayout from "../../components/Dashboard/layout";
import TopLayout from '../../components/Dashboard/toplayout';
import { getAnalysisStats } from '../../api/dashboard';
import { getAllAlerts, getAllMonitors, getAllTakeDowns, getAnalysis } from '../../api';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';


const takedownStatusesData = [
    { type: 'Drafts', percentage: 0, info: 'Takedowns requested but not yet submitted', link: "/dashboard/takedowns" },
    { type: 'Pending', percentage: 0, info: 'Takedowns submitted to the registrar and blacklist, pending completion', link: "/dashboard/takedowns" },
    { type: 'Completed', percentage: 0, info: 'Domains successfully takendown', link: "/dashboard/takedowns" },
];

// Utility function to filter and group records by date
const filterAndGroupByDate = (records, start, end, dateKey = 'updated_at') => {
    // Filter records within the date range
    const filteredRecords = records.filter((record) => {
        const recordDate = new Date(record[dateKey]);
        return recordDate >= start && recordDate <= end;
    });

    // Group records by their date
    return filteredRecords.reduce((acc, record) => {
        const recordDate = new Date(record[dateKey]).toISOString().split('T')[0];
        if (!acc[recordDate]) {
            acc[recordDate] = [];
        }
        acc[recordDate].push(record);
        return acc;
    }, {});
};


const generateDomainScanData = async (startDate, endDate, takeDowns, monitors) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const days = [];
    let currentDate = new Date(start);
    end.setHours(23, 59, 59, 999);

    try {
        // Parallel requests
        const requests = [
            getAnalysis(),
            //getAllMonitors(),
            //getAllTakeDowns()
        ];

        const [analysisResponse] = await Promise.all(requests);

        const analysisData = analysisResponse?.data?.results || [];
        const monitorsData = monitors || [];
        const takeDownData = takeDowns || [];


        // Filter and group each dataset by date
        const analysisByDate = filterAndGroupByDate(analysisData, start, end);
        const monitorsByDate = filterAndGroupByDate(monitorsData, start, end);
        const takeDownsByDate = filterAndGroupByDate(takeDownData, start, end);


        while (currentDate <= end) {
            const dateKey = currentDate.toISOString().split('T')[0];

            const totalVariationsForDate = (analysisByDate[dateKey] || []).reduce((sum, record) => {
                return sum + (record?.total_variations || 0);
            }, 0);

            const totalActiveDomainsForDate = (analysisByDate[dateKey] || []).reduce((sum, record) => {
                return sum + (record?.dns_lookups_formatted?.length || 0);
            }, 0);

            const totalActiveWebserversForDate = (analysisByDate[dateKey] || []).reduce((sum, record) => {
                return sum + (record?.dns_lookups_formatted?.filter(x => x.http_open || x.https_open).length || 0);
            }, 0);

            const totalMonitorsForDate = (monitorsByDate[dateKey] || []).length;
            const totalTakeDownsForDate = (takeDownsByDate[dateKey] || []).length;

            days.push({
                date: dateKey,
                generatedDomainVariations: totalVariationsForDate,
                activeDomains: totalActiveDomainsForDate,
                activeWebservers: totalActiveWebserversForDate,
                totalMonitors: totalMonitorsForDate,
                domainsForTakedown: totalTakeDownsForDate,
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }
    } catch (error) {
        console.error("Error fetching analysis data:", error);
    }

    return days;
};


const renderLegendText = (value, entry) => {
    const { color } = entry;
    return <span style={{ color, marginRight: '10px' }}>{value}</span>;
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Paper elevation={3} sx={{ p: 2, backgroundColor: 'white' }}>
                <Typography variant="body2" sx={{ mb: 1 }}>{`Date: ${label}`}</Typography>
                {payload.map((entry, index) => (
                    <Typography key={`item-${index}`} variant="body2" sx={{ color: entry.color, my: 0.5 }}>
                        {`${entry.name}: ${entry.value}`}
                    </Typography>
                ))}
            </Paper>
        );
    }
    return null;
};

const today = new Date();
const oneMonthAgo = new Date();
oneMonthAgo.setMonth(today.getMonth() - 1);

const formatDate = (date) => {
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
};


const DomainScanChart = ({ takeDowns, monitors }) => {
    const [startDate, setStartDate] = useState(formatDate(oneMonthAgo));
    const [endDate, setEndDate] = useState(formatDate(today));
    const [data, setData] = useState([]);

    const [activeSeries, setActiveSeries] = useState([]);

    const handleLegendClick = (dataKey) => {
        console.log(dataKey)

        if (activeSeries.includes(dataKey)) {
            setActiveSeries(activeSeries.filter(el => el !== dataKey));
        } else {
            setActiveSeries(prev => [...prev, dataKey]);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const result = await generateDomainScanData(startDate, endDate, takeDowns, monitors);
            setData(result);
        };

        fetchData();
    }, [startDate, endDate, takeDowns, monitors]);

    return (
        <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h5">
                    Domain Scanning Trends
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ width: 170 }}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ width: 170 }}
                    />
                </Box>
            </Box>
            <Box sx={{ width: '100%', height: 400, mt: 2 }}>
                <ResponsiveContainer>
                    <AreaChart
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 30
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            angle={-45}
                            textAnchor="end"
                            height={60}
                            tick={{ fontSize: 12 }}
                        />
                        <YAxis />
                        <RechartsTooltip content={<CustomTooltip />} />
                        <Legend
                            height={36}
                            iconType="square"
                            iconSize={10}
                            formatter={renderLegendText}
                            wrapperStyle={{
                                paddingTop: '20px',
                                cursor: "pointer"
                            }}
                            onClick={props => handleLegendClick(props.dataKey)}
                        />
                        <Area
                            hide={activeSeries.includes("generatedDomainVariations")}
                            type="monotone"
                            dataKey="generatedDomainVariations"
                            name="Generated Domain Variations"
                            stackId="1"
                            stroke="#003C78"
                            fill="#003C78"
                        />
                        <Area
                            hide={activeSeries.includes("activeDomains")}
                            type="monotone"
                            dataKey="activeDomains"
                            name="Active Domains Found"
                            stackId="2"
                            stroke="#2888AE"
                            fill="#2888AE"
                        />
                        <Area
                            hide={activeSeries.includes("activeWebservers")}
                            type="monotone"
                            dataKey="activeWebservers"
                            name="Active Webservers Found"
                            stackId="3"
                            stroke="#FFA533"
                            fill="#FFA533"
                        />
                        <Area
                            hide={activeSeries.includes("totalMonitors")}
                            type="monotone"
                            dataKey="totalMonitors"
                            name="Total Monitors Created"
                            stackId="4"
                            stroke="#FF6239"
                            fill="#FF6239"
                        />
                        <Area
                            hide={activeSeries.includes("domainsForTakedown")}
                            type="monotone"
                            dataKey="domainsForTakedown"
                            name="Domains Submitted for Takedown"
                            stackId="5"
                            stroke="#CF1B1A"
                            fill="#CF1B1A"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
        </Paper>
    );
};


const statConfigurations = [
    {
        key: "total_alerts_count",
        subtitle: "Total Alerts",
        color: "#FF7544",
        icon: CrisisAlertIcon,
        link: "/dashboard/alerts",
    },
    {
        key: "total_new_webservers_count",
        subtitle: "Total New Webservers",
        color: "#00488F",
        icon: DnsIcon,
        link: "/dashboard/alerts",
        state: {
            filter: "port_change"
        }
    },
    {
        key: "total_new_similar_domain_count",
        subtitle: "Total New Similar Domains",
        color: "#9C27B0",
        icon: AnimationIcon,
        link: "/dashboard/alerts",
        state: {
            filter: "new_similar_domain"
        }
    },
    {
        key: "total_high_risk_domains_count",
        subtitle: "Total High Risk Domains",
        color: "#FF7544",
        icon: PolicyIcon,
    },
    {
        key: "total_spoofed_count",
        subtitle: "Total Spoofed",
        color: "#f44336",
        icon: PrivacyTipIcon,
        link: "/dashboard/alerts",
        state: {
            filter: "is_spoofing"
        }
    },
    {
        key: "total_phishing_count",
        subtitle: "Total Phishing",
        color: "#f44336",
        icon: PhishingIcon,
        link: "/dashboard/alerts",
        state: {
            filter: "phishing_status"
        }
    },
];

const Dashboard = () => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedAlertRow, setSelectedAlertRow] = useState(null);
    const [selectedTakedown, setSelectedTakedown] = useState(null);
    const [analysisStats, setAnalysisStats] = useState(null);
    const [alerts, setAlerts] = useState(null);
    const [alertsToday, setAlertsToday] = useState(null);

    const [monitors, setMonitors] = useState(null);
    const [highAlertMonitors, setHighAlertMonitors] = useState(null);

    const [takeDowns, setTakeDowns] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        getAnalysisStats().then((response) => {
            const data = response?.data;
            if (!data) return;

            const stats = statConfigurations.map(({ key, subtitle, color, icon, link, state }) => ({
                title: data[key],
                subtitle,
                color,
                icon,
                link,
                state
            }));

            setAnalysisStats(stats);
        }).catch(() => {
            enqueueSnackbar("Unable to load stats", {
                variant: "error",
            });
        });;

        getAllTakeDowns().then((takeDownResponse) => {
            const takeDownData = takeDownResponse?.data;
            setTakeDowns(takeDownData);

            getAllAlerts().then((alertsResponse) => {
                const alertsData = alertsResponse?.data;
                if (!alertsData || !takeDownData) return;

                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

                const today = new Date();
                const todayStart = new Date(today.setHours(0, 0, 0, 0));
                const todayEnd = new Date(today.setHours(23, 59, 59, 999));

                // Get alerts for today
                const alertsToday = alertsData.filter((alert) => {
                    const alertDate = new Date(alert.updated_at);
                    return (
                        alertDate >= todayStart &&
                        alertDate <= todayEnd &&
                        takeDownData.some((takedown) => takedown.malicious_domain === alert.domain)
                    );
                });

                // Get alerts for the past 7 days
                const filteredAlerts = alertsData.filter((alert) =>
                    takeDownData.some((takedown) => takedown.malicious_domain === alert.domain) &&
                    new Date(alert.updated_at) >= sevenDaysAgo
                );

                setAlertsToday(alertsToday);
                console.log("Today's Alerts:", alertsToday);

                console.log("Filtered Alerts from the past 7 days:", filteredAlerts);
                setAlerts(filteredAlerts);
                setLoading(false);
            }).catch(() => {
                enqueueSnackbar("Unable to load alerts", {
                    variant: "error",
                });
            });;
        }).catch(() => {
            enqueueSnackbar("Unable to load takedowns", {
                variant: "error",
            });
        });


        getAllMonitors().then((response) => {
            const data = response?.data;
            if (!data) return;

            const filteredMonitors = data.sort((a, b) => {
                return (b.grouped_alerts?.total_count || 0) - (a.grouped_alerts?.total_count || 0);
            });
            setMonitors(data)
            setHighAlertMonitors(filteredMonitors)

        }).catch(() => {
            enqueueSnackbar("Unable to load monitors", {
                variant: "error",
            });
        });;

    }, []);

    return (
        <>
            <DashboardLayout>
                <Container maxWidth="100%" sx={{ mb: 4 }}>
                    <Box py={3} className="hidden md:block">
                        <TopLayout title="Dashboard" />
                    </Box>
                    <Box sx={{ flexGrow: 1, p: 1 }}>
                        <Grid container spacing={2} sx={{ mb: 1 }}>
                            {analysisStats?.map((item, index) => (
                                <Grid item xs={2} key={index}>
                                    <Card
                                        sx={{
                                            height: '100%',
                                            boxShadow: 3,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            cursor: "pointer"
                                        }}
                                        onClick={() => { navigate(item.link ? item.link : "", item.state ? { state: item.state } : {}) }}
                                    >
                                        <CardContent sx={{ p: '8px !important', flexGrow: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                                                {item.icon && (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <item.icon sx={{ color: item.color, fontSize: '1.5rem' }} />
                                                    </Box>
                                                )}
                                                <Box>
                                                    <Typography
                                                        variant="subtitle2"
                                                        component="div"
                                                        sx={{ color: item.color, fontSize: '1rem', fontWeight: 'bold' }}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: '1rem' }} color="text.secondary">
                                                        {item.subtitle}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>

                                </Grid>
                            ))}

                        </Grid>

                        {/* Domain Scan Chart */}
                        <Grid container spacing={2} sx={{ mb: 0, mt: 0 }}>
                            <Grid item xs={12}>
                                <DomainScanChart takeDowns={takeDowns} monitors={monitors} />
                            </Grid>
                        </Grid>

                        {/* Main Content */}
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            {/* Takedowns Row */}
                            <Grid item xs={12} container spacing={2} sx={{ justifyContent: 'space-between' }}>
                                {/* Takedowns by Status */}
                                <Grid item xs={12} md={2.75}>
                                    <Paper sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 3 }}>
                                        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                <AutoModeIcon sx={{ color: '#FF7544', mr: 1 }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Takedowns by Status</Typography>
                                                    <Typography color="text.secondary" sx={{ fontSize: '1rem' }}>
                                                        Get a breakdown of takedowns by their current status.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ height: '200px', overflow: 'auto', mt: 2 }}>
                                                {takedownStatusesData?.map((item) => (
                                                    <Box key={item.type} sx={{ mb: 1, cursor: "pointer" }} onClick={() => navigate(item?.link)}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, alignItems: 'center' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography sx={{ fontSize: '1rem', mr: 0.5 }}>{item.type}</Typography>
                                                                <Tooltip title={item.info} arrow>
                                                                    <InfoIcon sx={{ fontSize: '1rem', color: 'text.secondary', cursor: 'help' }} />
                                                                </Tooltip>
                                                            </Box>
                                                            <Typography sx={{ fontSize: '1rem' }}>{item.percentage}%</Typography>
                                                        </Box>
                                                        <Box sx={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: 2, height: 6 }}>
                                                            <Box
                                                                sx={{
                                                                    width: `${item.percentage}%`,
                                                                    backgroundColor:
                                                                        item.type === 'Drafts' ? '#2196F3' :
                                                                            item.type === 'Pending' ? '#FFA700' :
                                                                                item.type === 'Completed' ? '#5BD160' :
                                                                                    '#2196F3',
                                                                    borderRadius: 2,
                                                                    height: 6
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>

                                {/* Takedown Alerts */}
                                <Grid item xs={12} md={6.5}>
                                    <Paper sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 3 }}>
                                        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                <VisibilityIcon sx={{ color: '#FF7544', mr: 1 }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Takedown Alerts</Typography>
                                                    <Typography color="text.secondary" sx={{ fontSize: '1rem' }}>
                                                        Stay up to date with recent alerts for domains to be taken down.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ height: '200px', overflow: 'auto', mt: 2 }}>
                                                <TableContainer>
                                                    <Table size="small" sx={{
                                                        '& .MuiTableCell-root': {
                                                            fontSize: '1rem',
                                                            padding: '6px 16px',
                                                            borderBottom: '1px solid rgba(224, 224, 224, 0.4)',
                                                        },
                                                        '& .MuiTableCell-head': {
                                                            fontWeight: 'bold',
                                                            backgroundColor: 'background.paper',
                                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        },
                                                        tableLayout: 'fixed',
                                                    }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell width="20%">Malicious Domain</TableCell>
                                                                <TableCell width="60%">Alert Message</TableCell>
                                                                <TableCell width="20%" align="right">Time</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {alerts?.map((row, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{
                                                                        '&:nth-of-type(odd)': { bgcolor: 'action.hover' },
                                                                        cursor: 'pointer',
                                                                        '&:hover': { bgcolor: 'action.hover' },
                                                                    }}
                                                                    onClick={() => {
                                                                        navigate("/dashboard/monitoring", { state: { monitor_id: row.monitor_id, domain: row.domain } })
                                                                    }}
                                                                >
                                                                    <TableCell width="20%">{row.domain}</TableCell>
                                                                    <TableCell width="60%" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                                        {row.message}
                                                                    </TableCell>
                                                                    <TableCell width="20%" align="right"> {new Date(row.updated_at).toLocaleString('en-US', {
                                                                        year: 'numeric',
                                                                        month: 'short',
                                                                        day: 'numeric',
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        second: '2-digit',
                                                                        hour12: true, // Use 12-hour clock (set to false for 24-hour clock)
                                                                    })}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>

                                {/* Takedowns Submitted */}
                                <Grid item xs={12} md={2.75}>
                                    <Paper sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 3 }}>
                                        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                <GavelIcon sx={{ color: '#FF7544', mr: 1 }} />
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>All Submitted Takedowns</Typography>
                                                    <Typography color="text.secondary" sx={{ fontSize: '1rem' }}>
                                                        Review submitted takedown requests.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ height: '200px', overflow: 'auto', mt: 2 }}>
                                                <TableContainer>
                                                    <Table size="small" sx={{
                                                        '& .MuiTableCell-root': {
                                                            fontSize: '1rem',
                                                            padding: '6px 0',
                                                        },
                                                        '& .MuiTableCell-head': {
                                                            fontWeight: 'bold',
                                                        },
                                                        tableLayout: 'fixed',
                                                    }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell width="70%">Malicious Domain</TableCell>
                                                                <TableCell width="30%" align="right" sx={{ pr: 2 }}>Status</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {takeDowns?.map((row, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{
                                                                        '&:nth-of-type(odd)': { bgcolor: 'action.hover' },
                                                                        cursor: 'pointer',
                                                                        bgcolor: selectedTakedown === row.malicious_domain ? 'action.selected' : 'inherit',
                                                                        '&:hover': { bgcolor: 'action.hover' },
                                                                    }}
                                                                    onClick={() => {
                                                                        navigate("/dashboard/takedowns", { state: { takedown_id: row.uid } });
                                                                        console.log('Clicked takedown:', row.malicious_domain);
                                                                    }}
                                                                >
                                                                    <TableCell width="70%">{row.malicious_domain}</TableCell>
                                                                    <TableCell width="30%" align="right" sx={{ pr: 2 }}>N/A</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>

                            {/* High Alert Monitors and Alerts Generated Today */}
                            {/* High Alert Monitors */}
                            <Grid item xs={12} md={6}>
                                <Paper sx={{ p: 1, boxShadow: 3 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <CrisisAlertIcon sx={{ color: '#FFC53D', mr: 1 }} />
                                        <Box>
                                            <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>High Alert Monitors</Typography>
                                            <Typography color="text.secondary" sx={{ fontSize: '1rem' }}>
                                                Review active monitors generating high alerts.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ height: '200px', overflow: 'auto', mt: 2 }}>
                                        <TableContainer>
                                            <Table size="small" sx={{
                                                '& .MuiTableCell-root': {
                                                    fontSize: '1rem',
                                                    padding: '6px 0',
                                                },
                                                '& .MuiTableCell-head': {
                                                    fontWeight: 'bold',
                                                },
                                                tableLayout: 'fixed',
                                            }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width="80%">Domain</TableCell>
                                                        <TableCell w align="right" sx={{ pr: 2 }}>Alerts</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {highAlertMonitors?.filter(x => x.grouped_alerts?.total_count > 10)?.map((row, index) => (
                                                        <TableRow
                                                            key={row.domain}
                                                            sx={{
                                                                '&:nth-of-type(odd)': { bgcolor: 'action.hover' },
                                                                cursor: 'pointer',
                                                                bgcolor: selectedRow === row.domain ? 'action.selected' : 'inherit',
                                                                '&:hover': { bgcolor: 'action.hover' },
                                                            }}
                                                            onClick={() => {
                                                                navigate("/dashboard/monitoring", { state: { monitor_id: row.uid, domain: row.domain } })
                                                            }}
                                                        >
                                                            <TableCell sx={{
                                                                maxWidth: 0,
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                pr: 1
                                                            }}>
                                                                {row.domain}
                                                            </TableCell>
                                                            <TableCell align="right" sx={{ pr: 2 }}>{row.grouped_alerts?.total_count}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Paper>
                            </Grid>

                            {/* Alerts Generated Today */}
                            <Grid item xs={12} md={6}>
                                <Paper sx={{ p: 1, boxShadow: 3 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <NotificationAddIcon sx={{ color: '#FFC53D', mr: 1 }} />
                                        <Box>
                                            <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Alerts Generated Today</Typography>
                                            <Typography color="text.secondary" sx={{ fontSize: '1rem' }}>
                                                Stay up to date with recent alerts.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ height: '200px', overflow: 'auto', mt: 2 }}>
                                        <TableContainer>
                                            <Table size="small" sx={{
                                                '& .MuiTableCell-root': {
                                                    fontSize: '1rem',
                                                    padding: '6px 0',
                                                },
                                                '& .MuiTableCell-head': {
                                                    fontWeight: 'bold',
                                                },
                                                tableLayout: 'fixed',
                                            }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width="20%">Domain</TableCell>
                                                        <TableCell width="60%">Alert Message</TableCell>
                                                        <TableCell width="20%" align="right" sx={{ pr: 2 }}>Time</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {alertsToday?.map((row, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{
                                                                '&:nth-of-type(odd)': { bgcolor: 'action.hover' },
                                                                cursor: 'pointer',
                                                                bgcolor: selectedAlertRow === index ? 'action.selected' : 'inherit',
                                                                '&:hover': { bgcolor: 'action.hover' },
                                                            }}
                                                            onClick={() => {
                                                                navigate("/dashboard/monitoring", { state: { monitor_id: row.monitor_id, domain: row.domain } })
                                                            }}
                                                        >
                                                            <TableCell width="20%" sx={{
                                                                maxWidth: 0,
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                            }}>
                                                                {row.domain}
                                                            </TableCell>
                                                            <TableCell width="60%" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                                {row.message}
                                                            </TableCell>
                                                            <TableCell width="20%" align="right" sx={{ pr: 2 }}>{new Date(row.updated_at).toLocaleString('en-US', {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                second: '2-digit',
                                                                hour12: true, // Use 12-hour clock (set to false for 24-hour clock)
                                                            })}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </DashboardLayout>
        </>
    );
}

export default Dashboard;

