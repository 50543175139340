import axios from "axios";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const ModuleDictionary = () => {
  const [wordlists, setWordlists] = useState([]);
  const [newWordlist, setNewWordlist] = useState({
    name: "",
    code: "",
    words: "",
  });
  const [editingWordlist, setEditingWordlist] = useState(null);

  const fetchWordlists = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/wordlists/`
      );
      setWordlists(response.data);
    } catch (error) {
      console.error("Error fetching wordlists:", error);
    }
  };

  const createWordlist = async () => {
    try {
      const wordsArray = newWordlist.words
        .split(",")
        .map((word) => word.trim());
      const response = await axios.post(
        `${process.env.REACT_APP_BASIC_API_URL}/wordlists/`,
        {
          id: uuidv4(),
          name: newWordlist.name,
          code: newWordlist.code,
          words: wordsArray,
        }
      );
      setWordlists([...wordlists, response.data]);
      setNewWordlist({ name: "", code: "", words: "" });
    } catch (error) {
      console.error("Error creating wordlist:", error);
    }
  };

  const editWordlist = async (wordlistId) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_BASIC_API_URL}/wordlists/${wordlistId}/`,
        {
          name: editingWordlist.name,
          code: editingWordlist.code,
          words: editingWordlist.words,
        }
      );
      fetchWordlists();
      setEditingWordlist(null);
    } catch (error) {
      console.error("Error editing wordlist:", error);
    }
  };
  const deleteWordlist = async (wordlistId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASIC_API_URL}/wordlists/${wordlistId}/`
      );
      setWordlists(wordlists.filter((list) => list.id !== wordlistId));
    } catch (error) {
      console.error("Error deleting wordlist:", error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const contents = e.target.result;
      const wordsArray = contents.split("\n").map((word) => word.trim());
      const newWordlistData = {
        name: `Uploaded List - ${file.name}`,
        code: `uploaded_${Date.now()}`,
        words: wordsArray,
      };
      await createWordlist(newWordlistData);
    };

    reader.readAsText(file);
  };

  useEffect(() => {
    fetchWordlists();
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold text-center mb-6">
        Manage Custom Wordlists
      </h1>

      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Create New Wordlist</h2>
        <div className="space-y-4">
          <div>
            <label
              className="block mb-1 font-semibold"
              htmlFor="new-wordlist-name"
            >
              Name
            </label>
            <input
              id="new-wordlist-name"
              type="text"
              className="w-full p-2 border rounded"
              value={newWordlist.name}
              onChange={(e) =>
                setNewWordlist({ ...newWordlist, name: e.target.value })
              }
            />
          </div>
          <div>
            <label
              className="block mb-1 font-semibold"
              htmlFor="new-wordlist-code"
            >
              Code
            </label>
            <input
              id="new-wordlist-code"
              type="text"
              className="w-full p-2 border rounded"
              value={newWordlist.code}
              onChange={(e) =>
                setNewWordlist({ ...newWordlist, code: e.target.value })
              }
            />
          </div>
          <div>
            <label
              className="block mb-1 font-semibold"
              htmlFor="new-wordlist-words"
            >
              Words (comma-separated)
            </label>
            <textarea
              id="new-wordlist-words"
              className="w-full p-2 border rounded"
              value={newWordlist.words}
              onChange={(e) =>
                setNewWordlist({ ...newWordlist, words: e.target.value })
              }
            />
          </div>
          <button
            onClick={createWordlist}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
          >
            Create
          </button>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Upload Wordlist File</h2>
        <input
          type="file"
          accept=".txt"
          onChange={handleFileUpload}
          className="border p-2 rounded"
        />
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Existing Wordlists</h2>
        {wordlists.length > 0 ? (
          wordlists.map((list) => (
            <div
              key={list.id}
              className="mb-4 p-4 border gap-5 flex items-center rounded shadow-sm bg-gray-50"
            >
              <div>
                <h3 className="text-xl font-bold">{list.name}</h3>
                <p className="mb-4 text-gray-600">{list.words.join(", ")}</p>
              </div>
              <div className="space-x-4">
                <button
                  onClick={() => deleteWordlist(list.id)}
                  className="bg-primary text-white px-4 py-2 rounded hover:bg-red-600 transition"
                >
                  Delete
                </button>
                <button
                  onClick={() => setEditingWordlist(list)}
                  className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition"
                >
                  Edit
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No wordlists found.</p>
        )}
      </div>

      {editingWordlist && (
        <div className="bg-white shadow-md rounded-lg p-6 mt-8">
          <h2 className="text-2xl font-semibold mb-4">Edit Wordlist</h2>
          <div>
            <label
              className="block mb-1 font-semibold"
              htmlFor="edit-wordlist-name"
            >
              Name
            </label>
            <input
              id="edit-wordlist-name"
              type="text"
              className="w-full p-2 border rounded mb-4"
              value={editingWordlist.name}
              onChange={(e) =>
                setEditingWordlist({ ...editingWordlist, name: e.target.value })
              }
            />
          </div>
          <div>
            <label
              className="block mb-1 font-semibold"
              htmlFor="edit-wordlist-code"
            >
              Code
            </label>
            <input
              id="edit-wordlist-code"
              type="text"
              className="w-full p-2 border rounded mb-4"
              value={editingWordlist.code}
              onChange={(e) =>
                setEditingWordlist({ ...editingWordlist, code: e.target.value })
              }
            />
          </div>
          <div>
            <label
              className="block mb-1 font-semibold"
              htmlFor="edit-wordlist-words"
            >
              Words (comma-separated)
            </label>
            <textarea
              id="edit-wordlist-words"
              className="w-full p-2 border rounded mb-4"
              value={editingWordlist.words.join(", ")}
              onChange={(e) =>
                setEditingWordlist({
                  ...editingWordlist,
                  words: e.target.value.split(",").map((word) => word.trim()),
                })
              }
            />
          </div>
          <button
            onClick={() => editWordlist(editingWordlist.id)}
            className="bg-primary text-white px-4 py-2 rounded hover:bg-green-600 transition"
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default ModuleDictionary;
