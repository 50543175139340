import React from "react";
import notfound from "../assets/images/404.png";

function NotFound() {
  return (
    <div className="min-h-[100vh] flex items-center">
      <div className="max-w-5xl mx-auto flex flex-col justify-center items-center gap-4 px-4">
        <p className="text-base md:text-lg xl:text-2xl font-bold">404: The page you are looking for isn’t here</p>
        <p className="text-sm md:text-base text-grey text-center">
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation.
        </p>
        <img src={notfound} alt="" />
        <a
          href="/"
          className="flex items-center text-sm lg:text-base bg-primary text-white max-w-[200px] px-8 h-[40px] rounded-full mt-4"
        >
          Back To Home
        </a>
      </div>
    </div>
  );
}

export default NotFound;
