import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { setUserData, logout } from "../actions/accountActions";
import authService from "../services/authService";

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout()),
      });
      authService.handleAuthentication();
      if (authService.isAuthenticated()) {
        await authService
          .loginInWithToken()
          .then((user) => {
            dispatch(setUserData(user));
          })
          .catch((error) => {
            window.location.href = "/login";
          });
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    // return <SplashScreen />;
    return;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any,
};

export default Auth;
