import React, { useState, useMemo } from 'react';
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton,
  Typography,
  Box,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Search, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { deleteAnalysis } from '../../api';
import SnackBarWrapper from '../wrappers/snackbar-wrapper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${theme.breakpoints.down('sm')}`]: {
    fontSize: 14,
  },
}));

const RecentScansTable = ({ recentScans, setLoadTable }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [pendingDelete, setPendingDelete] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState('updated_at'); // Default sort by date
  const [sortOrder, setSortOrder] = useState('desc'); // Default descending
  const [success, setSuccess] = useState({ show: false, message: "" })
  const [error, setError] = useState({ show: false, message: "" })


  const statusOrder = {
    initial_complete: 0,
    initial_running: 1,
  };

  // Sort and filter the scans based on search term, sort column, and order
  const sortedAndFilteredScans = useMemo(() => {
    return recentScans
      .filter((scan) => scan.domain.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => {
        if (sortColumn === 'status') {
          return (statusOrder[a.status] - statusOrder[b.status]) * (sortOrder === 'asc' ? 1 : -1);
        } else if (sortColumn === 'domain') {
          return a.domain.localeCompare(b.domain) * (sortOrder === 'asc' ? 1 : -1);
        } else {
          // Sort by date (default)
          return (new Date(a.updated_at) - new Date(b.updated_at)) * (sortOrder === 'asc' ? 1 : -1);
        }
      });
  }, [recentScans, searchTerm, sortColumn, sortOrder]);

  const paginatedScans = useMemo(() => {
    return sortedAndFilteredScans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [sortedAndFilteredScans, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (column) => {
    if (sortColumn === column) {
      // Toggle sort order if the same column is clicked
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      // Set new column and reset to descending order
      setSortColumn(column);
      setSortOrder('desc');
    }
  };

  const getStatus = (status) => {
    let text = "";

    switch (status) {
      case 'initial_running':
        text = "Inital Analysis In Progress"
        break;
      case 'initial_complete':
        text = "Inital Analysis Completed"
        break;
      case 'further_running':
        text = "Domain Investigation In Progress"
        break;
      case 'further_complete':
        text = "Spoofing Detection Completed"
        break;
      default:
    }

    return text;
  };

  const getFormattedDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const getSingleDomainAnalysisHandler = (e, scan) => {
    e.preventDefault();
    navigate(`/dashboard/analyze-results/${scan?.uid}`, { state: { poll: false } });
  };

  const continueSingleDomainAnalysisHandler = (e, scan) => {
    e.preventDefault();
    navigate(`/dashboard/analyze-results/${scan?.uid}`, { state: { poll: true } });
  };

  const monitorDomainHandler = (e, scan) => {
    e.preventDefault();
    navigate(`/dashboard/setup-monitoring/${scan?.uid}`);
  };

  const continueSingleDomainInvestigationHandler = (e, scan) => {
    e.preventDefault();

    navigate(`/dashboard/domain-investigation/${scan?.uid}`, { state: { poll: true } });
  };

  const handleDeleteModal = (scan) => {
    setOpenDialog(true);
    setPendingDelete(scan.uid)
  }

  const handleAnalysisDelete = () => {
    deleteAnalysis(pendingDelete).then((response) => {
      setOpenDialog(false);
      setSuccess({ show: true, message: "Analysis deleted successfully" });
      setLoadTable(true)
    }).catch(() => {
      setError({ show: true, message: "Could not delete analysis" });
    })
  }

  const handleSnackbarClose = () => {
    setError({ ...error, show: false });
    setSuccess({ ...success, show: false });
  }

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box component="main" sx={{ flexGrow: 1, p: 0, overflow: 'auto' }}>
        <Box sx={{ mx: 'auto' }}>
          <Paper elevation={1}>
            <Box
              sx={{
                p: 2,
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6">Recent Scans</Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  size="small"
                  placeholder="Search domains..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: <Search sx={{ color: 'action.active', mr: 1 }} />,
                  }}
                />
              </Box>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <TableSortLabel
                        active={sortColumn === 'updated_at'}
                        direction={sortColumn === 'updated_at' ? sortOrder : 'asc'}
                        onClick={() => handleSortRequest('updated_at')}
                      >
                        Last Scanned
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TableSortLabel
                        active={sortColumn === 'domain'}
                        direction={sortColumn === 'domain' ? sortOrder : 'asc'}
                        onClick={() => handleSortRequest('domain')}
                      >
                        Domain
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TableSortLabel
                        active={sortColumn === 'status'}
                        direction={sortColumn === 'status' ? sortOrder : 'asc'}
                        onClick={() => handleSortRequest('status')}
                      >
                        Scan Status
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align="right">Quick Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedScans.map((scan) => (
                    <TableRow
                      key={scan.uid}
                      hover
                    >
                      <StyledTableCell>{getFormattedDate(scan.updated_at)}</StyledTableCell>
                      <StyledTableCell>{scan.domain}</StyledTableCell>
                      <StyledTableCell>
                        <Chip
                          label={getStatus(scan.status)}
                          sx={{
                            bgcolor: scan.status === 'initial_complete' || scan.status === 'further_complete' ? '#E3F2FD' : undefined,
                            color: scan.status === 'initial_complete' || scan.status === 'further_complete' ? '#1976D2' : undefined,
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                          {scan.status === 'initial_running' &&
                            <Tooltip title={"Initial Scanning in progress, click Continue for rurther analysis"}>
                              <span>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={(e) => continueSingleDomainAnalysisHandler(e, scan)}
                                >
                                  Continue
                                </Button>
                              </span>
                            </Tooltip>
                          }

                          {scan.status === 'further_running' &&
                            <Tooltip title={"Analyze results of the domain initial scan"}>
                              <span>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={(e) => continueSingleDomainInvestigationHandler(e, scan)}
                                >
                                  Continue Investigation
                                </Button>
                              </span>
                            </Tooltip>
                          }

                          {(scan.status === 'initial_complete' || scan.status === 'further_complete') &&
                            <>
                              <Tooltip title={"Analyze results of the domain initial scan"}>
                                <span>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={(e) => getSingleDomainAnalysisHandler(e, scan)}
                                  >

                                    Analyze Results
                                  </Button>
                                </span>
                              </Tooltip>
                              {/* <Tooltip title={"Analyze results of the domain initial scan"}>
                                <span>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={(e) => monitorDomainHandler(e, scan)}
                                  >
                                    Setup Monitoring
                                  </Button>
                                </span>
                              </Tooltip> */}
                            </>
                          }

                          {/* {scan.status === 'further_complete' &&
                            <Tooltip title={"Analyze results of the domain initial scan"}>
                              <span>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={(e) => monitorDomainHandler(e, scan)}
                                >
                                  Setup Monitoring
                                </Button>
                              </span>
                            </Tooltip>
                          } */}

                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();

                              handleDeleteModal(scan)
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={sortedAndFilteredScans.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
            />
          </Paper>
        </Box>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Delete Scan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this scan? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={() => handleAnalysisDelete()} color="error">
            Delete Scan
          </Button>
        </DialogActions>
      </Dialog>

      {error.show &&
        <SnackBarWrapper onSnackbarClose={handleSnackbarClose} status={"error"} message={error.message} />
      }

      {success.show &&
        <SnackBarWrapper onSnackbarClose={handleSnackbarClose} status={"success"} message={success.message} />
      }
    </Box>
  );
};

export default RecentScansTable;
