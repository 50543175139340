import React from "react";

const Button = ({ className, children, color, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`${className} before:ease flex items-center relative h-[36px] md:h-[40px] max-w-[300px] px-8 rounded-full overflow-hidden bg-${color} text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-${color} hover:before:-translate-x-40`}
    >
      {children}
    </button>
  );
};

export default Button;
