import React, { useState } from "react";
import { Box, Button, Typography, TextField, Paper, Snackbar, Alert } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Papa from "papaparse";
import { saveWordList } from "../../api";
import { InfoOutlined } from "@mui/icons-material";

const WordlistModal = ({ handleCloseModal }) => {
    const [wordlistName, setWordlistName] = useState("");
    const [parsedWords, setParsedWords] = useState([]);
    const [error, setError] = useState("");
    const [fileName, setFileName] = useState(null);
    const [requestError, setRequestError] = useState({ show: false, message: "" });

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type !== "text/csv") {
                setError("Please upload a valid CSV file.");
                return;
            }

            Papa.parse(file, {
                header: false,
                skipEmptyLines: true,
                complete: (results) => {
                    console.log(file)
                    const words = results?.data?.flat().filter(Boolean);
                    setParsedWords(words);
                    setFileName(file.name)
                },
                error: (error) => {
                    setError("Error reading file.");
                    console.error(error);
                },
            });
        }
    };

    const handleCloseSnackbar = () => {
        setRequestError({ ...error, show: false });
    };

    const handleSave = () => {
        if (!wordlistName) {
            setError("Both Wordlist Name and a CSV are required.");
            return;
        }

        if (parsedWords.length === 0) {
            setError("Please upload a CSV with valid words.");
            return;
        }

        const wordlist = {
            name: wordlistName,
            code: wordlistName.replace(/\s+/g, '_'),
            words: parsedWords,
        };

        saveWordList(wordlist).then((response) => {
            const data = response?.data;
            if (data?.existing_wordlist_code) {
                setRequestError({ show: true, message: data.details });
            } else {
                handleCloseModal(true);
            }


        }).catch((error) => {
            setRequestError({ show: true, message: "An error has occured while trying to save your wordlist" });
        });
    };

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" mb={2}>
                    Add New Wordlist
                </Typography>

                <TextField
                    label="Wordlist Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={wordlistName}
                    onChange={(e) => setWordlistName(e.target.value)}
                    sx={{ mb: 2 }}
                />

                <Paper
                    elevation={3}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: 2,
                        backgroundColor: "#e8f4fc",
                        borderLeft: "5px solid #2196f3",
                        borderRadius: 1,
                        margin: "auto",
                        mb: 3,
                    }}
                >
                    <InfoOutlined sx={{ color: "#2196f3", marginRight: 1 }} />
                    <Typography variant="body1" sx={{ color: "#333" }}>
                        Ensure your imported file is in UTF-8 encoding. Each row should list one word (e.g., word1, word2).
                        <a
                            href={`${process.env.PUBLIC_URL}/sample_wordlist.csv`} // Path to the file in the public folder
                            download="Sample_Wordlist.csv" // Optional: Rename the downloaded file
                            style={{ color: '#1976d2', textDecoration: 'underline', cursor: 'pointer', fontWeight: "bold" }}
                        >
                            Download Sample File
                        </a>
                    </Typography>
                </Paper>


                <Button
                    variant="outlined"
                    component="label"
                    fullWidth
                    startIcon={<UploadFileIcon />}
                    sx={{ mb: 2 }}
                >
                    Upload CSV
                    <input
                        type="file"
                        accept=".csv"
                        hidden
                        onChange={handleFileUpload}
                    />
                </Button>

                
                {fileName && (
                    <Typography variant="body2" color="success" sx={{ mb: 2 }}>
                        {fileName} uploaded
                    </Typography>
                )}

                {error && (
                    <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                        {error}
                    </Typography>
                )}

                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button
                        onClick={() => handleCloseModal(false)}
                        variant="contained"
                        color="grey"
                    >
                        Close
                    </Button>

                    <Button
                        onClick={handleSave}
                        variant="contained"
                        color="primary"
                    >
                        Save Wordlist
                    </Button>

                </Box>
            </Box>

            <Snackbar
                open={requestError.show}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {requestError.message}
                </Alert>
            </Snackbar>
        </>

    );
};

export default WordlistModal;
