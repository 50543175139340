import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  TablePagination,
  Box,
  CircularProgress,
  Typography,
  Button,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import { getFurtherDomainAnalysis, getSingleDomainAnalysis } from "../../api";
import MonitorSettings from "../monitor-settings";
import { useNavigate } from "react-router-dom";

const SetupMonitoringTable = ({ analysis_id, onSelectedRowsChange, onConditionChange, alertConditions, onBrandModalSubmit, setupMonitoringHandler, selectedDomains }) => {

  const navigate = useNavigate();

  const [analysisData, setAnalysisData] = useState([]);
  const [results, setResults] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState([]);
  const [error, setError] = useState({ show: false, message: "" });
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    if (analysis_id) {
      setLoading(true);

      // Fetch data from APIs
      Promise.all([
        getSingleDomainAnalysis(analysis_id),
        getFurtherDomainAnalysis(analysis_id),
      ])
        .then(([analysisResults, furtherResults]) => {
          const dnsLookups = analysisResults?.data?.dns_lookups_formatted || [];
          //const preselectedDomains = furtherResults?.data?.further_results_formatted || [];
          setResults(furtherResults?.data);
          //setAnalysisData(dnsLookups);
          //setSelectedRows(preselectedDomains); // Preselect rows
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error loading domain analysis data:", err);
          setLoading(false);
        });
    }
  }, [analysis_id]);

  useEffect(() => {
    if (selectedDomains) {
      setDomains(selectedDomains)
      setAnalysisData(selectedDomains)
      setLoading(false)
    }
  }, [selectedDomains])

  // Notify parent component when selected rows change
  useEffect(() => {
    onSelectedRowsChange(selectedRows);
    setButtonsDisabled(selectedRows.length === 0)
  }, [selectedRows, onSelectedRowsChange]);

  const handleSelectRow = (item) => {
    const isSelected = selectedRows.some(
      (selected) => selected.domain === item.domain
    );
    const newSelectedRows = isSelected
      ? selectedRows.filter((selected) => selected.domain !== item.domain)
      : [...selectedRows, item];

    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = (e) => {
    const newSelectedRows = e.target.checked ? [...analysisData] : [];
    setSelectedRows(newSelectedRows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTakedownClick = () => {
    if (selectedRows?.length === 0) {
      setError({ show: true, message: "Please select at least one domain to proceed" });
      return;
    }

    navigate(`/dashboard/takedown-domains/${analysis_id}`, { state: { selectedDomains: selectedDomains } })
  }

  const handleCloseSnackbar = () => {
    setError({ ...error, show: false });
  };


  const currentRows = analysisData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      {alertConditions?.length &&
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <MonitorSettings furtherResults={results} alertConditions={alertConditions} onConditionChange={onConditionChange} onBrandModalSubmit={onBrandModalSubmit} />
        </Paper>
      }

      <Paper elevation={3} sx={{ p: 3 }}>
        <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h6" component="h2">
            Domains to monitor
          </Typography>


          <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" pt={2} mb={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <Tooltip title={buttonsDisabled ? "Select at least one domain to Enable Monitoring." : ""} arrow>
                <span>
                  <Button
                    disabled={buttonsDisabled}
                    variant="contained"
                    color="primary"
                    onClick={() => setupMonitoringHandler()}
                  >
                    Enable Monitoring
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title={buttonsDisabled ? "Select at least one domain to initiate a Takedown." : ""} arrow>
                <span>
                  <Button
                    disabled={buttonsDisabled}
                    variant="contained"
                    color="primary"
                    onClick={() => handleTakedownClick()}
                  >
                    Takedown Domain
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        <Paper>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
              <CircularProgress />
            </Box>
          ) : (
            domains && (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedRows.length === analysisData.length &&
                              analysisData.length > 0
                            }
                            onChange={handleSelectAll}
                            indeterminate={
                              selectedRows.length > 0 &&
                              selectedRows.length < analysisData.length
                            }
                          />
                        </TableCell>
                        <TableCell>Domain</TableCell>
                        <TableCell>A</TableCell>
                        <TableCell>MX</TableCell>
                        <TableCell>HTTP Open</TableCell>
                        <TableCell>HTTPS Open</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentRows.map((lookup) => (
                        <TableRow
                          key={lookup.domain}
                          hover
                          selected={selectedRows.some(
                            (selected) => selected.domain === lookup.domain
                          )}
                          onClick={() => handleSelectRow(lookup)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedRows.some(
                                (selected) => selected.domain === lookup.domain
                              )}
                              onChange={(e) => {
                                e.stopPropagation(); // Prevent row click when selecting/deselecting checkbox
                                handleSelectRow(lookup);
                              }}
                            />
                          </TableCell>
                          <TableCell>{lookup.domain}</TableCell>
                          <TableCell>{lookup.a_record}</TableCell>
                          <TableCell>{lookup.mx_record}</TableCell>
                          <TableCell>
                            {lookup.http_open ? "Yes" : "No"}
                          </TableCell>
                          <TableCell>
                            {lookup.https_open ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={analysisData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[50, 100]}
                />
              </>
            )
          )}
        </Paper>
      </Paper>

      <Snackbar
        open={error.show}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error.message}
        </Alert>
      </Snackbar>

    </>
  );
};

export default SetupMonitoringTable;
