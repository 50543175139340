import React, { useEffect, useMemo, useState } from "react";
import {
    Box,
    CircularProgress,
    Container,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    TableSortLabel,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TablePagination,
} from "@mui/material";
import { deleteMonitor, getAllMonitors } from "../../api";
import CollapsibleRow from "../../components/collapsible-row";
import SnackBarWrapper from "../../components/wrappers/snackbar-wrapper";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DashboardLayout from "../../components/Dashboard/layout";
import TopLayout from "../../components/Dashboard/toplayout";

const Monitoring = () => {
    const location = useLocation();
    const monitorId = location?.state?.monitor_id;
    const monitorDomain = location?.state?.domain;

    const [loading, setLoading] = useState(false);
    const [monitors, setMonitors] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [orderBy, setOrderBy] = useState("created_at");
    const [order, setOrder] = useState("desc");
    const [filters, setFilters] = useState({
        domain: monitorDomain ? monitorDomain : "",
        enabled: "",
        takedownStatus: "",
    });
    const [success, setSuccess] = useState({ show: false, message: "" });
    const [error, setError] = useState({ show: false, message: "" });
    const [openDialog, setOpenDialog] = useState(false);
    const [pendingDelete, setPendingDelete] = useState(null);

    const conditionsList = useSelector((state) => state.scan.config.alert_conditions);



    useEffect(() => {
        setLoading(true);
        getAllMonitors()
            .then((response) => {
                setMonitors(response?.data || []);
                setLoading(false);
            })
            .catch((err) => {
                setError({ show: true, message: "Failed to load monitors" });
                setLoading(false);
            });
    }, []);

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: value,
        }));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const filteredMonitors = useMemo(() => {
        return monitors.filter((monitor) => {
            const matchesDomain = monitor.domain.toLowerCase().includes(filters.domain.toLowerCase());
            const matchesEnabled =
                filters.enabled === ""
                    ? true
                    : filters.enabled === "true"
                        ? monitor.enabled
                        : !monitor.enabled;
            const matchesTakedownStatus =
                filters.takedownStatus === "" || monitor.takedown_status === filters.takedownStatus;

            return matchesDomain && matchesEnabled && matchesTakedownStatus;
        });
    }, [monitors, filters]);

    const sortedMonitors = useMemo(() => {
        const comparator = (a, b) => {
            if (b[orderBy] < a[orderBy]) return -1;
            if (b[orderBy] > a[orderBy]) return 1;
            return 0;
        };

        return filteredMonitors.sort((a, b) =>
            order === "desc" ? comparator(a, b) : -comparator(a, b)
        );
    }, [filteredMonitors, order, orderBy]);

    const handleDeleteModal = (monitor) => {
        setOpenDialog(true);
        setPendingDelete({
            monitor_id: monitor.uid,
            analysis_id: monitor.analysis_id,
        });
    };

    const handleMonitorDelete = () => {
        const data = { monitor_ids: [pendingDelete.monitor_id] };
        deleteMonitor(pendingDelete.analysis_id, data)
            .then(() => {
                setOpenDialog(false);
                setSuccess({ show: true, message: "Monitor deleted successfully" });
                setMonitors((prev) => prev.filter((m) => m.uid !== pendingDelete.monitor_id));
            })
            .catch(() => {
                setError({ show: true, message: "Could not delete monitor" });
            });
    };

    return (
        <DashboardLayout>
            <Container maxWidth="100%">
                <Box py={3} className="hidden md:block">
                    <TopLayout title="Monitors" />
                </Box>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : (
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <TableContainer sx={{ maxHeight: "calc(100vh - 300px)" }}>
                            <Table stickyHeader>
                                <TableHead>
                                <TableRow>
                                        <TableCell />
                                        <TableCell>
                                           Domain
                                        </TableCell>
                                        <TableCell sortDirection={orderBy === "created_at" ? order : false}>
                                            <TableSortLabel
                                                active={orderBy === "created_at"}
                                                direction={orderBy === "created_at" ? order : "asc"}
                                                onClick={() => handleRequestSort("created_at")}
                                            >
                                                Created
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            Enabled
                                        </TableCell>
                                        <TableCell sortDirection={orderBy === "alerts" ? order : false}>
                                            <TableSortLabel
                                                active={orderBy === "alerts"}
                                                direction={orderBy === "alerts" ? order : "asc"}
                                                onClick={() => handleRequestSort("alerts")}
                                            >
                                                Alerts
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            Takedown Status
                                        </TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>
                                            <TextField
                                                variant="standard"
                                                placeholder="Filter by Domain"
                                                value={filters.domain}
                                                onChange={(e) => handleFilterChange("domain", e.target.value)}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                variant="standard"
                                                value={filters.enabled}
                                                onChange={(e) => handleFilterChange("enabled", e.target.value)}
                                                displayEmpty
                                                fullWidth
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="true">True</MenuItem>
                                                <MenuItem value="false">False</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                           
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                variant="standard"
                                                value={filters.takedownStatus}
                                                onChange={(e) => handleFilterChange("takedownStatus", e.target.value)}
                                                displayEmpty
                                                fullWidth
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="Draft">Draft</MenuItem>
                                                <MenuItem value="Pending">Pending</MenuItem>
                                                <MenuItem value="Completed">Completed</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedMonitors
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((monitor) => (
                                            <CollapsibleRow
                                                key={monitor.uid}
                                                monitorId={monitorId}
                                                monitor={monitor}
                                                onMonitorDelete={handleDeleteModal}
                                                conditionsList={conditionsList}
                                                setSuccess={setSuccess}
                                                setError={setError}
                                            />
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[50, 100]}
                            component="div"
                            count={sortedMonitors.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                )}

                {error.show && (
                    <SnackBarWrapper
                        onSnackbarClose={() => setError({ show: false })}
                        status={"error"}
                        message={error.message}
                    />
                )}
                {success.show && (
                    <SnackBarWrapper
                        onSnackbarClose={() => setSuccess({ show: false })}
                        status={"success"}
                        message={success.message}
                    />
                )}

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>Delete Monitor</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this monitor? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                        <Button onClick={handleMonitorDelete} color="error">
                            Delete Monitor
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </DashboardLayout>
    );
};

export default Monitoring;