import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../components/Dashboard/layout";
import { Box, CircularProgress, Container, Paper, Typography, Snackbar, Alert, Button, Link } from "@mui/material";
import TopLayout from "../../../components/Dashboard/toplayout";
import BreadcrumbSteps from "../../../components/steps-breadcrumbs";
import { useCallback, useEffect, useState } from "react";
import { getDomainMonitor, getFurtherDomainAnalysis, getModuleConfig, getSingleDomainAnalysis, startSetupMonitor } from "../../../api";
import SetupMonitoringTable from "../../../components/setup-monitoring-table";
import MonitorSettings from "../../../components/monitor-settings";
import { InfoOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";

const SetupMonitoring = () => {
    const { analysis_id } = useParams();
    const location = useLocation();
    const preselectedDomains = location?.state?.selectedDomains;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [error, setError] = useState({ show: false, message: "" });

    const [modalBrand, setModalBrand] = useState("");
    const [modalFile, setModalFile] = useState(null);


    const [selectedDomains, setSelectedDomains] = useState([]);
    const [selectedAlertConditons, setSelectedAlertCondition] = useState([]);

    const alertConditions = useSelector(state => state.scan.config.alert_conditions);

    const handleSelectedRowsChange = useCallback((selectedRows) => {
        setSelectedDomains(selectedRows);
    }, []);

    const handleCloseSnackbar = () => {
        setError({ ...error, show: false });
    };

    const handleConditionChange = useCallback((alertConditions) => {
        console.log(alertConditions)
        setSelectedAlertCondition(alertConditions)
    }, []);

    const handleBrandModalSubmit = useCallback((modalData) => {
        setModalBrand(modalData?.brand_name)
        setModalFile(modalData?.logo)
    }, [])

    const setupMonitoringHandler = async (e) => {
        if (!selectedDomains || selectedDomains.length === 0) {
            setError({ show: true, message: "Please select at least one domain to proceed" });
            return false;
        }

        if (!selectedAlertConditons || selectedAlertConditons.length === 0) {
            setError({ show: true, message: "Please choose some alert conditions to proceed" });
            return false;
        }

        const formData = new FormData();

        if (modalBrand) {
            const brandArray = [modalBrand];

            brandArray.forEach((brand) => {
                formData.append("brand", brand);
            });
        }

        if (modalFile) {
            formData.append("logo", modalFile);
        }

        formData.append("auto_track_new_domains", true);
        formData.append("enabled", true);

        selectedDomains.forEach((domain) => {
            formData.append("domains", domain.domain);
        });

        selectedAlertConditons.forEach((condition) => {
            formData.append("conditions", condition);
        });

        startSetupMonitor(analysis_id, formData).then((response) => {
            navigate(`/dashboard/takedown-domains/${analysis_id}`, { state: { selectedDomains: selectedDomains } })
        }).catch((error) => {
            if (error?.response?.status === 400 && error?.response?.data?.details === "The following domains are already being monitored.") {
                setError({ show: true, message: error?.response?.data?.details });
            } else {
                setError({ show: true, message: "An error has occured" });
            }
        })
    }

    return (
        <DashboardLayout>
            <Container maxWidth="100%">
                <Box py={3} className="hidden md:block">
                    <TopLayout title="Analyze Results" />
                </Box>

                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    <BreadcrumbSteps uid={analysis_id} />
                </Paper>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : (
                    !loading && (
                        <>

                            {showFeedback ?
                                <Alert severity="warning" sx={{ m: 0, display: "flex", alignItems: "center" }}>
                                    The following domains are already being monitored

                                    <Button sx={{
                                        ml: 1,
                                        borderColor: "warning.main",
                                        color: "warning.main",
                                        "&:hover": {
                                            borderColor: "warning.dark",
                                            backgroundColor: "warning.light",
                                        }
                                    }}
                                        variant="outlined" href="#outlined-buttons">
                                        Go to monitor settings
                                    </Button>
                                </Alert>

                                : <Paper
                                    elevation={3}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: 2,
                                        backgroundColor: "#e8f4fc",
                                        borderLeft: "5px solid #2196f3",
                                        borderRadius: 1,
                                        margin: "auto",
                                        mb: 3,
                                    }}
                                >
                                    <InfoOutlined sx={{ color: "#2196f3", marginRight: 1 }} />
                                    <Typography variant="body1" sx={{ color: "#333" }}>
                                        Choose domains to be monitored and configure your domain monitoring conditions
                                    </Typography>
                                </Paper>
                            }


                            <SetupMonitoringTable
                                analysis_id={analysis_id}
                                onSelectedRowsChange={handleSelectedRowsChange}
                                onConditionChange={handleConditionChange}
                                alertConditions={alertConditions}
                                onBrandModalSubmit={handleBrandModalSubmit}
                                setupMonitoringHandler={setupMonitoringHandler}
                                selectedDomains={preselectedDomains}
                            />
                        </>
                    )
                )}
            </Container>

            <Snackbar
                open={error.show}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {error.message}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default SetupMonitoring;
