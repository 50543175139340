import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    TextField,
    Tooltip,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import { saveFileToLocalStorage } from '../../utiles/general';
import { useDispatch } from 'react-redux';
import { updateBrand, updateBrandLogo } from '../../slices/scan-slice';
import { startFurtherDomainAnalysis } from '../../api';
import { useParams } from 'react-router-dom';

export default function DetectSpoofingModal({ open, onClose, onSubmit, selectedDomains }) {
    const dispatch = useDispatch();
    const { analysis_id } = useParams();


    const [brandInfo, setBrandInfo] = useState({
        brandName: '',
        logo: null
    });

    const handleBrandNameChange = (event) => {
        setBrandInfo(prev => ({
            ...prev,
            brandName: event.target.value
        }));
    };

    const handleLogoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setBrandInfo(prev => ({
                ...prev,
                logo: file
            }));
        }
    };

    const handleDetectSpoofing = async () => {
        console.log(1)

        if (brandInfo.logo) {
            try {
                const fileKey = await saveFileToLocalStorage(brandInfo.logo);
                dispatch(updateBrandLogo(fileKey));
            } catch (error) {
                console.error("Error uploading file:", error);
            }

            dispatch(updateBrand(brandInfo.brandName))
        }

        onSubmit()
        onClose()
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="detect-spoofing-modal-title"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: 24,
                p: 4,
            }}>
                <Typography
                    id="detect-spoofing-modal-title"
                    variant="h5"
                    component="h2"
                    sx={{
                        mb: 3,
                        fontWeight: 'normal'
                    }}
                >
                    Detect Spoofing
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'normal',
                            mr: 1
                        }}
                    >
                        Add Branding or Logo
                    </Typography>
                    <Tooltip title="Upload your brand name and logo to detect spoofing attempts">
                        <InfoIcon fontSize="small" sx={{ color: '#1976d2' }} />
                    </Tooltip>
                </Box>

                <TextField
                    fullWidth
                    label="Brand Name"
                    variant="outlined"
                    value={brandInfo.brandName}
                    onChange={handleBrandNameChange}
                    sx={{
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#1976d2',
                            },
                            '&:hover fieldset': {
                                borderColor: '#1976d2',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#1976d2',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            color: '#1976d2',
                            fontSize: '0.875rem',
                        },
                        '& .MuiOutlinedInput-input': {
                            fontSize: '0.875rem',
                        },
                    }}
                />

                <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                        mb: 4,  // Changed from mb: 3
                        color: '#1976d2',
                        borderColor: '#1976d2',
                        '&:hover': {
                            borderColor: '#1565c0',
                            bgcolor: 'rgba(25, 118, 210, 0.04)',
                        },
                        fontSize: '0.875rem',
                        py: 0.75,
                    }}
                >
                    UPLOAD LOGO
                    <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleLogoUpload}
                    />
                </Button>

                {brandInfo.logo && (
                    <Typography variant="body2" sx={{ mb: 3, color: 'text.secondary' }}>
                        Selected file: {brandInfo.logo.name}
                    </Typography>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button
                        onClick={onClose}
                        sx={{
                            color: '#1976d2',
                            fontSize: '0.875rem'
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleDetectSpoofing()}
                        sx={{
                            bgcolor: '#FFC985',
                            color: 'black',
                            '&:hover': { bgcolor: '#E6B578' },
                            fontSize: '0.875rem',
                            px: 2,
                            py: 0.75,
                        }}
                    >
                        DETECT SPOOFING
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

