export const reportData = [
  {
    id: 0,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "pdf",
    numberofrecords: "23422",
  },
  {
    id: 1,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "csv",
    numberofrecords: "23422",
  },
  {
    id: 2,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "csv",
    numberofrecords: "23422",
  },
  {
    id: 3,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "pdf",
    numberofrecords: "23422",
  },
  {
    id: 4,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "csv",
    numberofrecords: "23422",
  },
  {
    id: 5,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "pdf",
    numberofrecords: "23422",
  },
  {
    id: 6,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "csv",
    numberofrecords: "23422",
  },
  {
    id: 7,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "csv",
    numberofrecords: "23422",
  },
  {
    id: 8,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "pdf",
    numberofrecords: "23422",
  },
  {
    id: 9,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "csv",
    numberofrecords: "23422",
  },
  {
    id: 10,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "pdf",
    numberofrecords: "23422",
  },
  {
    id: 11,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "csv",
    numberofrecords: "23422",
  },
  {
    id: 12,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "csv",
    numberofrecords: "23422",
  },
  {
    id: 13,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "pdf",
    numberofrecords: "23422",
  },
  {
    id: 14,
    domain: "example.com",
    date: "Jan 24, 2024",
    type: "csv",
    numberofrecords: "23422",
  },
];
