import React, { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    Button,
    Container,
    Grid,
    Card,
    CardContent,
    Stack,
    Paper,
    Grid2,
    LinearProgress
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { getExportAnalysisCsv, getExportVariationsCsv, stopAnalysis } from '../../api'
import { useParams } from 'react-router-dom'
import SnackBarWrapper from '../wrappers/snackbar-wrapper'
import { Download } from '@mui/icons-material'

const AnalyzeScanStats = ({onStopScan, isScanning, data, completionPercentage, updated_at, domain, isSpoofing = false }) => {
    const { analysis_id } = useParams();

    const [stats, setStats] = useState([]);
    const [error, setError] = useState({ show: false, message: "" });
    const [success, setSuccess] = useState({ show: false, message: "" });

    useEffect(() => {
        if (data) {
            if (!isSpoofing) {
                const detectedDomainsCount = data.length || 0;
                const mxCount = data.filter((domain) => domain.mx_record).length;
                const httpsCount = data.filter((domain) => domain.https_open).length;
                const httpCount = data.filter((domain) => domain.http_open).length;

                setStats([
                    { label: 'Detected Domains', value: detectedDomainsCount },
                    { label: 'MX', value: mxCount },
                    { label: 'HTTPS', value: httpsCount },
                    { label: 'HTTP', value: httpCount },
                ]);
            } else {
                const detectedDomainsCount = data.length || 0;
                const webSpoofing = data.filter((domain) => domain.spoofing).length;
                const logoSpoofing = data.filter((domain) => domain.logo_spoofing).length;

                setStats([
                    { label: 'Detected Domains', value: detectedDomainsCount },
                    { label: 'Website Spoofing', value: webSpoofing },
                    { label: 'Brand Spoofing', value: logoSpoofing },
                ]);
            }
        }
    }, [data, isSpoofing]);

    const handleExportDomainVariations = async () => {
        try {
            const response = await getExportVariationsCsv(analysis_id);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'domain_variations.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            setError({ show: true, message: "Error exporting domain variations" });
            console.error("Error exporting domain variations:", error);
        }
    };

    const handleExportDomainAnalysis = async () => {
        try {
            const response = await getExportAnalysisCsv(analysis_id);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'domain_analysis.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            setError({ show: true, message: "Error exporting domain analysis" });
            console.error("Error exporting domain analysis:", error);
        }
    };

    const handleSnackbarClose = () => {
        setError({ ...error, show: false });
        setSuccess({ ...success, show: false });
    }




    return (
        <>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>

                <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" pt={2} mb={2}>
                    <Box>
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'text.secondary',
                                mb: 0.5
                            }}
                        >
                            {updated_at}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: '1.5rem', sm: '2rem' }
                            }}
                        >
                            {domain}
                        </Typography>
                    </Box>
                    {!isSpoofing &&
                        <Box display="flex" gap={2}>
                            <Button variant="outlined" onClick={handleExportDomainVariations} startIcon={<Download />}>
                                Export Domain Variations
                            </Button>
                            <Button variant="outlined" onClick={handleExportDomainAnalysis} startIcon={<Download />}>
                                Export Domain Analysis
                            </Button>
                        </Box>
                    }
                </Box>

                <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                    {stats.map((stat, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: "25%", // Ensures each stat takes 25% width
                                display: "flex",
                                alignItems: "flex-start",
                                gap: 2,
                                paddingTop: 2,
                                marginBottom: 2,
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "text.secondary",
                                        whiteSpace: "nowrap",
                                        textAlign: "left", // Ensures alignment to the left
                                    }}
                                >
                                    {stat.label}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: { xs: "1.5rem", sm: "2rem" },
                                        textAlign: "left", // Ensures alignment to the left
                                    }}
                                >
                                    {stat.value}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    {isScanning && completionPercentage < 100 && (
                        <>
                            <LinearProgress sx={{ width: "90%", height: 6, borderRadius: 1 }} />

                            <Typography variant="body2" color="text.secondary">
                                {completionPercentage}% Completed
                            </Typography>

                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => onStopScan()}
                            >
                                Stop Scan
                            </Button>
                        </>
                    )}



                </Box>


            </Paper>

            {
                error.show &&
                <SnackBarWrapper onSnackbarClose={handleSnackbarClose} status={"error"} message={error.message} />
            }

            {
                success.show &&
                <SnackBarWrapper onSnackbarClose={handleSnackbarClose} status={"success"} message={success.message} />
            }
        </>

    )
}

export default AnalyzeScanStats;
