import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    IconButton,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getAnalysis, getFurtherDomainAnalysis, getSingleDomainAnalysis } from '../../api';

const InfoRow = ({ label, value, showIcon }) => {
    const [openDialog, setOpenDialog] = useState(false)
    const [screenshot, setScreenshot] = useState(null)
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={1}
                borderBottom="1px solid rgba(0, 0, 0, 0.12)"
            >
                <Typography variant="body2">{label}</Typography>
                <Box display="flex" alignItems="center">
                    {!showIcon &&
                        <Typography variant="body2" color="text.secondary">
                            {value && typeof value === 'boolean' ? (value ? 'True' : 'False') : value || 'N/A'}
                        </Typography>
                    }

                    {showIcon && value && (
                        <IconButton size="small" onClick={() => { setOpenDialog(true); setScreenshot(value) }}>
                            <VisibilityIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
            </Box>

            <Dialog open={openDialog} onClose={() => { setOpenDialog(true); setScreenshot(null) }}>
                <DialogTitle>Screenshot</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {value ? (
                            <img
                                src={value}
                                alt="Domain Screenshot"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    borderRadius: 4,
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                }}
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite error loop
                                    e.target.style.display = "none"; // Hide the broken image
                                    e.target.parentNode.innerHTML = `
                                      <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; border: 1px dashed #ccc; border-radius: 4px; font-weight: bold; color: #999;">
                                       No screenshot available
                                      </div>
                                    `;
                                }}
                            />
                        ) : (
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px dashed #ccc",
                                    borderRadius: 4,
                                    fontWeight: "bold",
                                    color: "#999",
                                }}
                            >
                                No screenshot available
                            </Box>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

const InfoSection = ({ section }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>{section.title}</Typography>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                {section?.fields?.map((field) => (
                    <InfoRow
                        key={field?.key}
                        label={field?.label}
                        value={field?.value}
                        showIcon={field?.key === 'screenshot'}
                    />
                ))}
            </Box>
        </Box>
    )
};

const DomainInfoCard = ({ analysis_id, domainInfo }) => {


    const [sections, setSections] = useState(null);

    useEffect(() => {
        if (analysis_id) {
            getSingleDomainAnalysis(analysis_id).then((initialScanResponse) => {
                const initialScanData = initialScanResponse?.data;
                const filteredInitialData = initialScanData.dns_lookups_formatted?.filter(x => x.domain === domainInfo.domain)[0];

                getFurtherDomainAnalysis(analysis_id).then((furtherScanResponse) => {
                    const furtherScanData = furtherScanResponse?.data;
                    const filteredFurtherData = furtherScanData.further_results_formatted?.filter(x => x.domain === domainInfo.domain)[0];

                    const sections = [
                        {
                            title: 'DNS Info',
                            fields: [
                                { key: 'domain', label: "Domain", value: filteredInitialData?.domain },
                                { key: 'a_record', label: "A Record", value: filteredInitialData?.a_record },
                                { key: 'mx_record', label: 'MX Record', value: filteredInitialData?.mx_record },
                                { key: 'ns_record', label: 'NS Record', value: filteredInitialData?.ns_record },
                                { key: 'https_open', label: 'HTTPS Open', value: filteredInitialData?.http_open },
                                { key: 'http_open', label: 'HTTP Open', value: filteredInitialData?.https_open },
                            ]
                        },
                        {
                            title: 'Content',
                            fields: [
                                { key: 'screenshot', label: 'Screenshot', value: filteredInitialData?.screenshot },
                                { key: 'content_spoofed', label: 'Content Spoofed', value: filteredInitialData?.content_spoofed },
                            ]
                        },
                        {
                            title: 'Spoofing Checks',
                            fields: [
                                { key: 'url_score', label: 'URL Score', value: filteredFurtherData?.urlscore },
                                { key: 'is_spoofing', label: 'Is Spoofing', value: filteredFurtherData?.spoofing },
                                { key: 'phishing_status', label: 'Phishing Status', value: Object.keys(filteredFurtherData?.phishing || {}).length === 0 ? "N/A" : filteredFurtherData?.phishing },
                            ]
                        },
                        {
                            title: 'Whois Info',
                            fields: [
                                { key: 'creation_date', label: 'Domain Creation Date', value: filteredInitialData?.whois?.creation_date },
                                { key: 'expiration_date', label: 'Domain Expiration Date', value: filteredInitialData?.whois?.expiration_date },
                                { key: 'abuse_email', label: 'Abuse Email', value: filteredInitialData?.whois?.emails },
                                { key: 'registrar', label: 'Registrar', value: filteredInitialData?.whois?.registrar },
                                { key: 'address', label: 'Address', value: filteredInitialData?.whois?.address },
                                { key: 'registrant', label: 'Registrant', value: filteredInitialData?.whois?.registrant }
                            ]
                        }
                    ];

                    setSections(sections)

                })

            })
        }
    }, [analysis_id, domainInfo])

    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={2}>
                        {sections?.map((section, index) => (
                            <Grid item xs={12} key={index}>
                                <InfoSection section={section} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
};

export default DomainInfoCard;

