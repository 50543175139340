import React from "react";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <div>
      <div className="min-h-[100vh] flex flex-col justify-between">
        <div>
          <Header />
        </div>
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
