import React from "react";
import technology_hero from "../assets/images/technology_hero.png";
import howwedoit from "../assets/images/howwedoit.png";
import shape from "../assets/images/shape.png";
import arrowcircle from "../assets/images/icons/arrow_circle.png";

const Step = ({ number, title, description }) => (
  <div className="flex gap-4">
    <div className="bg-primary rounded-full py-2 min-w-[70px] h-fit">
      <p className="text-sm text-white text-center">{number}</p>
    </div>
    <div>
      <div className="font-semibold text-xl lg:text-2xl pb-2">{title}</div>
      <p className="text-grey text-sm lg:text-base">{description}</p>
    </div>
  </div>
);

const Technology = () => {
  return (
    <section className="py-[3rem] lg:py-[5rem]">
      <div className="max-w-xl lg:max-w-6xl mx-auto px-4">
        <h1 className="text-4xl font-bold">Technology</h1>    
      </div>

      <div className="technology_shape2">
        <div className="max-w-xl lg:max-w-6xl mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 lg:gap-2 py-[3rem] lg:py-[5rem] technology_shape1">
            <div className="lg:col-span-6">
              <div className="bg-primary rounded-full py-2 max-w-[150px]">
                <p className="text-sm text-white text-center">What we do</p>
              </div>
              <h2 className="text-2xl lg:text-4xl font-bold py-[1.5rem]">
                What is Cyber-Risk?
              </h2>
              <p className="text-sm lg:text-base text-grey">
                Typosquatting is when attackers create look-alike web addresses with slight spelling errors (think: examp1e.com instead of example.com). Why? To trick users into visiting these fake sites, steal sensitive data, or spread malware
              </p>
              <h3 className="text-2xl lg:text-4xl font-bold py-[1.5rem]">
                Common Techniques:
              </h3>
              <div className="pt-[1.5rem] flex flex-col gap-4">
                {[
                  "Character Omission -> Leaving out a letter (e.g., gogle.com)",
                  "Character Swap -> Flipping two letters (e.g., googel.com).",
                  "Homoglyphs -> Using similar-looking characters (e.g., g0ogle.com)."
                ].map((text, index) => (
                  <div className="flex items-center gap-2" key={index}>
                    <img src={arrowcircle} alt="Arrow icon" />
                    <p>{text}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="hidden lg:flex items-end">
              <img src={shape} alt="Decorative shape" />
            </div>
            <div className="lg:col-span-5">
              <img src={technology_hero} alt="Technology hero" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="max-w-xl lg:max-w-6xl mx-auto py-[3rem] lg:py-[5rem] px-4">
          <div className="bg-primary rounded-full py-2 max-w-[150px]">
            <p className="text-sm text-white text-center">Work Process</p>
          </div>
          <h2 className="text-2xl lg:text-4xl font-bold py-[1.5rem]">
            Why SpoofGuard?
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 lg:gap-2">
            <div className="lg:col-span-5">
              <p className="text-sm lg:text-base pb-4 lg:pb-12">
                Because the others don’t cut it. We built a smarter detection engine to reduce false positives and keep the focus on what really matters. Connect any data source, tweak any module, and create custom rules that fit your environment—no more one-size-fits-all. Our algorithms aren’t generic—they’re constructed from real phishing patterns. We spot what others miss. SpoofGuard pulls in live feeds like certificate logs and domain registrations to spot risks as they appear, not after. We allow automated risk scoring through our own app URLScore.ai. This means we catch dodgy domains that misuse your brand or keywords before you even notice.
              </p>
              <p className="text-sm lg:text-base pb-4 lg:pb-12">

                Why Not Just Download and Analyze Newly Registered Domains?
                Because it’s not that simple. There’s no global, real-time database for newly registered domains across all TLDs (Top-Level Domains). While a few countries offer access to registration data—like Switzerland, the Netherlands, Denmark, and Canada—it’s limited and often comes with privacy restrictions. Even commercial vendors can’t deliver real-time detection, leaving gaps in coverage and missing critical threats. Without a unified feed of newly registered domains, traditional methods fall short of providing proactive, real-time protection. That’s where SpoofGuard comes in—bridging these gaps by combining available data sources, integrating live feeds, and leveraging intelligent algorithms to provide a comprehensive, up-to-date picture of domain activity.

              </p>
              <img src={howwedoit} alt="How we do it" />
            </div>
            <div className="hidden lg:flex items-end"></div>
            <div className="lg:col-span-6 flex flex-col gap-4">
              <Step
                number="01"
                title="Step 1"
                description="Simply enter your primary domain—no subdomains or “http” needed. By default, SpoofGuard activates all modules except “Custom Keywords.”"
              />
              <Step
                number="02"
                title="Step 2"
                description=" Once the scan is complete, SpoofGuard will show you all detected typo-squatted variations with an NS-record, plus additional data like A-records and MX-records. If we missed any domains you’re concerned about, simply upload them for further analysis."
              />
              <Step
                number="03"
                title="Step 3"
                description="Brand Awareness. Before diving into a deeper analysis, give SpoofGuard a bit more context about your brand. This helps identify where your brand name appears—whether as text or an image—on any of the scanned websites."
              />
               <Step
                number="04"
                title="Step 4"
                description="Set Up Monitoring. Found a suspicious domain? Activate monitoring. Get alerted whenever a new domain that matches your brand or custom keywords is registered. Keep track of any modifications to a monitored domain. You can specify the types of changes to watch for, ensuring you’re always a step ahead."
              />
                <Step
                number="05"
                title="Step 5"
                description="Take Down. Request a domain take down by submitting a report to the hosting and DNS provider or add the site to Blacklists."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Technology;
