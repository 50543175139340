import React, { useState } from "react";
import ShadowBlog from "./shadowBlog";
import tick_circle from "../../assets/images/icons/tick_circle.png";
import close_circle from "../../assets/images/icons/close_circle.png";
import axios from "axios";
import { useStripeCheckout } from "../../hooks/useCheckout";
import Modal from "react-responsive-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PlanCard = ({ item, userHasPlan, onSelect, subscriptionData }) => {
  const isCurrentPlan = item.name === subscriptionData?.plan_type;
  const { handleSubmit } = useStripeCheckout();

  const account = useSelector((state) => state.account);
  const navigate = useNavigate();

  const [isCancelModalOpen, setCancelModalOpen] = useState(false);

  const handlePlanSelection = () => {
    if (!account.isAuthenticated) {
      navigate("/login");
    } else {
      onSelect();
    }
  };

  const calculateDaysRemaining = (endDate) => {
    const end = new Date(endDate);
    const today = new Date();

    end.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const timeDiff = end - today;
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  };
  const daysRemaining =
    subscriptionData && subscriptionData.is_active
      ? calculateDaysRemaining(subscriptionData.end_date)
      : 0;

  const handleCancelSubscription = async (uid) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASIC_API_URL}/memberships/${uid}/cancel/`
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="relative">
      <ShadowBlog>
        <div className="flex flex-col gap-4">
          <img src={item.icon} className="w-[50px]" alt="" />
          <p className="text-xl font-semibold">{item.name}</p>
          <p className="text-sm">{item.description}</p>
          <div className="w-[150px] border-t-2 border-black"></div>

          <div className="flex justify-between items-center">
            <span>
              <span className="text-4xl font-bold">${item.price}</span>
              /Month
            </span>
            {isCurrentPlan && (
              <p className="text-red-400">{daysRemaining} days remaining</p>
            )}
          </div>

          <div className="flex justify-center">
            <button
              type="button"
              disabled={isCurrentPlan}
              onClick={handlePlanSelection}
              className={`relative h-[36px] md:h-[40px] w-full px-8 rounded-full ${
                isCurrentPlan ? "bg-slate-500" : "bg-primary"
              } text-white`}
            >
              <span className="relative text-sm lg:text-base z-10">
                Select Plan
              </span>
            </button>
          </div>

          <div className="flex flex-col gap-4">
            {item.features &&
              item.features.map((subitem, index) => (
                <div className="flex gap-2" key={index}>
                  <img
                    src={subitem.value ? tick_circle : close_circle}
                    alt="icon"
                    className="w-[25px] h-[25px]"
                  />
                  <p className="text-sm lg:text-base text-grey">
                    {subitem.title}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </ShadowBlog>

      {isCurrentPlan ? (
        <div className="absolute top-[20px] items-center flex-col right-[5px] flex gap-4">
          <div className={"rounded-full bg-primary py-2 px-3 text-white"}>
            Current Plan
          </div>
          <div
            className="rounded-full bg-red-500 py-2 px-3 text-white bg-red cursor-pointer"
            onClick={() => setCancelModalOpen(true)}
          >
            Cancel Subscription
          </div>
        </div>
      ) : (
        userHasPlan && (
          <div
            onClick={() => handleSubmit(item.price_id, item)}
            className="absolute top-[50px] right-[5px] rounded-full bg-primary py-2 px-3 cursor-pointer text-white"
          >
            Upgrade Plan
          </div>
        )
      )}

      <Modal
        open={isCancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        center
        styles={{
          modal: {
            top: "-10%",
            transform: "translateY(-50%)",
            maxHeight: "auto",
            overflow: "auto",
            borderRadius: "30px",
          },
        }}
      >
        <div className="flex flex-col ">
          <h2>Confirm Cancellation</h2>
          <p>Are you sure you want to cancel your subscription?</p>
          <div className="flex justify-end gap-4 mt-4">
            <button
              className="px-4 py-2 bg-gray-300 rounded"
              onClick={() => setCancelModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white bg-slate-950 rounded"
              onClick={() => {
                handleCancelSubscription(subscriptionData.uid);
                setCancelModalOpen(false);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PlanCard;
