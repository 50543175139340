import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../components/Dashboard/layout";
import { Box, CircularProgress, Container, Paper, Typography, LinearProgress, Snackbar, Alert, Button, Tooltip } from "@mui/material";
import TopLayout from "../../../components/Dashboard/toplayout";
import BreadcrumbSteps from "../../../components/steps-breadcrumbs";
import { useEffect, useState } from "react";
import { getFurtherDomainAnalysis, getSingleDomainAnalysis, startFurtherDomainAnalysis } from "../../../api";
import AnalyzeResultsTable from "../../../components/analyze-results-table";
import { InfoOutlined } from "@mui/icons-material";
import BrandInputSection from "../../../components/brand-input-section";
import DomainInvestigationTable from "../../../components/domain-investigation-table";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedDomains } from "../../../slices/scan-slice";
import AnalyzeScanStats from "../../../components/analyze-scan-stats";

const DomainInvestigation = () => {
    const location = useLocation();
    const { analysis_id } = useParams();
    const pollResults = location.state?.poll;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [selectedDomains, setSelectedDomains] = useState([]);
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [error, setError] = useState({ show: false, message: "" });
    const [buttonsDisabled, setButtonsDisabled] = useState(true);

    const handleSelectedRowsChange = (selectedRows) => {
        setSelectedDomains(selectedRows);
        setButtonsDisabled(selectedRows.length === 0)
    };

    useEffect(() => {
        let intervalId;

        const fetchData = () => {
            if (analysis_id) {

                getFurtherDomainAnalysis(analysis_id)
                    .then((response) => {
                        setLoading(false);
                        const resultData = response?.data;
                        setData(resultData)
                        setCompletionPercentage(
                            Math.round((resultData.total_further_done / resultData.total_further) * 100)
                        );

                        if (resultData.status === "further_complete") {
                            clearInterval(intervalId);
                        }
                    })
                    .catch((err) => {
                        setError({ show: true, message: "An unexpected error occured" });
                    });
            }
        };

        if (!pollResults) {
            fetchData();
            setLoading(false);
        } else if (pollResults) {
            intervalId = setInterval(fetchData, 3000);
        }

        return () => clearInterval(intervalId);
    }, [analysis_id, pollResults]);


    const handleCloseSnackbar = () => {
        setError({ ...error, show: false });
    };

    const setupMonitoringHandler = () => {
        if (!selectedDomains || selectedDomains?.length === 0) {
            setError({ show: true, message: "Please select at least one domain to proceed" });
            return false;
        }

        navigate(`/dashboard/setup-monitoring/${analysis_id}`, { state: { selectedDomains: selectedDomains } })
    }

    const handleTakedownClick = () => {
        if (selectedDomains?.length === 0) {
            setError({ show: true, message: "Please select at least one domain to proceed" });
            return;
        }

        navigate(`/dashboard/takedown-domains/${analysis_id}`, { state: { selectedDomains: selectedDomains } })
    }

    return (
        <DashboardLayout>
            <Container maxWidth="100%">
                <Box py={3} className="hidden md:block">
                    <TopLayout title="Analyze Results" />
                </Box>

                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    <BreadcrumbSteps uid={analysis_id} />
                </Paper>

                {loading ? (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="50vh">
                        <Typography mb={2} variant="body1" sx={{ color: "#333" }}>
                            Detecting website and brand spoofing, please wait…
                        </Typography>
                        <CircularProgress />
                    </Box>
                ) : (
                    data && (
                        <>
                            <Paper
                                elevation={3}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 2,
                                    backgroundColor: "#e8f4fc",
                                    borderLeft: "5px solid #2196f3",
                                    borderRadius: 1,
                                    margin: "auto",
                                    mb: 3,
                                }}
                            >
                                <InfoOutlined sx={{ color: "#2196f3", marginRight: 1 }} />
                                <Typography variant="body1" sx={{ color: "#333" }}>
                                    Identify domains linked to a website that may pose a threat or impersonate your brand or organization.
                                </Typography>
                            </Paper>

                            <AnalyzeScanStats completionPercentage={completionPercentage} data={data.further_results_formatted} updated_at={data.updated_at} domain={data.domain} isSpoofing={true} />

                            <Paper elevation={3} sx={{ p: 3 }}>
                                <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" mb={2}>
                                    <Typography variant="h6" component="h2">
                                        Results
                                    </Typography>

                                    <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" pt={2} mb={2}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Tooltip title={buttonsDisabled ? "Select at least one domain to Setup Monitoring." : ""} arrow>
                                                <span>
                                                    <Button
                                                        disabled={buttonsDisabled}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => setupMonitoringHandler()}
                                                    >
                                                        Setup Monitoring
                                                    </Button>
                                                </span>
                                            </Tooltip>

                                            <Tooltip title={buttonsDisabled ? "Select at least one domain to initiate a Takedown." : ""} arrow>
                                                <span>
                                                    <Button
                                                        disabled={buttonsDisabled}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleTakedownClick()}
                                                    >
                                                        Takedown Domain
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>

                                <DomainInvestigationTable
                                    furtherResultsFormatted={data.further_results_formatted}
                                    onSelectedRowsChange={handleSelectedRowsChange}
                                />
                            </Paper>
                        </>
                    )
                )}
            </Container>

            <Snackbar
                open={error.show}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {error.message}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default DomainInvestigation;
