import { useEffect, useState } from "react";

import ScanContent from "../pages/Dashboard/scan/scan";
import Analyze from "../pages/Dashboard/scan/analyze";
import SetupMonitoring from "../pages/Dashboard/scan/setupmonitoring";
import Takedownsite from "../pages/Dashboard/scan/takedownsite";
import { stepdata } from "../data/stepData";
import DomainInvestigation from "../pages/Dashboard/scan/domainInvestigation";
export const useSteps = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [steps, setSteps] = useState(stepdata);

  useEffect(() => {
    const savedStep = localStorage.getItem("step");

    if (savedStep !== null) {
      setCurrentStepIndex(parseInt(savedStep));
    }
  }, []);

  useEffect(() => {
    const updatedSteps = steps.map((item, index) => ({
      ...item,
      completed: index < currentStepIndex,
    }));
    setSteps(updatedSteps);
  }, [currentStepIndex]);

  useEffect(() => {
    localStorage.setItem("step", currentStepIndex.toString());
  }, [currentStepIndex]);

  const handleStepClick = (step) => {
    const index = steps.findIndex((s) => s.stepLabel === step.stepLabel);
    const analysis_id = localStorage.getItem("analysis_id");

    if (index !== -1 && analysis_id) {
      setCurrentStepIndex(index);
      localStorage.setItem("step", index.toString());
    } else {
      console.error("Step not found in steps array");
    }
  };

  const getCurrentStepComponent = () => {
    switch (currentStepIndex) {
      case 0:
        return <ScanContent setCurrentStepIndex={setCurrentStepIndex} />;
      case 1:
        return <Analyze setCurrentStepIndex={setCurrentStepIndex} />;

      case 2:
        return (
          <DomainInvestigation setCurrentStepIndex={setCurrentStepIndex} />
        );
      case 3:
        return <SetupMonitoring setCurrentStepIndex={setCurrentStepIndex} />;
      case 4:
        return <Takedownsite setCurrentStepIndex={setCurrentStepIndex} />;
      default:
        return null;
    }
  };

  return {
    currentStepIndex,
    setCurrentStepIndex,
    steps,
    handleStepClick,
    getCurrentStepComponent,
  };
};
