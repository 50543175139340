import React from "react";
import moment from "moment";
import view from "../../assets/images/icons/view.png";

const ScanTable = ({
  recentAnalysis,
  domainFilter,
  currentPage,
  selectAll,
  handleSelectAll,
  selectedItems,
  handleCheckboxChange,
  handleImageView,
  setSelectedId,
}) => {
  const filteredAnalysis =
    recentAnalysis && recentAnalysis.length > 0
      ? recentAnalysis.filter((item) =>
          domainFilter ? item.domain.includes(domainFilter) : true
        )
      : [];

  return (
    <div className="pt-4 md:pt-8 rounded-xl w-full max-w-full overflow-x-auto custom-scroll">
      <table className="rounded-xl shadow-xl w-full table-auto">
        <thead className="text-start h-[60px] rounded-xl bg-light">
          <tr className="p-3 ">
            <th className="px-3 w-[20px]">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            {[
              "Detected Domain",
              "Brand",
              "Date",
              "A",
              "MX",
              "NS",
              "Enumeration",
              "WHOIS",
              "WEB",
              "Category",
              "Spoofing",
              "Risk",
              "Screenshot",
            ].map((header, index) => (
              <th key={index} className="text-start px-3 min-w-[100px]">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredAnalysis.length > 0 ? (
            filteredAnalysis
              .slice((currentPage - 1) * 10, currentPage * 10)
              .map((item, index) => (
                <tr
                  key={index}
                  className="odd:bg-white even:bg-slate-50 h-[50px] text-grey"
                >
                  <td className="px-3">
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                  <td className="px-3">{item.detected_domain}</td>
                  <td className="px-3">{item.brand || "N/A"}</td>
                  <td className="px-3">
                    {moment(item.date).format("DD-MM-YYYY")}
                  </td>
                  <td className="px-3">{item.a_record || "N/A"}</td>
                  <td className="px-3">{item.mx_record || "N/A"}</td>
                  <td className="px-3">{item.ns_record || "N/A"}</td>
                  <td className="px-3">{item.enumeration || "N/A"}</td>
                  <td className="px-3">{item.whois || "N/A"}</td>
                  <td className="px-3">
                    {item.web.length > 0 ? item.web.join(", ") : "N/A"}
                  </td>
                  <td className="px-3">{item.category || "N/A"}</td>
                  <td className="px-3">
                    {item.spoofing != null ? item.spoofing.toString() : "N/A"}
                  </td>
                  <td className="px-3">{item.risk || "N/A"}</td>
                  <td className="px-3 w-[120px] text-center">
                    {item.screenshot ? (
                      <button
                        className="flex justify-center cursor-pointer border-0 bg-none"
                        onClick={() => handleImageView(item.screenshot)}
                      >
                        <img src={view} alt="viewImage" />
                      </button>
                    ) : (
                      "N/A"
                    )}
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td
                colSpan="14"
                className="min-h-[200px] flex justify-center items-center text-grey"
              >
                There is no data.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ScanTable;
