import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  IconButton,
  Popper,
  ClickAwayListener,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SendIcon from '@mui/icons-material/Send';
import Info from '@mui/icons-material/Info';
import Papa from 'papaparse';

const DomainInputSection = ({ onDomainSubmit, onFileUpload }) => {
  const [domain, setDomain] = useState('');
  const [error, setError] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

  const handleDomainSubmit = () => {
    if (!domainRegex.test(domain)) {
      setError('Please enter a valid domain (e.g., example.com)');
      return;
    }
    setError('');
    onDomainSubmit(domain);
    setDomain('');
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== 'text/csv') {
        setError('Please upload a valid CSV file.');
        return;
      }

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const domains = results.data.map((row) => row.Domains).filter(Boolean);
          onFileUpload(domains);
        },
        error: (error) => {
          setError('Error parsing CSV file.');
          console.error(error);
        },
      });
    }
  };

  const handleTooltipOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleTooltipClose = () => {
    setAnchorEl(null);
  };

  const isTooltipOpen = Boolean(anchorEl);

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" mb={2}>
        Enter Domain or Upload CSV
      </Typography>

      <Box display="flex" gap={2} mb={2}>
        <TextField
          label="Domain"
          variant="outlined"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
          error={!!error}
          helperText={error}
          placeholder="example.com"
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleDomainSubmit}
          startIcon={<SendIcon />}
        >
          Submit
        </Button>
      </Box>

      <Box display="flex" alignItems="center" gap={2}>
        <Button
          variant="outlined"
          color="primary"
          component="label"
          startIcon={<UploadFileIcon />}
        >
          Upload CSV
          <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
        </Button>

        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Box>
            <IconButton color="primary" onClick={handleTooltipOpen}>
              <Info />
            </IconButton>
            <Popper
              open={isTooltipOpen}
              anchorEl={anchorEl}
              placement="bottom-start"
              disablePortal
              modifiers={[
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: 'window',
                  },
                },
              ]}
              style={{
                zIndex: 1200,
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  maxWidth: '300px',
                }}
              >
                <Typography variant="body2">
                  Ensure your imported file is in UTF-8 encoding and contains a
                  single column with the header Domains. Each row should list
                  one domain (e.g., example1.com, example2.com).{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/sample.csv`}
                    download="Sample_Domains.csv"
                    style={{
                      color: '#1976d2',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Download Sample File
                  </a>
                </Typography>
              </Paper>
            </Popper>
          </Box>
        </ClickAwayListener>
      </Box>
    </Paper>
  );
};

export default DomainInputSection;
