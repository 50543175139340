// settingsData.js
import { useSelector } from "react-redux";
import Profile from "../pages/Dashboard/settings/profile";
import ChangePassword from "../pages/Dashboard/settings/changepassword";
import Subscription from "../pages/Dashboard/settings/subscription";
import LicensedInfo from "../pages/Dashboard/settings/licenseinfo";
import Help from "../pages/Dashboard/settings/help";
import EmailTemplateSettings from "../pages/Dashboard/settings/emailtemplate";
import ModuleDictionary from "../pages/Dashboard/settings/ModuleDictionary";

export const useSettingsData = () => {
  const account = useSelector((state) => state.account);

  const tabs = [
    {
      title: "Profile Setup",
      short: "Profile",
      tabpanel: <Profile account={account.user} />,
    },
    {
      title: "Module Dictionary files",
      short: "Module",
      tabpanel: <ModuleDictionary />,
    },
    {
      title: "Change Password",
      short: "Change PW",
      tabpanel: <ChangePassword />,
    },
    {
      title: "Subscription",
      short: "Subscription",
      tabpanel: <Subscription />,
    },
    {
      title: "License Info",
      short: "License",
      tabpanel: <LicensedInfo />,
    },
    {
      title: "Email Template settings",
      short: "Template",
      tabpanel: <EmailTemplateSettings />,
    },
    {
      title: "Help",
      short: "Help",
      tabpanel: <Help />,
    },
  ];

  return { account, tabs };
};
