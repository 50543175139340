import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useLocation, useNavigate } from "react-router-dom";

import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";

import menu from "../../assets/images/icons/menu.png";
import logo from "../../assets/images/logo.png";
import user_account from "../../assets/images/user_account.png";
import { logout } from "../../actions/accountActions";

import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "react-responsive-modal/styles.css";

const Header = () => {
  const navbar_tabs = [
    {
      label: "Home",
      value: "/",
    },
    // {
    //   label: "Blog",
    //   value: "/blog",
    // },
    {
      label: "About Us",
      value: "/aboutus",
    },
    {
      label: "Pricing",
      value: "/pricing",
    },
    {
      label: "Technology",
      value: "/technology",
    },
  ];

  const auth_navbar_tabs = [
    {
      label: "Home",
      value: "/",
    },
    {
      label: "Blog",
      value: "/blog",
    },
    {
      label: "About Us",
      value: "/aboutus",
    },
    {
      label: "Pricing",
      value: "/pricing",
    },
    {
      label: "Technology",
      value: "/technology",
    },
    {
      label: "Dashboard",
      value: "/dashboard/index",
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const [url, setUrl] = useState(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const account = useSelector((state) => state.account);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  // const mobileMenu = () => {
  //   setMobile(!mobile);
  // };

  const handleLogout = async () => {
    try {
      localStorage.clear();
      dispatch(logout());
      navigate("/");
    } catch (error) {
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  const [open, setopen] = useState(false);

  const onOpenModal = () => setopen(true);
  const onCloseModal = () => setopen(false);

  return (
    <div className="bg-secondary rounded-b-3xl">
      <div className="max-w-xl lg:max-w-6xl mx-auto bg-header_bg px-6">
        <div className="flex justify-between items-center py-[20px]">
          <img src={logo} className="h-[30px] lg:h-[40px]" alt="" />

          {!account.user ? (
            <div className="hidden justify-between items-center text-white lg:flex">
              {navbar_tabs.map((item, index) => {
                if (item.value === url) {
                  return (
                    <a
                      key={index}
                      className={
                        "ml-8 cursor-pointer hover:text-primary text-primary"
                      }
                      href={item.value}
                    >
                      {item.label}
                    </a>
                  );
                } else {
                  return (
                    <a
                      key={index}
                      className="ml-8 cursor-pointer hover:text-primary"
                      href={item.value}
                    >
                      {item.label}
                    </a>
                  );
                }
              })}
            </div>
          ) : (
            <div className="hidden justify-between items-center text-white lg:flex">
              {auth_navbar_tabs.map((item, index) => {
                if (item.value === url) {
                  return (
                    <a
                      key={index}
                      className={
                        "ml-8 cursor-pointer hover:text-primary text-primary"
                      }
                      href={item.value}
                    >
                      {item.label}
                    </a>
                  );
                } else {
                  return (
                    <a
                      key={index}
                      className="ml-8 cursor-pointer hover:text-primary"
                      href={item.value}
                    >
                      {item.label}
                    </a>
                  );
                }
              })}
            </div>
          )}

          {!account.user ? (
            <div>
              <div className="hidden lg:flex items-center justify-center">
                <a
                  className="bg-primary text-white min-w-[200px] h-[40px] rounded-full flex items-center justify-center"
                  href="/login"
                >
                  <p>Login / Register</p>
                </a>
                {/* <Button color='primary' title="Register" /> */}
              </div>
              <div className="block lg:hidden">
                <img
                  src={menu}
                  className="w-[20px]"
                  onClick={onOpenModal}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <div className="flex gap-2 items-center">
              <Menu
                menuButton={
                  <MenuButton>
                    <div className="flex items-center gap-3">
                      <img src={user_account} alt="" />
                      <p className="text-sm md:text-base hidden md:block text-white">
                        {account.user.first_name} {account.user.last_name}
                      </p>
                    </div>
                  </MenuButton>
                }
                transition
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
              <div className="block lg:hidden">
                <img
                  src={menu}
                  className="w-[20px]"
                  onClick={onOpenModal}
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <div className="s">
          <Modal
            open={open}
            onClose={onCloseModal}
            showCloseIcon={false}
            classNames={{
              overlay: "customOverlay",
              modal: "homeheader",
            }}
          >
            <div className="p-4 bg-secondary w-full">
              <div className="pb-6">
                <img src={logo} className="h-[40px]" alt="" />
              </div>
              {!account.user ? (
                <div className="flex flex-col gap-4 text-white">
                  {navbar_tabs.map((item, index) => {
                    if (item.value === url) {
                      return (
                        <a
                          key={index}
                          className={
                            "cursor-pointer hover:text-primary text-primary"
                          }
                          href={item.value}
                        >
                          {item.label}
                        </a>
                      );
                    } else {
                      return (
                        <a
                          key={index}
                          className="cursor-pointer hover:text-primary"
                          href={item.value}
                        >
                          {item.label}
                        </a>
                      );
                    }
                  })}
                  <a className="text-white cursor-pointer" href="/login">
                    <p>Login / Register</p>
                  </a>
                </div>
              ) : (
                <div className="flex flex-col gap-4 text-white">
                  {auth_navbar_tabs.map((item, index) => {
                    if (item.value === url) {
                      return (
                        <a
                          key={index}
                          className={
                            "cursor-pointer hover:text-primary text-primary"
                          }
                          href={item.value}
                        >
                          {item.label}
                        </a>
                      );
                    } else {
                      return (
                        <a
                          key={index}
                          className="cursor-pointer hover:text-primary"
                          href={item.value}
                        >
                          {item.label}
                        </a>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Header;
