import React, { useEffect, useState } from "react";

import axios from "axios";

import { Formik } from "formik";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";

import search from "../../assets/images/icons/search.png";
import Button from "../common/button";
import { renderStatusIcon } from "../../utiles/renderIcon";
import { useNavigate } from "react-router-dom";

const DomainScanForm = ({
  domain,
  modulesList,
  selectedModule,
  setSelectedModule,
  setLoading2,
  setLoading3,
  setScanResult,
  setPreviousScan,
  setAnalysisId,
  setLoading4,
  setBack,
}) => {
  const [wordlist, setWordlists] = useState([]);
  const navigate = useNavigate();

  const toggleModule = (module) => {
    setSelectedModule((prevSelectedModules) => {
      const isModuleSelected = prevSelectedModules.some(
        (m) => m.value === module.value
      );

      switch (module.value) {
        case "all":
          return isModuleSelected
            ? []
            : modulesList.filter((m) => m.value !== "all_no_keyword");

        case "all_no_keyword":
          return isModuleSelected
            ? []
            : modulesList.filter(
                (m) =>
                  m.value !== "all" &&
                  m.value !== "module_domain_word_combination"
              );

        case "module_domain_word_combination":
          return isModuleSelected
            ? prevSelectedModules.filter(
                (m) => m.value !== "module_domain_word_combination"
              )
            : [...prevSelectedModules, module];

        default:
          return isModuleSelected
            ? prevSelectedModules.filter((m) => m.value !== module.value)
            : [...prevSelectedModules, module];
      }
    });
  };

  const fetchWordlists = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/wordlists/`
      );
      setWordlists(response.data);
    } catch (error) {
      console.error("Error fetching wordlists:", error);
    }
  };

  useEffect(() => {
    fetchWordlists();
  }, []);
  return (
    <Formik
      initialValues={{
        domain: domain || "",
        modules: "",
        keywords: "",
      }}
      validationSchema={Yup.object().shape({
        domain: Yup.string()
          .max(255)
          .matches(
            /^(?!www\.)(?!https?:\/\/)(?!.*\b([a-zA-Z0-9-]+)\.\1\b)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,}$/,
            "Invalid domain format (e.g. Domain.com, Domain.co.uk, but not coms.com.com)"
          )
          .required("Domain is required"),

        modules: Yup.string().max(255),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          let _modules = [];
          let keywordsArray = values.keywords
            .split(",")
            .filter((item) => item.trim() !== "");

          enqueueSnackbar("Analysis started successfully!", {
            variant: "success",
          });
          resetForm();
          setLoading4(false);
          setBack(false);
          localStorage.removeItem("isback");

          if (selectedModule.some((item) => item.value === "all")) {
            _modules = modulesList.slice(2).map((item) => ({
              module_name: item.value,
              enabled: true,
              config: {},
            }));
          } else if (
            selectedModule.some((item) => item.value === "all_no_keyword")
          ) {
            _modules = modulesList
              .slice(2)
              .filter((item) => item.value !== "module_domain_word_combination")
              .map((item) => ({
                module_name: item.value,
                enabled: true,
                config: {},
              }));
          } else {
            selectedModule.forEach((item) => {
              _modules.push({
                module_name: item.value,
                enabled: true,
                config: {},
              });
            });
          }
          const request_body = {
            domain: values.domain,
            modules: _modules.map((module) => ({
              module_name: module.module_name,
              enabled: true,
              wordlist:
                module.module_name === "module_domain_word_combination"
                  ? values.keywords || []
                  : undefined,
            })),
            keywords: _modules.some(
              (module) =>
                module.module_name === "module_domain_word_combination"
            )
              ? keywordsArray
              : [],
            config: {},
            custom_domains: [],
          };

          setLoading2(false);
          setLoading3(true);
          localStorage.removeItem("analysis_id");

          await axios
            .post(
              `${process.env.REACT_APP_BASIC_API_URL}/analysis/start/`,
              request_body
            )
            .then((response) => {
              setLoading3(false);
              setScanResult(response.data);
              setPreviousScan(true);
              localStorage.setItem(
                "previous_scan",
                JSON.stringify(response.data)
              );
              setStatus({ success: true });
              setSubmitting(true);
              resetForm();
              const estimatedTime = response.data.variations.reduce(
                (max, obj) => (obj.count > max ? obj.count : max),
                Number.NEGATIVE_INFINITY
              );
              setTimeout(() => {
                setLoading2(true);
                setAnalysisId(response.data.analysis_id);
                localStorage.setItem("domain", values.domain);

                localStorage.setItem("analysis_id", response.data.analysis_id);
                enqueueSnackbar("Fetched response data successfully!", {
                  variant: "success",
                });
              }, (estimatedTime / 469 + 1) * 1000);
            })
            .catch((error) => {
              setLoading3(false);
              const message =
                error.response.data.details || "Failed to scan a domain!";
              enqueueSnackbar(message, {
                variant: "error",
              });
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            });
        } catch (error) {
          const message = "Failed to scan a domain!";
          enqueueSnackbar(message, {
            variant: "error",
          });
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-2">
            <label className="text-xs sm:text-sm py-2 block">Domain</label>
            <div className="flex items-center gap-4">
              <input
                className={
                  errors.domain
                    ? "w-[450px] h-[40px] rounded-full border-[0.1px] border-red focus:outline-red px-6"
                    : "w-[450px] h-[40px] rounded-full border-[0.1px] focus:outline-blue-500 px-6"
                }
                placeholder="your domain"
                name="domain"
                type="text"
                onChange={handleChange}
                value={values.domain}
                required
              />

              <div className="col-span-2 lg:col-span-2 flex items-end">
                <button
                  type="submit"
                  disabled={errors.domain}
                  className={`${
                    errors.domain ? "cursor-not-allowed bg-gray-500" : ""
                  }  flex items-center justify-center gap-2 before:ease relative h-[36px] md:h-[40px] w-full px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40`}
                >
                  <span className="text-sm lg:text-base relative z-10">
                    Scan
                  </span>
                  <img src={search} className="w-[15px]" alt="" />
                </button>
              </div>
            </div>
            {errors.domain && (
              <p className="text-sm text-red pl-2 pt-2">{errors.domain}</p>
            )}
            <div className="col-span-2 lg:col-span-4">
              <div className="flex  items-center gap-3">
                <div className="flex flex-col items-center my-2">
                  <span className="text-sm text-center pb-2">
                    Custom Domain
                  </span>
                  <button className="bg-green text-white p-2 px-8 rounded-xl ml-2">
                    Upload
                  </button>
                </div>
                <p className="text-center">
                  Want to include additional domains in your analysis? Please
                  upload them here
                </p>
              </div>
            </div>
            <div className="col-span-2 lg:col-span-4">
              <label className="text-xs sm:text-sm pb-1">Module</label>
              <div className="rounded-xl shadow-xl w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead className="text-start h-[60px] rounded-xl bg-light">
                    <tr className="w-full">
                      <th className="p-2 text-center w-10">Select</th>
                      <th className="p-2 text-center w-36">Status</th>
                      <th className="p-2 text-center w-1/3">Module Name</th>
                      <th className="p-2 text-center w-1/3">Description</th>
                      <th className="p-2 text-center min-w-[200px]">
                        Optional settings
                      </th>
                    </tr>
                  </thead>
                </table>
                <div className="overflow-y-auto h-[300px]">
                  <table className="w-full table-auto">
                    <tbody>
                      {modulesList.map((module) => {
                        const isEnabled = selectedModule.some(
                          (item) => item.value === module.value
                        );

                        return (
                          <tr
                            key={module.value}
                            className="odd:bg-white even:bg-slate-50 h-[50px]"
                          >
                            <td className="p-2 text-center w-10">
                              <input
                                type="checkbox"
                                className="accent-secondary"
                                checked={selectedModule.includes(module)}
                                onChange={() => toggleModule(module)}
                              />
                            </td>
                            <td className="p-2 text-center w-36">
                              <div className="flex items-center justify-center pl-10">
                                {renderStatusIcon(isEnabled)}{" "}
                              </div>
                            </td>
                            <td className="p-2 text-start w-1/3">
                              {module.label}
                            </td>
                            <td className="p-2 text-center w-1/3"></td>
                            <td className="p-2 text-center min-w-[200px]">
                              {module.value ===
                                "module_domain_word_combination" && (
                                <div className="w-full">
                                  <div className="col-span-4 lg:col-span-4">
                                    <label className="text-xs md:text-sm">
                                      Keywords
                                    </label>
                                    <div className="flex items-center space-x-2">
                                      {wordlist.length === 0 ? (
                                        <div className="flex items-center">
                                          <p>
                                            No keywords defined, please create
                                            someone
                                          </p>
                                          <button
                                            className="h-[40px] px-4 bg-blue-500 text-white rounded-full"
                                            onClick={() =>
                                              navigate(
                                                `${process.env.REACT_APP_BASIC_API_URL}/dashboard/settings`
                                              )
                                            }
                                          >
                                            Create
                                          </button>
                                        </div>
                                      ) : (
                                        <>
                                          <select
                                            className="w-full h-[40px] rounded-full border-[0.1px] focus:outline-blue-500 px-6"
                                            name="keywords"
                                            value={values.keywords}
                                            onChange={handleChange}
                                          >
                                            <option value="">
                                              No keywords defined
                                            </option>
                                            {wordlist.map((keyword) => (
                                              <option
                                                key={keyword.id}
                                                value={keyword.name}
                                              >
                                                {keyword.code}
                                              </option>
                                            ))}
                                          </select>
                                          <button
                                            className="h-[40px] px-4 bg-blue-500 text-white rounded-full"
                                            onClick={() =>
                                              navigate(
                                                `/dashboard/settings?keyword=true`
                                              )
                                            }
                                          >
                                            Create
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-8 justify-between">
            <Button className={"mt-5"} color={"primary"}>
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default DomainScanForm;
