import axios from 'axios'

const authHeader = "";

export const getAnalysisStats = async (uid) => {
    return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/analysis/stats/`,
        {
            params: { analysis_id: uid },
        }
    )
}

