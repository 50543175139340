import React, { useState } from "react";
import {
    Box,
    Typography,
    Switch,
    FormControlLabel,
    Paper,
} from "@mui/material";
import BrandModal from "../brand-modal";

const MonitorSettings = ({
    alertConditions,
    onConditionChange,
    onBrandModalSubmit,
    defaultEnabledSettings = [],
    furtherResults,
}) => {
    const sortedSettings = [...alertConditions].sort((a, b) => {
        const isAEnabled = defaultEnabledSettings.includes(a.code);
        const isBEnabled = defaultEnabledSettings.includes(b.code);
        return isAEnabled === isBEnabled ? 0 : isAEnabled ? -1 : 1;
    });

    const [enabledSettings, setEnabledSettings] = useState(
        sortedSettings.reduce((acc, setting) => {
            acc[setting.code] = defaultEnabledSettings.includes(setting.code);
            return acc;
        }, {})
    );

    const [isModalOpen, setModalOpen] = useState(false);
    const [brandName, setBrandName] = useState("");
    const [logoFile, setLogoFile] = useState(null);
    const [hasNoBrandOrLogo, setHasNoBrandOrLogo] = useState(
        !furtherResults?.params?.brand?.length &&
        !furtherResults?.params?.logo_scan
    );
    const [pendingSettingCode, setPendingSettingCode] = useState(null);

    const handleToggleSetting = (settingCode) => {
        if (settingCode === "content_change_and_spoof") {
            if (!enabledSettings[settingCode] && hasNoBrandOrLogo) {            
                setPendingSettingCode(settingCode);
                setModalOpen(true);
                return;
            }
        }

        setEnabledSettings((prev) => {
            const updatedSettings = { ...prev, [settingCode]: !prev[settingCode] };
            const selectedSettings = Object.keys(updatedSettings).filter(
                (code) => updatedSettings[code]
            );
            onConditionChange(selectedSettings);
            return updatedSettings;
        });
    };

    const handleToggleAll = () => {
        const allEnabled = Object.values(enabledSettings).every(Boolean);

        if (
            !allEnabled &&
            hasNoBrandOrLogo &&
            !enabledSettings["content_change_and_spoof"]
        ) {
            setPendingSettingCode("content_change_and_spoof");
            setEnabledSettings((prev) => {
                const updatedSettings = { ...prev };
                sortedSettings.forEach((setting) => {
                    updatedSettings[setting.code] = setting.code !== "content_change_and_spoof";
                });
                const selectedSettings = Object.keys(updatedSettings).filter(
                    (code) => updatedSettings[code]
                );
                onConditionChange(selectedSettings);
                return updatedSettings;
            });
            setModalOpen(true);
            return;
        }

        const updatedSettings = sortedSettings.reduce((acc, setting) => {
            acc[setting.code] = !allEnabled;
            return acc;
        }, {});
        setEnabledSettings(updatedSettings);
        const selectedSettings = Object.keys(updatedSettings).filter((code) => updatedSettings[code]);
        onConditionChange(selectedSettings);
    };



    const handleModalClose = () => {
        setModalOpen(false);
        setBrandName("");
        setLogoFile(null);
    };

    const handleSubmitModal = (modalData) => {
        onBrandModalSubmit(modalData);
        setHasNoBrandOrLogo(false);

        if (pendingSettingCode === "content_change_and_spoof") {        
            setEnabledSettings((prev) => {
                const updatedSettings = { ...prev, [pendingSettingCode]: true };
                const selectedSettings = Object.keys(updatedSettings).filter(
                    (code) => updatedSettings[code]
                );
                onConditionChange(selectedSettings);
                return updatedSettings;
            });
            setPendingSettingCode(null);
        }
        handleModalClose();
    };


    return (
        <>
            <Paper sx={{ p: 3 }}>
                <Typography variant="h6" mb={2}>
                    Monitor Conditions
                </Typography>

                <FormControlLabel
                    control={
                        <Switch
                            checked={Object.values(enabledSettings).every(Boolean)}
                            onChange={handleToggleAll}
                            color="primary"
                        />
                    }
                    label="Enable All"
                    sx={{ marginBottom: 2 }}
                />

                <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
                    {sortedSettings.map((setting) => (
                        <Box
                            key={setting.code}
                            p={1}
                            display="flex"
                            alignItems="center"
                            gap={2}
                            border="1px solid #ccc"
                            borderRadius={2}
                            sx={{
                                "&:hover": { backgroundColor: "#f9f9f9" },
                            }}
                        >
                            <Typography sx={{ flexGrow: 1 }}>{setting.name}</Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={enabledSettings[setting.code]}
                                        onChange={() => handleToggleSetting(setting.code)}
                                        color="primary"
                                    />
                                }
                                label={enabledSettings[setting.code] ? "On" : "Off"}
                            />
                        </Box>
                    ))}
                </Box>
            </Paper>

            {isModalOpen &&
                <BrandModal isModalOpen={isModalOpen} onModalClose={handleModalClose} onModalSubmit={handleSubmitModal} />
            }
        </>
    );
};

export default MonitorSettings;
