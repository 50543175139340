import React, { useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    Container,
    Paper,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";

import DashboardLayout from "../../components/Dashboard/layout";
import TopLayout from "../../components/Dashboard/toplayout";
import { deleteTakeDown, getAllTakeDowns } from "../../api";
import SnackBarWrapper from "../../components/wrappers/snackbar-wrapper";
import TakeDownPageTable from "../../components/takedown-page-table";
import { useLocation } from "react-router-dom";

const TakeDown = () => {
    const [loading, setLoading] = useState(false);
    const [loadTable, setLoadTable] = useState(true);
    const [takeDowns, setTakeDowns] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const [success, setSuccess] = useState({ show: false, message: "" });
    const [error, setError] = useState({ show: false, message: "" });

    const [pendingDelete, setPendingDelete] = useState(null);

    const location = useLocation();
    const takeDownId = location?.state?.takedown_id;


    useEffect(() => {
        if (loadTable) {
            setLoading(true);
            getAllTakeDowns()
                .then((response) => {
                    setTakeDowns(response?.data || []);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setError({ show: true, message: "Failed to load monitors" });
                    setLoading(false);
                });

            setLoadTable(false)
        }
    }, [loadTable]);


    const handleDeleteModal = (takedown) => {
        setOpenDialog(true);
        setPendingDelete(takedown.uid)
    }

    const handleDeleteModalClose = () => {
        setOpenDialog(false);
        setPendingDelete(null)
    }

    const handleTakeDownDelete = () => {

        deleteTakeDown(pendingDelete).then((response) => {
            setOpenDialog(false);
            setSuccess({ show: true, message: "Takedown deleted successfully" });
            setLoadTable(true)
        }).catch(() => {
            setError({ show: true, message: "Could not delete takedown" });
        })
    }

    const handleSnackbarClose = () => {
        setError({ ...error, show: false });
        setSuccess({ ...success, show: false });
    }

    return (
        <DashboardLayout>
            <Container maxWidth="100%">
                <Box py={3} className="hidden md:block">
                    <TopLayout title="Takedown" />
                </Box>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                            <Typography variant="h6" component="h2">
                                Takedown
                            </Typography>
                        </Paper>

                        <TakeDownPageTable takeDownId={takeDownId} takeDowns={takeDowns} handleDeleteModal={handleDeleteModal} setSuccess={setSuccess} setError={setError} />
                    </Box> 
                )}
            </Container>

            {
                error.show &&
                <SnackBarWrapper onSnackbarClose={handleSnackbarClose} status={"error"} message={error.message} />
            }

            {
                success.show &&
                <SnackBarWrapper onSnackbarClose={handleSnackbarClose} status={"success"} message={success.message} />
            }


            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Delete Takedown</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete takedown? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteModalClose()}>Cancel</Button>
                    <Button onClick={() => handleTakeDownDelete()} color="error">
                        Delete Takedown
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default TakeDown;