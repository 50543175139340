import { useState } from "react";
import axios from "axios";
import { useStripe } from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";

export const useStripeCheckout = () => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const [searchParams] = useSearchParams();

  const handleSubmit = async (plan) => {
    if (!stripe) return;

    setLoading(true);

    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: plan.price_id, quantity: 1 }],
      mode: "subscription",
      successUrl: `${window.location.origin}/success?plan=${encodeURIComponent(
        plan.name
      )}`,
      cancelUrl: `${window.location.origin}/cancel`,
    });

    if (error) {
      console.error("Stripe Checkout Error:", error);
    } else {
      const requestBody = {
        plan_type: plan.name,
        payment_status: "Success",
        payment_data: {},
      };

      try {
        await axios.post(`${process.env.REACT_APP_STRIPE_API}`, requestBody);
      } catch (postError) {
        console.error("Error sending payment information:", postError);
      }
    }
    searchParams.set("subscription", plan.name);
    setLoading(false);
  };

  return { handleSubmit, loading };
};
