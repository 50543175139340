import axios from 'axios'

const authHeader = "";

export const getSingleDomainAnalysis = async (uid) => {
    return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}`,
        {
            params: { analysis_id: uid },
        }
    )
}

export const getAnalysis = async (pageConfig) => {
    return await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/analysis/`,
        {
            params: pageConfig,
        }
    );
}

export const getWordlists = async () => {
    return await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/wordlists/`
    );
};


// export const getModuleConfig = async () => {
//     return await axios.get(
//         `${process.env.REACT_APP_BASIC_API_URL}/analysis/configs/`
//     );
// };

// export const getMonitoringConfig = async () => {
//     return await axios.get(
//         `${process.env.REACT_APP_BASIC_API_URL}/analysis/configs/`
//     );
// };

export const getConfig = async () => {
    return await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/analysis/configs/`
    );
};



export const startAnalysis = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_BASIC_API_URL}/analysis/start/`, data
    );
};

export const stopAnalysis = async (analysis_id) => {
    return await axios.post(
        `${process.env.REACT_APP_BASIC_API_URL}/analysis/${analysis_id}/stop/`);
};


export const startFurtherDomainAnalysis = async (uid, data) => {
    return await axios.post(`${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}/further/`, data)
}


export const getFurtherDomainAnalysis = async (uid) => {
    return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}/further/`,
        {
            params: {
                analysis_id: uid,
            }
        }
    )
}


export const getExportVariationsCsv = async (uid) => {
    return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}/variations/export/`,
        {
            responseType: "blob",
            headers: {
                Accept: "*/*",
            },
        }
    )
}

export const getExportAnalysisCsv = async (uid) => {
    return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}/export/`,
        {
            responseType: "blob",
            headers: {
                Accept: "*/*",
            },
        }
    )
}

// export const startSetupMonitor = async (uid, data) => {
//     return await axios.post(
//         `${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}/monitors/`, data
//     )
// }

export const startSetupMonitor = async (uid, data) => {
    return await axios.post(
        `${process.env.REACT_APP_BASIC_API_URL}/monitors/?analysis_id=${uid}`, data
    )
}

export const getDomainMonitor = async (uid) => {
    return await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/monitors/?analysis_id=${uid}`
    )
}

export const getAllMonitors = async () => {
    return await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/monitors/`
    )
}

export const getAllTakeDowns = async () => {
    return await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/takedown/`
    )
}


export const getAllAlerts = async () => {
    return await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/alerts/`
    )
}


export const startDomainTakeDown = async (data) => {
    return await axios.post(`${process.env.REACT_APP_BASIC_API_URL}/takedown/start/`, data)
}



export const getDomainTakeDown = async (uid) => {
    return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/takedown`
       
    )
}

export const saveWordList = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_BASIC_API_URL}/wordlists/`, data
    )
}


export const startTakeDown = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_BASIC_API_URL}/takedown/start/`, data
    )
}

export const sendTakeDown = async (takedown_id, data) => {
    return await axios.post(
        `${process.env.REACT_APP_BASIC_API_URL}/takedown/${takedown_id}/send/`, data
    )
}

export const getPowerFile = async () => {
    return await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/takedown/power-file/`,
        {
            responseType: "blob", // Ensure the response is treated as a Blob
        }
    )
}


export const deleteAnalysis = async (analysis_id) => {
    return await axios.delete(`${process.env.REACT_APP_BASIC_API_URL}/analysis/${analysis_id}`)
}

export const deleteMonitor = async (analysis_id, data) => {
    return await axios.delete(`${process.env.REACT_APP_BASIC_API_URL}/monitors`, {
        params: {
            analysis_id: analysis_id,
        },
        data: data
    })
}

export const deleteTakeDown = async (takedown_id) => {
    return await axios.delete(`${process.env.REACT_APP_BASIC_API_URL}/takedown`, {
        params: {
            takedown_id: takedown_id,
        }
    })
}

export const getSingleMonitor = async (monitor_id) => {
    return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/monitors`, {
        params: {
            monitor_id: monitor_id,
        }
    })
}

export const updateSingleMonitor = async (analysis_id, data) => {
    return await axios.patch(`${process.env.REACT_APP_BASIC_API_URL}/monitors`, data, {
        params: {
            analysis_id: analysis_id,
        }
    })
}

export const getSingleMonitorAlerts = async (monitor_id) => {
    return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/alerts`, {
        params: {
            monitor_id: monitor_id,
        }
    })
}







