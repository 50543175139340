import React, { useState } from "react";
import ResponsivePagination from "react-responsive-pagination";

import greysearch from "../assets/images/icons/grey_search.png";
import blog1 from "../assets/images/blog1.png";
import blog2 from "../assets/images/blog2.png";
import BlogCard from "../components/common/blogcard";

import "react-responsive-pagination/themes/classic.css";

const Blog = () => {
  const data = [
    {
      title: "Lorem ipsum dolor sit amet consectetur.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. Sit arcu faucibus felis commodo ornare Nibh.",
      date: "30 March 2024",
      image: blog1
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. Sit arcu faucibus felis commodo ornare Nibh.",
      date: "30 March 2024",
      image: blog2
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. Sit arcu faucibus felis commodo ornare Nibh.",
      date: "30 March 2024",
      image: blog1
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. Sit arcu faucibus felis commodo ornare Nibh.",
      date: "30 March 2024",
      image: blog2
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. Sit arcu faucibus felis commodo ornare Nibh.",
      date: "30 March 2024",
      image: blog1
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. Sit arcu faucibus felis commodo ornare Nibh.",
      date: "30 March 2024",
      image: blog2
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. Sit arcu faucibus felis commodo ornare Nibh.",
      date: "30 March 2024",
      image: blog1
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. Sit arcu faucibus felis commodo ornare Nibh.",
      date: "30 March 2024",
      image: blog2
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. Sit arcu faucibus felis commodo ornare Nibh.",
      date: "30 March 2024",
      image: blog1
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Feugiat sed egestas id eu tellus. Sit arcu faucibus felis commodo ornare Nibh.",
      date: "30 March 2024",
      image: blog2
    },
  ];
  const [currentData, setCurrentData] = useState(data.slice(0, 4));

  const totalPages = Math.ceil(data.length / 4);
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(page) {
    setCurrentPage(page);
    setCurrentData(data.slice((page - 1) * 4, page * 4));
    // ... do something with `page`
  }
  return (
    <div className="max-w-6xl mx-auto py-[3rem] lg:py-[5rem] px-4">
      <div>
        <p className="text-4xl font-bold pb-[1rem]">Our Blog</p>
        <p className="pb-[2.5rem]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ipsum
          tortor, hendrerit vehicula nisi ut, dapibus eleifend ligula.
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="col-span-2 order-last lg:order-first">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {
                currentData.map((item, index) => {
                    return(
                        <div key={index}>
                            <BlogCard image={item.image} title={item.title} description={item.description} date={item.date} />
                        </div>
                    )
                })
            }
          </div>
          
      <div className="flex justify-center pt-8">
        <div className="w-[300px]">
          <ResponsivePagination
            total={totalPages}
            current={currentPage}
            onPageChange={(page) => handlePageChange(page)}
          />
        </div>
      </div>
        </div>
        <div className="order-first lg:order-last">
          <div className="relative pb-4">
            <input
              className="w-full h-[40px] rounded-full border-[0.1px] border-grey pl-6 pr-[45px]"
              placeholder="Search your keyword..."
            />
            <img
              src={greysearch}
              className="absolute right-[12px] top-[8px]"
              alt=""
            />
          </div>
          <div className="flex flex-col gap-4">
            <p className="font-bold text-lg">Recent blogs</p>
            <div className="flex gap-4">
              <img src={blog1} className="w-[100px] rounded-xl" alt="" />
              <div className="flex flex-col justify-between">
                <p className="font-semibold">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
                <p className="text-sm text-grey">30 March 2024</p>
              </div>
            </div>
            <div className="flex gap-4">
              <img src={blog2} className="w-[100px] rounded-xl" alt="" />
              <div className="flex flex-col justify-between">
                <p className="font-semibold">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
                <p className="text-sm text-grey">30 March 2024</p>
              </div>
            </div>
            <div className="flex gap-4">
              <img src={blog1} className="w-[100px] rounded-xl" alt="" />
              <div className="flex flex-col justify-between">
                <p className="font-semibold">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
                <p className="text-sm text-grey">30 March 2024</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
