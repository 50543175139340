import React, { useCallback, useEffect, useState } from 'react';
import DashboardLayout from "../../../components/Dashboard/layout";
import { Alert, Box, CircularProgress, Container, Paper, Snackbar, Typography, Modal, Button } from "@mui/material";
import TopLayout from "../../../components/Dashboard/toplayout";
import BreadcrumbSteps from "../../../components/steps-breadcrumbs";
import { InfoOutlined } from "@mui/icons-material";
import ModuleSettings from "../../../components/module-settings";
import { getModuleConfig, getWordlists, startAnalysis } from "../../../api";
import DomainInputSection from '../../../components/domain-input-section';
import { useNavigate } from 'react-router-dom';
import WordlistModal from '../../../components/wordlist-modal';
import { useSelector } from 'react-redux';

const NewScan = () => {
    const modules = useSelector(state => state.scan.config.modules);

    const [loading, setLoading] = useState(false);
    const [selectedModules, setSelectedModules] = useState([]);
    const [isWordCombinationSelected, setIsWordCombinationSelected] = useState(false);
    const [customDomains, setCustomDomains] = useState([]);
    const [error, setError] = useState({ show: false, message: "" });
    const [wordlist, setWordlist] = useState("");
    const [success, setSuccess] = useState({ show: false, message: "" });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customWordLists, setCustomWordlists] = useState([]);

    const navigate = useNavigate();
    const defaultEnabledModules = [""];

    const [loadWordlists, setLoadWordlists] = useState(true);

    useEffect(() => {
        if (loadWordlists) {
            getWordlists()
                .then((response) => {
                    const wordLists = response?.data;
                    setCustomWordlists(Array.isArray(wordLists) ? wordLists : []);
                    setLoadWordlists(false)
                })
                .catch(() => {
                    setCustomWordlists([])
                    setLoadWordlists(false)
                });
        }

    }, [loadWordlists]);

    const handleDomainSubmit = (domain) => {

        if (selectedModules.length === 0) {
            setError({ show: true, message: "Please select modules before proceeding with the scan" });
            return false;
        }

        const data = {
            config: {},
            custom_domains: customDomains,
            domain: domain,
            keywords: [],
            modules: selectedModules?.map((moduleCode) => {
                const moduleData = {
                    module_name: moduleCode,
                    enabled: true,
                };

                if (moduleCode === "module_domain_word_combination" && wordlist) {
                    moduleData.wordlist = wordlist;
                }

                return moduleData;
            }),
        };

        setLoading(true);

        startAnalysis(data).then((response) => {
            if (response?.status === 200 && response?.data) {
                navigate(`/dashboard/analyze-results/${response?.data?.analysis_id}`, { state: { new_scan: true, poll: true, data: response?.data } });
            }
        }).catch((error) => {
            if (error?.response?.status === 400 && error?.response?.data?.details === "Warning: The module domain_word_combination was selected, but no valid custom keywords are defined") {
                setError({ show: true, message: "Please select a wordlist for the Word Combination module" });
            } else {
                setError({ show: true, message: "An error has occured" });
            }

            setLoading(false);
        });
    };

    const handleWordlistSelect = (wordlist) => {
        setWordlist(wordlist)
    }

    const handleFileUpload = useCallback((domains) => {
        if (domains?.length > 0) {
            setCustomDomains(domains);
        }
    }, []);

    const handleModulesChange = useCallback((modules) => {
        setSelectedModules(modules);
    }, []);

    const handleCloseSnackbar = () => {
        setError({ ...error, show: false });
    };

    const handleSuccessCloseSnackbar = () => {
        setSuccess({ ...success, show: false });
    };

    const handleWordlistModal = () => {
        setIsModalOpen(true); // Open the modal
    };

    const handleCloseModal = (isSuccess) => {
        if (isSuccess) {
            setSuccess({ show: true, message: "Wordlist saved successfully" });
            setLoadWordlists(true)
        }

        setIsModalOpen(false); // Close the modal
    };

    return (
        <DashboardLayout>
            <Container maxWidth="100%">
                <Box py={3} className="hidden md:block">
                    <TopLayout title="New Scan" />
                </Box>

                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    <BreadcrumbSteps />
                </Paper>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : (
                    modules && (
                        <>
                            <DomainInputSection
                                onDomainSubmit={handleDomainSubmit}
                                onFileUpload={handleFileUpload}
                            />

                            <Paper
                                elevation={3}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 2,
                                    backgroundColor: "#e8f4fc",
                                    borderLeft: "5px solid #2196f3",
                                    borderRadius: 1,
                                    margin: "auto",
                                    mb: 3,
                                }}
                            >
                                <InfoOutlined sx={{ color: "#2196f3", marginRight: 1 }} />
                                <Typography variant="body1" sx={{ color: "#333" }}>
                                    The scanning process generates thousands of domain permutations and retrieves similar domains from external feeds. It sequentially verifies the presence of NS records, then checks for A and MX records, and finally analyzes open HTTP/HTTPS ports for web server activity. Active sites are evaluated using <a style={{ textDecoration: "underline" }} href='https://urlscore.ai' target='_blank' rel='noreferrer'>urlscore.ai</a> to generate risk scores based on content and activity.
                                </Typography>
                            </Paper>

                            <Paper elevation={3} sx={{ p: 3 }}>
                                <ModuleSettings
                                    modules={modules}
                                    defaultEnabledModules={defaultEnabledModules}
                                    onModulesChange={handleModulesChange}
                                    openWordlistModal={handleWordlistModal}
                                    onWordlistSelect={handleWordlistSelect}
                                    customWordLists={customWordLists}
                                />
                            </Paper>
                        </>
                    )
                )}
            </Container>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="wordlist-modal-title"
                aria-describedby="wordlist-modal-description"
            >
                <WordlistModal handleCloseModal={handleCloseModal} />
            </Modal>

            <Snackbar
                open={error.show}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {error.message}
                </Alert>
            </Snackbar>

            <Snackbar
                open={success.show}
                autoHideDuration={6000}
                onClose={handleSuccessCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleSuccessCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {success.message}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default NewScan;
