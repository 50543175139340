import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Switch,
    Button,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Divider,
    LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import { useParams } from 'react-router-dom';
import { sendTakeDown, startTakeDown } from '../../api';
import { enqueueSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { getFileFromLocalStorage, saveFileToLocalStorage } from '../../utiles/general';
import { updateAuthLetter } from '../../slices/scan-slice';

export default function TakedownModal({ open, onClose, domain, originalDomain }) {
    const [notifyRegistrar, setNotifyRegistrar] = useState(false);
    const [notifyHosting, setNotifyHosting] = useState(false);
    const [selectedBlacklists, setSelectedBlacklists] = useState([]);
    const [additionalNotes, setAdditionalNotes] = useState('');
    const [expanded, setExpanded] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isSendingTakedown, setIsSendingTakeDown] = useState(false);

    const [takedownRequested, setTakedownRequested] = useState(false);
    const [takedownRequestedResponse, setTakeDownRequestedResponse] = useState({});

    const [powerOfAttorney, setPowerOfAttorney] = useState(null);
    const { analysis_id } = useParams();
    const powerOfAttorneyKey = useSelector(state => state.scan.authLetter);


    const showDetails = takedownRequested;
    const dispatch = useDispatch();


    const handleBlacklistChange = (event) => {
        setSelectedBlacklists(event.target.value);
    };

    const handlePowerOfAttorneyUpload = async (event) => {
        const file = event.target.files[0];

        if (file) {
            try {
                setPowerOfAttorney(file);
                const fileKey = await saveFileToLocalStorage(file);
                dispatch(updateAuthLetter(fileKey));
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    };

    const handleSendTakedown = () => {

        setIsSendingTakeDown(true)
        const data = {
            additional_notes: additionalNotes
        }

        sendTakeDown(takedownRequestedResponse.uid, data).then((response) => {
            enqueueSnackbar("Takedown sent successfully", {
                variant: "success",
            });
            setIsSendingTakeDown(false)

            onClose()
        }).catch((error) => {
            if (
                error.response.status === 400
            ) {

                if (Object.keys(error.response.data).includes("signed_power")) {
                    enqueueSnackbar(error.response.data.signed_power, {
                        variant: "error",
                    });
                } else {
                    enqueueSnackbar(error.response.data.details, {
                        variant: "warning",
                    });
                }

            } else {
                enqueueSnackbar("Takedown was unable to send. An error occured.", {
                    variant: "error",
                });


            }
            setIsSendingTakeDown(false)
            onClose()
        })
    }

    const handleRequestTakedown = () => {
        console.log({
            domain,
            notifyRegistrar,
            notifyHosting,
            selectedBlacklists,
            additionalNotes,
            powerOfAttorney
        });

        setLoading(true)

        const formData = new FormData();
        formData.append("analysis_id", analysis_id);
        formData.append("domain", domain);
        formData.append("registrar", notifyRegistrar);
        formData.append("hosting", notifyHosting);
        formData.append("blacklists", selectedBlacklists);

        if (powerOfAttorney) {
            formData.append("signed_power", powerOfAttorney);
        }

        startTakeDown(formData).then((response) => {
            setTakedownRequested(true);
            setTakeDownRequestedResponse(response.data)
            setLoading(false)
        })
            .catch((error) => {
                if (
                    error.response.status === 400 &&
                    Object.keys(error.response.data).includes("signed_power")
                ) {
                    enqueueSnackbar(error.response.data.signed_power, {
                        variant: "error",
                    });
                }

                setLoading(false)
            });
    };

    const handleDownloadSample = () => {
        console.log("Downloading sample Power of Attorney file");
    };

    useEffect(() => {
        if (powerOfAttorneyKey) {
            const poaFile = getFileFromLocalStorage(powerOfAttorneyKey);
            if (poaFile) {
                setPowerOfAttorney(poaFile)
            }
        }
    }, [powerOfAttorneyKey])

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="takedown-modal-title"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: 1000,
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: 24,
                maxHeight: '90vh',
                overflow: 'auto',
                p: 3,
            }}>
                <Typography variant="h6" component="h2" gutterBottom>
                    Takedown Request
                </Typography>

                <Box sx={{ mb: 3 }}>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 150px 150px 300px 150px',
                        gap: 2,
                        alignItems: 'center',
                        mb: 2
                    }}>
                        <Typography>Domain</Typography>
                        <Typography>Notify Registrar</Typography>
                        <Typography>Notify Hosting Provider</Typography>
                        <Typography>Submit to Blacklist</Typography>
                        <Typography>Actions</Typography>
                    </Box>

                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 150px 150px 300px 150px',
                        gap: 2,
                        alignItems: 'center',
                        bgcolor: 'background.paper',
                        p: 1,
                        borderRadius: 1
                    }}>
                        <Typography>{domain}</Typography>
                        <Switch
                            checked={notifyRegistrar}
                            onChange={(e) => setNotifyRegistrar(e.target.checked)}
                        />
                        <Switch
                            checked={notifyHosting}
                            onChange={(e) => setNotifyHosting(e.target.checked)}
                        />
                        <FormControl fullWidth size="small">
                            <Select
                                multiple
                                value={selectedBlacklists}
                                onChange={handleBlacklistChange}
                                renderValue={(selected) => selected.join(', ')}
                                displayEmpty
                            >
                                <MenuItem disabled value="">
                                    <em>Optional - Select blacklists</em>
                                </MenuItem>
                                <MenuItem value="Google Safe Browsing">Google Safe Browsing</MenuItem>
                                <MenuItem value="Microsoft Security Intelligence">Microsoft Security Intelligence</MenuItem>
                                <MenuItem value="Spamhaus">Spamhaus</MenuItem>
                                <MenuItem value="OpenPhish">OpenPhish</MenuItem>
                                <MenuItem value="Netcraft">Netcraft</MenuItem>
                            </Select>
                        </FormControl>


                        <Button
                            variant="contained"
                            onClick={() => handleRequestTakedown()}
                            sx={{
                                bgcolor: '#CD6B6B',
                                '&:hover': { bgcolor: '#B25757' },
                                color: 'white',
                            }}
                        >
                            REQUEST TAKE-DOWN
                        </Button>
                    </Box>
                </Box>

                {loading &&
                    <>
                        <LinearProgress />
                        <Typography variant="body2" textAlign={"center"} mt={2}>
                            Please wait while your takedown is being processed
                        </Typography>
                    </>
                }


                {!powerOfAttorney &&
                    <>
                        <Divider sx={{ my: 3 }} />

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                            <Button
                                variant="outlined"
                                component="label"
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload Power of Attorney
                                <input
                                    type="file"
                                    hidden
                                    onChange={handlePowerOfAttorneyUpload}
                                />
                            </Button>
                            <Button
                                variant="text"
                                startIcon={<DownloadIcon />}
                                onClick={handleDownloadSample}
                            >
                                Download Sample File
                            </Button>


                        </Box>

                        {powerOfAttorney && (
                            <Typography variant="body2" sx={{ mb: 3 }}>
                                Uploaded: {powerOfAttorney.name}
                            </Typography>
                        )}
                    </>

                }

                {showDetails && (
                    <Accordion
                        expanded={expanded}
                        onChange={() => setExpanded(!expanded)}
                        sx={{
                            '&:before': { display: 'none' },
                            boxShadow: 'none',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            mt: 2
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ bgcolor: 'background.paper' }}
                        >
                            <Typography>Response Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                <Box>
                                    <Typography variant="subtitle1" gutterBottom>Domain Data</Typography>
                                    <Typography variant="body2" sx={{ mb: 1 }}>
                                        <strong>Malicious Domain:</strong> {domain}
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Original Domain:</strong> {originalDomain}
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="subtitle1" gutterBottom>Original Website</Typography>
                                        {takedownRequestedResponse.original_screenshot_link
                                            ? <Box
                                                sx={{
                                                    width: '100%',
                                                    height: 200,
                                                    bgcolor: 'rgba(0, 0, 0, 0.04)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 1,
                                                    overflow: "auto"
                                                }}
                                            >
                                                <img alt='Original Website' src={takedownRequestedResponse.original_screenshot_link} />
                                            </Box>
                                            :
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: 200,
                                                    bgcolor: 'rgba(0, 0, 0, 0.04)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 1
                                                }}
                                            >
                                                <Typography color="text.secondary">Original Website Screenshot</Typography>
                                            </Box>

                                        }


                                    </Box>
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="subtitle1" gutterBottom>Malicious Website</Typography>
                                        {takedownRequestedResponse.malicious_screenshot_link
                                            ? <Box
                                                sx={{
                                                    width: '100%',
                                                    height: 200,
                                                    bgcolor: 'rgba(0, 0, 0, 0.04)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 1,
                                                    overflow: "auto"
                                                }}
                                            >
                                                <img alt='Malicious Website' src={takedownRequestedResponse.malicious_screenshot_link} />
                                            </Box>
                                            :
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: 200,
                                                    bgcolor: 'rgba(0, 0, 0, 0.04)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 1
                                                }}
                                            >
                                                <Typography color="text.secondary">Original Website Screenshot</Typography>
                                            </Box>

                                        }

                                    </Box>
                                </Box>

                                <Box>
                                    <Typography variant="subtitle1" gutterBottom>Additional Notes</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        placeholder="Enter additional notes..."
                                        value={additionalNotes}
                                        onChange={(e) => setAdditionalNotes(e.target.value)}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, mb: 3 }}>
                                <Button
                                    variant="contained"
                                    onClick={handleSendTakedown}
                                    sx={{
                                        bgcolor: '#CD6B6B',
                                        '&:hover': { bgcolor: '#B25757' },
                                        color: 'white',
                                    }}
                                >
                                    SEND TAKEDOWN
                                </Button>
                            </Box>
                            {isSendingTakedown &&
                                <LinearProgress />
                            }

                        </AccordionDetails>
                    </Accordion>
                )}
            </Box>
        </Modal >
    );
}

