
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function GuestGuard({ children }) {
  const account = useSelector((state) => state.account);

  // Get the current URL
  const currentURL = new URL(window.location.href);

  // Get the value of the 'redirecturl' parameter
  const redirectURL = currentURL.searchParams.get('redirecturl');

  if (!!account && !!account.user) {
    if(redirectURL) {
      window.location.href = redirectURL;
    } else if(localStorage.getItem('anonymous')) {
      localStorage.removeItem('anonymous');
      window.location.href = '/dashboard/scan';
    } else {
      window.location.href =  '/dashboard/index';
    }
  } else {
    return children;
  }

}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
