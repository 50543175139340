import { Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";

import lock from "../assets/images/icons/lock-svgrepo-com.png";
import sms from "../assets/images/icons/sms.png";
import unlock from "../assets/images/icons/unlock-svgrepo-com.png";
import ShadowBlog from "../components/common/shadowBlog";
import { login } from "../actions/accountActions";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLock, setLock] = useState(false);

  const handleClick = () => {
    setLock(!isLock);
  };

  return (
    <div className="min-h-[100vh] bg-auth flex items-center justify-center">
      <div className=" w-[100vw] lg:w-[500px]">
        <ShadowBlog>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string()
                .min(7)
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                  "Password must contain at least one uppercase letter, one lowercase letter, and one number"
                )
                .max(255)
                .required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await dispatch(login(values.email, values.password));
                enqueueSnackbar("Login Success!", {
                  variant: "success",
                });
                // Get the current URL
                const currentURL = new URL(window.location.href);

                // Get the value of the 'redirecturl' parameter
                const redirectURL = currentURL.searchParams.get('redirecturl');

                if(redirectURL) {
                  navigate(redirectURL);
                } else if(localStorage.getItem('anonymous')) {
                  localStorage.removeItem('anonymous');
                  navigate('/dashboard/scan');
                } else {
                  navigate('/dashboard/index');
                }
              } catch (error) {
                const message = error.response.data.detail;
                enqueueSnackbar(message, {
                  variant: "error",
                });
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col item-center gap-4">
                  <p className="font-bold text-2xl lg:text-4xl text-center">
                    Login
                  </p>
                  <p className="font-bold text-center">Login to your Account</p>
                  <div className="relative">
                    <input
                      className={`w-full h-[40px] rounded-full border-[0.1px] border-grey pl-6 pr-[15px] ${
                        errors.email ? "border-[#D05748]" : "border-grey"
                      }`}
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={values.email}
                      placeholder="Email"
                      required
                    />
                    <img
                      src={sms}
                      className="absolute right-[12px] top-[8px]"
                      alt=""
                    />
                  </div>
                  <div className="relative">
                    <input
                      className={`w-full h-[40px] rounded-full border-[0.1px] pl-6 pr-[15px] ${
                        errors.password ? "border-[#D05748]" : "border-grey"
                      }`}
                      name="password"
                      type={!isLock ? "password": "text"}
                      onChange={handleChange}
                      value={values.password}
                      placeholder="Password"
                      required
                    />
                    {!isLock ? (
                      <img
                        src={lock}
                        className="absolute right-[12px] top-[8px]"
                        alt=""
                        onClick={handleClick}
                      />
                    ) : (
                      <img
                        src={unlock}
                        onClick={handleClick}
                        className="absolute right-[12px] top-[8px]"
                        alt=""
                      />
                    )}
                    {errors.password && (
                      <p className="text-red px-4 text-xs mt-1">
                        Password must contain at least one uppercase letter, one
                        lowercase letter, and one number
                      </p>
                    )}
                  </div>
                  <div className="flex justify-between items-center text-sm">
                    <label className="invisible">
                      <input type="checkbox" className="accent-primary" />{" "}
                      Remember me
                    </label>
                    <a
                      className="text-primary cursor-pointer"
                      href="/forgotpassword"
                    >
                      Forgot Password
                    </a>
                  </div>
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="text-sm lg:text-base bg-primary text-white max-w-[200px] px-8 h-[40px] rounded-full"
                    >
                      Login
                    </button>
                  </div>
                  <div className="flex justify-center text-sm">
                    <span>
                      Didn't have an account?{" "}
                      <a
                        href="/register"
                        className="text-primary cursor-pointer underline underline-offset-4"
                      >
                        Register now
                      </a>
                    </span>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </ShadowBlog>
      </div>
    </div>
  );
};

export default Login;
