import React, { useState } from "react";
import lock from "../assets/images/icons/lock-svgrepo-com.png";
import unlock from "../assets/images/icons/unlock-svgrepo-com.png";
import ShadowBlog from "../components/common/shadowBlog";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { resetpassword } from "../actions/accountActions";

const ResetPassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isPassword, setPassword] = useState(false);
  const [isConfirmPassword, setConfirmPassword] = useState(false);

  const handleLockPassword = () => {
    setPassword(!isPassword);
  };

  const handleLockConfirmPassword = () => {
    setConfirmPassword(!isConfirmPassword);
  };

  return (
    <div className="min-h-[100vh] bg-auth flex items-center justify-center">
      <div className="w-[100vw] lg:w-[500px]">
        <ShadowBlog>
          <Formik
            initialValues={{
              token: token,
              password: "",
              password_confirm: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(7)
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                  "Password must contain at least one uppercase letter, one lowercase letter, and one number"
                )
                .max(255)
                .required("Password is required"),
              password_confirm: Yup.string()
                .min(7)
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                  "Password must contain at least one uppercase letter, one lowercase letter, and one number"
                )
                .max(255)
                .required("Confirm Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                if (values.password !== values.password_confirm) {
                  enqueueSnackbar("Not matched Password!", {
                    variant: "error",
                  });
                } else {
                  await dispatch(resetpassword(values));
                  enqueueSnackbar("Success reset password", {
                    variant: "success",
                  });
                  window.location.href = '/login'
                }
              } catch (error) {
                const message = "Reset password Failed";
                enqueueSnackbar(message, {
                  variant: "error",
                });
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col item-center gap-4">
                  <p className="font-bold text-2xl lg:text-4xl text-center">
                    Reset Password?
                  </p>
                  <p className="text-center text-grey">
                    Please enter new password and confirm password
                  </p>
                  <div className="relative">
                    <label className="text-xs md:text-sm pb-1 pl-4">
                      New Password
                    </label>
                    <input
                      className={`w-full h-[40px] rounded-full border border-[0.1px] border-grey pl-6 pr-[15px] ${
                        errors.password ? "border-[#D05748]" : "border-grey"
                      }`}
                      name="password"
                      type={!isPassword ? "password" : "text"}
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      placeholder="Password"
                      required
                    />
                    {!isPassword ? (
                      <img
                        src={lock}
                        className="absolute right-[12px] top-[30px]"
                        alt=""
                        onClick={handleLockPassword}
                      />
                    ) : (
                      <img
                        src={unlock}
                        onClick={handleLockPassword}
                        className="absolute right-[12px] top-[30px]"
                        alt=""
                      />
                    )}
                    {errors.password && (
                      <p className="text-red px-4 text-xs mt-1">
                        Password must contain at least one uppercase letter, one
                        lowercase letter, and one number
                      </p>
                    )}
                  </div>
                  <div className="relative">
                    <label className="text-xs md:text-sm pb-1 pl-4">
                      Confirm Password
                    </label>
                    <input
                      className={`w-full h-[40px] rounded-full border border-[0.1px] border-grey pl-6 pr-[15px] ${
                        errors.password_confirm
                          ? "border-[#D05748]"
                          : "border-grey"
                      }`}
                      name="password_confirm"
                      type={!isConfirmPassword ? "password" : "text"}
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password_confirm}
                      placeholder="Confirm Password"
                      required
                    />
                    {!isConfirmPassword ? (
                      <img
                        src={lock}
                        className="absolute right-[12px] top-[30px]"
                        alt=""
                        onClick={handleLockConfirmPassword}
                      />
                    ) : (
                      <img
                        src={unlock}
                        onClick={handleLockConfirmPassword}
                        className="absolute right-[12px] top-[30px]"
                        alt=""
                      />
                    )}
                    {errors.password_confirm && (
                      <p className="text-red px-4 text-xs mt-1">
                        Password must be matched!
                      </p>
                    )}
                  </div>
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="text-sm lg:text-base bg-primary text-white max-w-[200px] px-8 h-[40px] rounded-full"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </ShadowBlog>
      </div>
    </div>
  );
};

export default ResetPassword;
