// ScanResults.js
import React from "react";
import { CustomProgressBar, IncreasingNumber } from "../../utiles/scanUtils";
import { RotatingLines } from "react-loader-spinner";

const ScanResults = ({
  scanResult,
  isLoading1,
  isLoading2,
  isLoading3,
  isBack,
  isPreviousScan,
  handleAnalyzeAll,
}) => {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4">
        <div className="col-span-1 lg:col-span-1 pt-4 md:pt-8 rounded-xl w-full shadow-xl overflow-x-scroll">
          <table className="text-sm md:text-base rounded-xl shadow-xl hover:shadow-2xl table-fixed overflow-hidden w-max xl:w-[-webkit-fill-available]">
            <thead className="text-start h-[60px] rounded-xl bg-light">
              <tr>
                <th className="text-start px-8 w-[300px]">Progress</th>
                <th className="text-start px-8 w-[100px]">Results</th>
                <th className="text-start px-8">Module</th>
              </tr>
            </thead>
            <tbody>
              {scanResult &&
                scanResult.variations.length > 0 &&
                scanResult.variations
                  .slice(0, parseInt(scanResult.variations.length / 2))
                  .map((item, index) => (
                    <tr
                      key={index}
                      className="odd:bg-white even:bg-slate-50 h-[60px] text-grey"
                    >
                      <td className="px-8">
                        <div className="flex flex-col gap-6 w-[250px]">
                          <CustomProgressBar
                            value={item.count}
                            active={isPreviousScan}
                          />
                        </div>
                      </td>
                      <td className="px-8">
                        <div className="flex flex-col gap-3">
                          <IncreasingNumber
                            value={item.count}
                            active={isPreviousScan}
                          />
                        </div>
                      </td>
                      <td className="px-8">
                        <div className="flex flex-col gap-3">
                          <p>{item.module_name}</p>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        <div className="col-span-1 lg:col-span-1 pt-4 md:pt-8 rounded-xl w-full shadow-xl overflow-x-scroll">
          <table className="text-sm md:text-base rounded-xl shadow-xl hover:shadow-2xl table-fixed overflow-hidden w-max xl:w-[-webkit-fill-available]">
            <thead className="text-start h-[60px] rounded-xl bg-light">
              <tr>
                <th className="text-start px-8 w-[300px]">Progress</th>
                <th className="text-start px-8 w-[100px]">Results</th>
                <th className="text-start px-8">Module</th>
              </tr>
            </thead>
            <tbody>
              {scanResult &&
                scanResult.variations.length > 0 &&
                scanResult.variations
                  .slice(
                    parseInt(scanResult.variations.length / 2),
                    scanResult.variations.length
                  )
                  .map((item, index) => (
                    <tr
                      key={index}
                      className="odd:bg-white even:bg-slate-50 h-[60px] text-grey"
                    >
                      <td className="px-8">
                        <div className="flex flex-col gap-6 w-[250px]">
                          <CustomProgressBar
                            value={item.count}
                            active={isPreviousScan}
                          />
                        </div>
                      </td>
                      <td className="px-8">
                        <div className="flex flex-col gap-3">
                          <IncreasingNumber
                            value={item.count}
                            active={isPreviousScan}
                          />
                        </div>
                      </td>
                      <td className="px-8">
                        <div className="flex flex-col gap-3">
                          <p>{item.module_name}</p>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-between items-center pt-4 md:pt-8">
        {!isLoading3 && isLoading2 && !isLoading1 && scanResult?.length > 0 ? (
          <></>
        ) : (
          <>
            {!isLoading2 && !isBack && (
              <div className={`flex gap-2`}>
                <RotatingLines
                  visible={true}
                  height="48"
                  width="48"
                  color="grey"
                  strokeWidth="3"
                  animationDuration="10"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
                <div className="flex flex-col justify-between">
                  <p className="font-bold">Please wait...</p>
                  <p className="text-grey">
                    We are collecting the results. The scan can take up 15
                    minutes. Please click next once the scan has finished
                  </p>
                </div>
              </div>
            )}
            {isLoading2 && !isBack && (
              <div>
                <span>
                  <span className="font-bold text-sm md:text-base pr-2">
                    Info:{" "}
                  </span>
                  <span className="text-sm md:text-base text-grey">
                    The scan finished, Please click next or wait 10 seconds.
                  </span>
                </span>
              </div>
            )}
            {!isLoading2 && isBack && (
              <div>
                <span>
                  <span className="font-bold text-sm md:text-base pr-2">
                    Info:{" "}
                  </span>
                  <span className="text-sm md:text-base text-grey">
                    You have pre-scanned variations, Please click next to
                    analyze it.
                  </span>
                </span>
              </div>
            )}
            <div>
              {!isLoading2 && !isBack ? (
                <button
                  disabled
                  className="before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-grey/70 cursor-not-allowed text-white"
                >
                  <span className=" text-sm md:text-base relative z-10">
                    Next step
                  </span>
                </button>
              ) : (
                <button
                  onClick={handleAnalyzeAll}
                  className="cursor-pointer before:ease relative h-[36px] md:h-[40px] max-w-[200px] px-4 md:px-8 rounded-full overflow-hidden bg-primary text-white shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-1000 hover:shadow-primary hover:before:-translate-x-40"
                >
                  <span className=" text-sm md:text-base relative z-10">
                    Next step
                  </span>
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ScanResults;
