import React, { useState, useMemo, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Switch,
    FormControlLabel,
    Button,
    Grid,
    IconButton,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
    LinearProgress,
    Paper,
    Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Papa from 'papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { updateAuthLetter, updateBrand, updateBrandLogo } from '../../slices/scan-slice';
import { saveFileToLocalStorage } from '../../utiles/general';
import { getWordlists, saveWordList, startAnalysis } from '../../api';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { InfoOutlined, PlusOneSharp, UploadFile } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 900,
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};


const NewScanModal = ({ open, onClose }) => {
    const [enableAll, setEnableAll] = useState(false);
    const [moduleStates, setModuleStates] = useState({});
    const [selectedWordlist, setSelectedWordlist] = useState('');
    const [expandedGroup, setExpandedGroup] = useState('');
    const [wordlistEnabled, setWordlistEnabled] = useState(false);
    const [groupedModules, setGroupedModules] = useState([]);

    const [parsedWords, setParsedWords] = useState([]);
    const [wordlists, setWordlists] = useState([]);
    const [newWordlist, setNewWordlist] = useState(false);
    const [newWordlistFile, setNewWordlistFile] = useState(null);
    const [newWordlistName, setNewWordlistName] = useState("");
    const [wordlistsError, setWordlistsError] = useState(false);
    const [wordlistLoading, setWordlistLoading] = useState(false);

    const [manualWordlist, setManualWordlist] = useState("");

    const [domainError, setDomainError] = useState(false);

    const [loading, setLoading] = useState(false);

    const [brand, setBrand] = useState("");
    const [brandLogo, setBrandLogo] = useState(null);
    const [authLetterName, setAuthLetterName] = useState("");

    const [domain, setDomain] = useState("");
    const [customDomains, setCustomDomains] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const modules = useSelector(state => state?.scan?.config?.modules);
    const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

    const categories = useMemo(() => [
        {
            "title": "Domain Structure Modifications",
            "modules": [
                "module_tld",
                "module_append_tld",
                "module_tldvariation_addon_tld",
                "module_prepend",
                "module_prepend_number",
                "module_append_numbers_letters",
                "module_subdomain_add",
                "module_subdomain_letter_hyphen",
                "module_hyphenated_domains"
            ]
        },
        {
            "title": "Typographical Manipulations",
            "modules": [
                "module_letter_swap",
                "module_levenshtein_typos",
                "module_omission",
                "module_vowel_swap",
                "module_double_characters",
                "module_misspellings",
                "module_replacement_letters"
            ]
        },
        {
            "title": "Look-alike Techniques",
            "modules": [
                "module_homograph_word_variations",
                "module_punycode_variations",
                "module_bit_squatting"
            ]
        },
        {
            "title": "Number-Based Variations",
            "modules": [
                "module_prepend_numbers",
                "module_add_common_numbers",
                "module_numeral_swap"
            ]
        },
        {
            "title": "Word-Based Modifications",
            "modules": [
                "module_common_word",
                "module_hyphen"
            ]
        },
        {
            "title": "Detection and Monitoring Modules",
            "modules": [
                "module_kaduu_new_domain_registrations",
                "module_api_domain_search",
                "module_api_domain_search_ssl",
                "module_domain_in_directory",
                "module_dyndns",
                "wrong_sld",
                "module_missing_dashes"
            ]
        }
    ], []);

    useEffect(() => {
        if (modules) {
            const filtered = modules?.map((module) => (
                {
                    code: module.code,
                    name: module.name,
                    description: module.description
                }
            ))

            const grouped = groupModules(categories, filtered);

            setGroupedModules(grouped)
            setModuleStates(Object.fromEntries(grouped.flatMap(group => group.modules).map(m => [m.code, false])))
        }
    }, [modules, categories]);

    useEffect(() => {
        if (!wordlists.length) {
            getWordlists().then((response) => {
                setWordlists(response?.data);
            }).catch(() => {
                enqueueSnackbar("Unable to load wordlists", {
                    variant: "error",
                });
            })
        }
    }, [enqueueSnackbar, wordlists]);


    const groupModules = (categories, filteredmodules) => {
        return categories.map((category) => ({
            title: category.title,
            modules: category.modules
                .map((moduleCode) =>
                    filteredmodules.find((module) => module.code === moduleCode)
                )
                .filter(Boolean),
        }));
    };


    const handleEnableAll = () => {
        setEnableAll(!enableAll);
        setModuleStates(
            Object.fromEntries(groupedModules?.flatMap(group => group.modules).map(m => [m.code, !enableAll]))
        );
    };

    const handleModuleChange = (moduleName) => {
        setModuleStates(prev => ({
            ...prev,
            [moduleName]: !prev[moduleName]
        }));
    };

    const handleGroupEnableAll = (groupModules) => {
        const allEnabled = groupModules.every(m => moduleStates[m.code]);
        setModuleStates(prev => ({
            ...prev,
            ...Object.fromEntries(groupModules.map(m => [m.code, !allEnabled]))
        }));
    };

    const handleWordlistChange = (event) => {
        setSelectedWordlist(event.target.value);
    };

    const handleAccordionChange = (panel, groupModules) => (event, isExpanded) => {
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'LABEL') {
            return;
        }
        setExpandedGroup(isExpanded ? panel : '');
    };

    const handleWordlistToggle = () => {
        setWordlistEnabled(!wordlistEnabled);
        if (!wordlistEnabled) {
            setSelectedWordlist('');
            setNewWordlist(false)
        }
    };

    const handleBrandName = (brand) => {
        setBrand(brand);
    }

    const handleLogoUpload = async (e) => {
        const uploadedFile = e.target.files[0];
        setBrandLogo(uploadedFile);
    };

    const getEnabledModulesCount = (groupModules) => {
        return groupModules.filter(m => moduleStates[m.code]).length;
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            enqueueSnackbar("No file selected", {
                variant: "error",
            });
            return;
        }

        console.log(file)

        // Check if the file is either .csv or .txt
        const isCSV = file.type === "text/csv";
        const isTXT = file.type === "text/plain";

        if (!isCSV && !isTXT) {
            enqueueSnackbar("File type not supported. Please upload a .txt or .csv file.", {
                variant: "error",
            });
            return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            const fileContent = e.target.result;

            if (isTXT) {
                const words = fileContent.split("\n").map(line => line.trim()).filter(Boolean);
                setNewWordlistFile(file.name);
                setParsedWords(words);
                console.log(words)
            } else if (isCSV) {
                Papa.parse(fileContent, {
                    header: false,
                    skipEmptyLines: true,
                    complete: (results) => {
                        const words = results?.data?.flat().filter(Boolean);
                        setNewWordlistFile(file.name);
                        setParsedWords(words);
                        console.log(words)

                    },
                    error: (error) => {
                        console.error("Error parsing the CSV file:", error);
                        enqueueSnackbar("Error parsing the file", {
                            variant: "error",
                        });
                    },
                });
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading the file:", error);
            enqueueSnackbar("Error reading the file", {
                variant: "error",
            });
        };

        // Read as text for both .txt and .csv files
        reader.readAsText(file);
    };


    const handleAuthLetterUpload = async (event) => {
        const file = event.target.files[0];

        if (file) {
            try {
                setAuthLetterName(file.name)
                const fileKey = await saveFileToLocalStorage(file);
                dispatch(updateAuthLetter(fileKey));
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    };

    const handleDomainSubmit = async () => {
        if (!domainRegex.test(domain)) {
            setDomainError(true)
            enqueueSnackbar("Please enter a valid domain (e.g., example.com)", {
                variant: "error",
            });
            return false;
        } else {
            setDomainError(false)
        }

        if (wordlistEnabled && selectedWordlist === '') {
            setWordlistsError(true)
            enqueueSnackbar("Please select or upload a wordlist", {
                variant: "error",
            });
            return false;
        } else {
            setWordlistsError(false)
        }

        if (brandLogo) {
            try {
                const fileKey = await saveFileToLocalStorage(brandLogo);
                dispatch(updateBrandLogo(fileKey));
            } catch (error) {
                console.error("Error uploading file:", error);
            }

            dispatch(updateBrand(brand))
        }

        //Transform moduleStates object into array
        const selectedModules = Object.entries(moduleStates)
            .filter(([key, value]) => value)
            .map(([key, value]) => ({ module_name: key, enabled: value }));


        if (selectedModules.length === 0) {
            enqueueSnackbar("Please select some modules to start your scan", {
                variant: "error",
            });
            return false;
        }

        if (wordlistEnabled) {
            selectedModules.push({
                module_name: "module_domain_word_combination",
                enabled: true,
                wordlist: selectedWordlist
            })
        }

        const data = {
            config: {},
            custom_domains: customDomains,
            domain: domain,
            keywords: [],
            modules: selectedModules
        };

        setLoading(true);

        startAnalysis(data).then((response) => {
            if (response?.status === 200 && response?.data) {
                navigate(`/dashboard/domain-analysis/${response?.data?.analysis_id}`, { state: { new_scan: true, poll: true, data: response?.data } });
            }
        }).catch((error) => {
            if (error?.response?.status === 400 && error?.response?.data?.details === "Warning: The module domain_word_combination was selected, but no valid custom keywords are defined") {
                enqueueSnackbar("Please select a wordlist for the Word Combination module", {
                    variant: "error",
                });
            } else {
                enqueueSnackbar("An error has occured", {
                    variant: "error",
                });
            }

            setLoading(false);
        });
    };

    const handleSaveWordList = () => {
        if (!newWordlistName) {
            enqueueSnackbar("Please enter a name for your wordlist", {
                variant: "error",
            });
            return;
        }

        if (parsedWords.length === 0) {
            enqueueSnackbar("Please upload a wordlist file", {
                variant: "error",
            });
            return;
        }

        if (manualWordlist.length > 0) {
            setParsedWords((prevWords) => [...prevWords, ...manualWordlist.split(", ")]);
        }

        const wordlist = {
            name: newWordlistName,
            code: newWordlistName.replace(/\s+/g, '_'),
            words: parsedWords,
        };

        saveWordList(wordlist).then((response) => {
            const data = response?.data;
            if (data?.existing_wordlist_code) {
                enqueueSnackbar(`A wordlist containing these words already exists. Please use ${data?.existing_wordlist_code}`, {
                    variant: "error",
                });
            } else {
                setNewWordlist(false);
                setWordlists([])
                enqueueSnackbar("Your wordlist has been created.", {
                    variant: "success",
                });
            }

        }).catch((error) => {

        });
    };

    const tooltipProps = {
        slotProps: {
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -14],
                        },
                    },
                ],
            },
        },
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="new-scan-modal-title"
        >
            <Box sx={style}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <Typography id="new-scan-modal-title" variant="h6" component="h2">
                        New Scan
                    </Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip
                                        title="Enter your company's base domain (e.g., domain.com) to scan for active typosquatting. Avoid including subdomains like www.domain.com or sub.domain.com."
                                        {...tooltipProps}
                                    >
                                        <IconButton size="small" sx={{ mr: 1 }}>
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <TextField
                                        fullWidth
                                        label="Domain"
                                        variant="outlined"
                                        onChange={(e) => setDomain(e.target.value)}
                                        error={domainError}
                                    />
                                </Box>

                            </Grid>
                            <Grid item xs={4}>
                                <Tooltip title="Upload a list of custom domains to scan" arrow>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        startIcon={<CloudUploadIcon />}
                                        sx={{ height: '56px', width: '100%' }}
                                    >
                                        Upload Custom Domains
                                        <input type="file" hidden accept=".csv,.txt" onChange={handleFileUpload} />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip
                                        title="Enter your company name to scan for brand misuse on malicious websites"
                                        {...tooltipProps}
                                    >
                                        <IconButton size="small" sx={{ mr: 1 }}>
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <TextField
                                        fullWidth
                                        label="Brand"
                                        variant="outlined"
                                        onChange={(e) => handleBrandName(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Tooltip title="Upload a company logo to verify if used on malicious websites" {...tooltipProps}>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        startIcon={<CloudUploadIcon />}
                                        sx={{ height: '56px', width: '100%' }}
                                    >
                                        Upload logo
                                        <input type="file" hidden accept="image/*" onChange={handleLogoUpload} />
                                    </Button>
                                </Tooltip>
                                {brandLogo ? brandLogo.name : ""}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <Divider></Divider>
                        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={wordlistEnabled}
                                            onChange={handleWordlistToggle}
                                            name="wordlist-toggle"
                                        />
                                    }
                                    label="Enable Wordlist"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip
                                        title="Select a predefined wordlist or upload a custom one"
                                        {...tooltipProps}
                                    >
                                        <IconButton size="small" sx={{ mr: 1 }}>
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <FormControl fullWidth disabled={!wordlistEnabled} error={wordlistsError}>
                                        <InputLabel id="wordlist-select-label">Wordlist</InputLabel>
                                        <Select
                                            labelId="wordlist-select-label"
                                            id="wordlist-select"
                                            value={selectedWordlist}
                                            onChange={handleWordlistChange}
                                            label="Wordlist"
                                            status="error"
                                        >
                                            {wordlists?.map((wordlist) => (
                                                <MenuItem key={wordlist.code} value={wordlist.code}>
                                                    {wordlist.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Tooltip title="Upload .csv or .txt files. Each row should list one word (e.g., word1, word2). Download sample file" arrow>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        startIcon={<PlusOneSharp />}
                                        disabled={!wordlistEnabled}
                                        sx={{ height: '56px', width: '100%' }}
                                        onClick={() => setNewWordlist(true)}
                                    >
                                        Create new wordlist
                                    </Button>
                                </Tooltip>
                            </Grid>
                            {newWordlist && wordlistEnabled &&
                                <Grid item xs={12}>
                                    <Paper sx={{ p: 3, mb: 3 }}>
                                        <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                                            Create new wordlist
                                        </Typography>

                                        <TextField
                                            label="Wordlist Name"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            value={newWordlistName}
                                            onChange={(e) => setNewWordlistName(e.target.value)}
                                            sx={{ mb: 2 }}
                                        />

                                        <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
                                            Option 1: Enter manually (Comma separated)
                                        </Typography>
                                        <TextField
                                            label="Keyword1, Keyword2, Keyword3..."
                                            variant="outlined"
                                            fullWidth
                                            required
                                            value={manualWordlist}
                                            onChange={(e) => setManualWordlist(e.target.value)}
                                            sx={{ mb: 2 }}
                                        />

                                        <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
                                            Option 2: Upload wordlist
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            fullWidth
                                            startIcon={<UploadFile />}
                                            sx={{ mb: 2 }}
                                        >
                                            Upload (CSV OR TXT FILE)
                                            <input
                                                type="file"
                                                accept=".csv, .txt"
                                                hidden
                                                onChange={handleFileUpload}
                                            />
                                        </Button>


                                        {newWordlistFile && (
                                            <Typography variant="body2" color="success" sx={{ mb: 2 }}>
                                                {newWordlistFile} uploaded
                                            </Typography>
                                        )}

                                        <Grid item xs={12}>
                                            {loading &&
                                                <LinearProgress />
                                            }

                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSaveWordList()}
                                                    sx={{ mr: 1 }}
                                                    disabled={loading}
                                                >
                                                    Save Wordlist
                                                </Button>
                                            </Box>
                                        </Grid>

                                    </Paper>
                                </Grid>
                            }
                        </Grid>

                    </Grid>

                    <Grid item xs={12}>
                        <Divider></Divider>

                        <Grid container spacing={2} sx={{ mt: 2 }} alignItems="center">
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip
                                        title="Upload a signed Power of Attorney document that authorizes SpoofGuard to send takedown requests on behalf your organization"
                                        {...tooltipProps}
                                    >
                                        <IconButton size="small" sx={{ mr: 1 }}>
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        startIcon={<CloudUploadIcon />}
                                        sx={{ height: '56px', width: '100%' }}
                                    >
                                        Upload power of attorney
                                        <input type="file" hidden accept=".odt,.pdf,.doc,.docx" onChange={(e) => handleAuthLetterUpload(e)} />
                                    </Button>

                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Tooltip title="Download a sample Power of Attorney document" arrow>
                                    <Typography
                                        variant="body2"
                                        color="primary"
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                        }}
                                    >
                                        Download sample file
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            {authLetterName &&
                                <Grid>
                                    <Typography
                                        variant="body2"
                                        mt={2}
                                        ml={4}
                                    >
                                        {authLetterName}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                        <Box display="flex" alignItems="center">
                            <Tooltip title="SpoofGuard's core feature is its domain permutation modules, designed to detect typosquatting threats. Select modules below to process your domain and wordlist, generating typosquatting variations based on your preferred scan range." arrow>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6" gutterBottom sx={{ m: 0, alignItems: "center" }}>
                                        <InfoIcon fontSize="small" color="action" sx={{ mr: 2 }} />
                                        Module Settings
                                    </Typography>
                                </Box>
                            </Tooltip>
                        </Box>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={enableAll}
                                    onChange={handleEnableAll}
                                />
                            }
                            label="Enable All"
                            sx={{ mb: 2 }}
                        />

                        {groupedModules?.map((group, index) => (
                            <Accordion
                                key={index}
                                expanded={expandedGroup === `panel${index}`}
                                onChange={handleAccordionChange(`panel${index}`, group.modules)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}bh-content`}
                                    id={`panel${index}bh-header`}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                            <Switch
                                                size="small"
                                                checked={group.modules.every(m => moduleStates[m.code])}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    handleGroupEnableAll(group.modules);
                                                }}
                                                sx={{ mr: 1 }}
                                            />
                                            <Typography>{group.title}</Typography>
                                        </Box>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            {getEnabledModulesCount(group.modules)}/{group.modules.length} enabled
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {group.modules.map((module) => (
                                            <Grid item xs={12} sm={6} md={4} key={module.name}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={moduleStates[module.code]}
                                                                onChange={() => handleModuleChange(module.code)}
                                                            />
                                                        }
                                                        label={module.name}
                                                    />
                                                    <Tooltip title={module.description}>
                                                        <IconButton size="small">
                                                            <InfoIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>

                    <Grid item xs={12}>
                        {loading &&
                            <LinearProgress />
                        }

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleDomainSubmit()}
                                sx={{ mr: 1 }}
                                disabled={loading}
                            >
                                Start scan
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default NewScanModal;
