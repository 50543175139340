import React from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { Box, Typography, Stack } from '@mui/material';

const defaultConfig = [
    { routes: ['/dashboard/scan', '/dashboard/new-scan'], title: 'Scan' },
    { routes: ['/dashboard/analyze-results/:analysis_id'], title: 'Analyze Results' },
    { routes: ['/dashboard/domain-investigation/:analysis_id'], title: 'Detect Spoofing' },
    { routes: ['/dashboard/setup-monitoring/:analysis_id'], title: 'Setup Monitoring' },
    { routes: ['/dashboard/takedown-domains/:analysis_id'], title: 'Take-Down Domain' },
];

const BreadcrumbSteps = () => {
    const location = useLocation();
    const { analysis_id } = useParams(); 

    const activeStep = defaultConfig.findIndex(step =>
        step.routes.some(route => location.pathname.includes(route.split('/:')[0]))
    );

    return (
        <Box display="flex" justifyContent="center" flexWrap="wrap" mt={3} mb={3}>
            {defaultConfig.map((step, index) => {
                const isActive = index === activeStep;
                const isCompleted = index < activeStep;

                return (
                    <Box key={step.title} display="flex" alignItems="center">
                        <Stack alignItems="center" spacing={1}>
                            <Box
                                component={isCompleted ? Link : 'div'}
                                to={
                                    isCompleted
                                        ? {
                                            pathname: step.routes[0].replace(':analysis_id', analysis_id), 
                                            state: { uid: analysis_id }, 
                                        }
                                        : undefined
                                }
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: { xs: 30, sm: 40 }, 
                                    height: { xs: 30, sm: 40 }, 
                                    borderRadius: '50%',
                                    backgroundColor: isActive ? 'primary.main' : isCompleted ? 'success.main' : 'grey.300',
                                    color: isActive ? 'white' : 'text.secondary',
                                    textDecoration: 'none',
                                    fontWeight: 'bold',
                                    cursor: isCompleted ? 'pointer' : 'default',
                                }}
                            >
                                {index + 1}
                            </Box>

                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: isActive ? 'bold' : 'normal',
                                    color: isActive ? 'primary.main' : 'text.secondary',
                                    textAlign: 'center',
                                    fontSize: { xs: '0.75rem', sm: '1rem' },
                                }}
                            >
                                {step.title}
                            </Typography>
                        </Stack>

                        {index < defaultConfig.length - 1 && (
                            <Box
                                sx={{
                                    width: { xs: 20, sm: 40 },
                                    height: 2,
                                    backgroundColor: isCompleted ? 'success.main' : 'grey.400',
                                    mx: { xs: 1, sm: 2 },
                                }}
                            />
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default BreadcrumbSteps;
