import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function AuthGuard({ children }) {
  const account = useSelector((state) => state.account);

  if (!!account && !!account.user) {
    return children;
  } else {
    window.location.href = `/login?redirecturl=${window.location.pathname}`;
    return;
  }

}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
