import React from "react";

const BlogCard = (props) => {
    return(
        <div className="shadow-xl rounded-xl">
            <img src={props.image} className="w-full" alt="" />
            <div className="py-6 relative">
                <div className="px-4">
                    <p className="text-grey">{props.date}</p>
                    <p className="font-bold text-xl py-2">{props.title}</p>
                    <p className="text-grey pb-2">{props.description}</p>
                    <p className="text-primary font-semibold">Read More</p>
                </div>
                <div className="w-full absolute top-[-30px]">
                    <div className="text-center flex justify-center items-center"><p className="px-4 h-[30px] flex items-center text-sm font-bold rounded-t-xl bg-white corner">Admin</p></div>
                </div>
            </div>
        </div>
    );
}

export default BlogCard;