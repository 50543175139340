import React, { useEffect, useState } from "react";
import { plansData } from "../../../data/pricingData";
import PlanCard from "../../../components/common/planCard";
import axios from "axios";
import { useStripeCheckout } from "../../../hooks/useCheckout";

const Subscription = () => {
  const [subscriptionData, setSubscriptionData] = useState({});

  const { handleSubmit } = useStripeCheckout();

  const getSubscription = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/memberships/`
      );
      setSubscriptionData(response.data.current);
    } catch (error) {}
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <div className="flex items-center justify-between gap-3">
      {plansData.map((plan, index) => (
        <PlanCard
          key={index}
          userHasPlan={subscriptionData && subscriptionData.is_active}
          item={plan}
          subscriptionData={subscriptionData}
          onSelect={() => handleSubmit(plan)}
        />
      ))}
    </div>
  );
};

export default Subscription;
