import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import sms from "../assets/images/icons/sms.png";
import ShadowBlog from "../components/common/shadowBlog";
import { forgotpassword } from "../actions/accountActions";

const Forgotpassword = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className="min-h-[100vh] bg-auth flex items-center justify-center">
      <div className="w-[100vw] lg:w-[500px]">
        <ShadowBlog>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await dispatch(forgotpassword(values.email));
                enqueueSnackbar("Email Sent!", {
                  variant: "success",
                });
                // window.location.href = "/";
              } catch (error) {
                const message = "This account is not exist!";
                enqueueSnackbar(message, {
                  variant: "error",
                });
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col item-center gap-4">
                  <p className="font-bold text-2xl lg:text-4xl text-center">
                    Forgot Password?
                  </p>
                  <p className="text-center text-grey text-sm md:text-base">
                    Don’t worry enter the email address associated with your
                    account and we’ll send you a link to reset password
                  </p>
                  <div className="relative">
                    <input
                      className={`w-full h-[40px] rounded-full border-[0.1px] border-grey pl-6 pr-[15px] ${
                        errors.email ? "border-[#D05748]" : "border-grey"
                      }`}
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={values.email}
                      placeholder="Email"
                      required
                    />
                    <img
                      src={sms}
                      className="absolute right-[12px] top-[8px]"
                      alt=""
                    />
                  </div>
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="text-sm lg:text-base bg-primary text-white max-w-[200px] px-8 h-[40px] rounded-full"
                    >
                      Continue
                    </button>
                  </div>
                  <div className="flex justify-center text-sm text-grey">
                    <span>
                      Can't reset your password?{" "}
                      <a
                        href="/register"
                        className="text-primary cursor-pointer underline underline-offset-4"
                      >
                        Let us know
                      </a>{" "}
                      and we'll help!
                    </span>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </ShadowBlog>
      </div>
    </div>
  );
};

export default Forgotpassword;
