import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  TablePagination,
  Box,
  Typography,
  IconButton,
  Divider,
  TextField,
  MenuItem,
  Select,
  Accordion,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import { ChevronDown } from "lucide-react";
import { useSelector } from "react-redux";
import { PageviewRounded, PreviewRounded, RemoveRedEyeOutlined } from "@mui/icons-material";

const AnalyzeResultsTable = ({ selectedDomains, dnsLookupsFormatted, onSelectedRowsChange, selectedModules = [] }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [expandedRow, setExpandedRow] = useState(null);
  const modules = useSelector((state) => state.scan.config.modules);

  const [filters, setFilters] = useState({
    domain: "",
    mxRecord: "",
    httpHttps: "", // Replace httpOpen and httpsOpen with combined httpHttps filter
    module: "",
    aRecord: "",
  });


  const handleExpandRow = (domain) => {
    setExpandedRow(expandedRow === domain ? null : domain);
  };

  const handleSelectRow = (item) => {
    const isSelected = selectedRows.find((selected) => selected.domain === item.domain);
    const newSelectedRows = isSelected
      ? selectedRows.filter((selected) => selected.domain !== item.domain)
      : [...selectedRows, item];

    setSelectedRows(newSelectedRows);
    onSelectedRowsChange(newSelectedRows);
  };

  const handleSelectAll = (e) => {
    const isSelectedAll = e.target.checked;

    // Select or deselect only the currently filtered rows
    const filteredDomains = sortedAndFilteredRows.map((row) => row.domain);
    const newSelectedRows = isSelectedAll
      ? [
        ...selectedRows.filter((row) => !filteredDomains.includes(row.domain)), // Retain unfiltered rows
        ...sortedAndFilteredRows, // Add filtered rows
      ]
      : selectedRows.filter((row) => !filteredDomains.includes(row.domain)); // Remove filtered rows

    setSelectedRows(newSelectedRows);
    onSelectedRowsChange(newSelectedRows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const sortedAndFilteredRows = [...dnsLookupsFormatted]
    .filter((lookup) => {
      const { domain, module, mxRecord, httpHttps } = filters;

      const matchesDomain = domain
        ? lookup.domain.toLowerCase().includes(domain.toLowerCase())
        : true;

      const matchesModule = module ? lookup.module_name === module : true;

      const matchesMXRecord =
        mxRecord === "true"
          ? !!lookup.mx_record
          : mxRecord === "false"
            ? !lookup.mx_record
            : true;

      // Filtering logic for HTTP/HTTPS combined column
      const matchesHttpHttps =
        httpHttps === "HTTP"
          ? lookup.http_open && !lookup.https_open
          : httpHttps === "HTTPS"
            ? !lookup.http_open && lookup.https_open
            : httpHttps === "HTTP/HTTPS"
              ? lookup.http_open && lookup.https_open
              : httpHttps === "N/A"
                ? !lookup.http_open && !lookup.https_open
                : true;

      return matchesDomain && matchesModule && matchesMXRecord && matchesHttpHttps;
    })
    .sort((a, b) => {
      // Prioritize https_open, then http_open, then mx_record
      if (b.https_open !== a.https_open) {
        return b.https_open - a.https_open; // HTTPS gets highest priority
      }
      if (b.http_open !== a.http_open) {
        return b.http_open - a.http_open; // HTTP gets second priority
      }
      if (!!b.mx_record !== !!a.mx_record) {
        return (!!b.mx_record ? 1 : 0) - (!!a.mx_record ? 1 : 0); // MX gets third priority
      }
      return 0; // Keep other order intact
    });

  const currentRows = sortedAndFilteredRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const isRowSelected = (row) => selectedRows.some((selected) => selected.domain === row.domain);

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={
                    sortedAndFilteredRows.every((row) =>
                      selectedRows.some((selected) => selected.domain === row.domain)
                    ) && sortedAndFilteredRows.length > 0
                  }
                  indeterminate={
                    sortedAndFilteredRows.some((row) =>
                      selectedRows.some((selected) => selected.domain === row.domain)
                    ) &&
                    !sortedAndFilteredRows.every((row) =>
                      selectedRows.some((selected) => selected.domain === row.domain)
                    )
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Domain</TableCell>
              <TableCell>Module</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>MX</TableCell>
              <TableCell>Web</TableCell>
              <TableCell>View Results</TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>
                <TextField
                  variant="standard"
                  placeholder="Filter by Domain"
                  value={filters.domain}
                  onChange={(e) => handleFilterChange("domain", e.target.value)}
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <Select
                  variant="standard"
                  value={filters.module}
                  onChange={(e) => handleFilterChange("module", e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">All</MenuItem>
                  {selectedModules.map((module) => (
                    <MenuItem key={module.module_name} value={module.module_name}>
                      {modules?.filter((x) => x.code === module.module_name)[0].name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell />
              <TableCell>
                <Select
                  variant="standard"
                  value={filters.mxRecord}
                  onChange={(e) => handleFilterChange("mxRecord", e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </TableCell>

              <TableCell>
                <Select
                  variant="standard"
                  value={filters.httpHttps}
                  onChange={(e) => handleFilterChange("httpHttps", e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="HTTP">HTTP</MenuItem>
                  <MenuItem value="HTTPS">HTTPS</MenuItem>
                  <MenuItem value="HTTP/HTTPS">HTTP/HTTPS</MenuItem>
                  <MenuItem value="N/A">N/A</MenuItem>
                </Select>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows.map((lookup) => (
              <React.Fragment key={lookup.domain}>
                <TableRow
                  hover
                  onClick={(e) => {
                    if (e.target.type !== "checkbox" && e.target.tagName !== "A") {
                      handleExpandRow(lookup.domain);
                    }
                  }}
                  selected={expandedRow === lookup.domain}
                  sx={{
                    backgroundColor: expandedRow === lookup.domain ? "#f0f8ff" : "inherit",
                    cursor: "pointer",
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isRowSelected(lookup)}
                      onChange={() => handleSelectRow(lookup)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleExpandRow(lookup.domain)}>
                      <ChevronDown />
                    </IconButton>
                    <a
                      href={`https://${lookup.domain}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#1976d2", textDecoration: "underline" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {lookup.domain}
                    </a>
                  </TableCell>
                  <TableCell>{modules?.filter((x) => x.code === lookup.module_name)[0].name}</TableCell>
                  <TableCell>{lookup.a_record}</TableCell>
                  <TableCell>{lookup.mx_record ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    {lookup.http_open && lookup.https_open
                      ? "HTTP/HTTPS"
                      : lookup.http_open
                        ? "HTTP"
                        : lookup.https_open
                          ? "HTTPS"
                          : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={"View Results"}>
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                        <RemoveRedEyeOutlined />
                      </Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                {expandedRow === lookup.domain && (
                  <TableRow>
                    <TableCell colSpan={7} sx={{ backgroundColor: "#f0f8ff" }}>
                      <Accordion expanded>
                        <AccordionDetails>
                          <Box sx={{ display: "flex", gap: 4 }}>
                            <Box sx={{ width: "40%", maxHeight: "30vh", minHeight: "30vh", overflow: "scroll" }}>
                              {lookup.screenshot ? (
                                <img
                                  src={lookup.screenshot}
                                  alt="Domain Screenshot"
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    borderRadius: 4,
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                  }}
                                  onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite error loop
                                    e.target.style.display = "none"; // Hide the broken image
                                    e.target.parentNode.innerHTML = `
                                      <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; border: 1px dashed #ccc; border-radius: 4px; font-weight: bold; color: #999;">
                                       No screenshot available
                                      </div>
                                    `;
                                  }}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px dashed #ccc",
                                    borderRadius: 4,
                                    fontWeight: "bold",
                                    color: "#999",
                                  }}
                                >
                                  No screenshot available
                                </Box>
                              )}
                            </Box>

                            <Box sx={{ width: "60%" }}>
                              <Typography variant="h6" mb={2} mt={2}>
                                WHOIS for {lookup.domain}
                              </Typography>
                              <Divider sx={{ my: 2 }} />
                              {[
                                { label: "Domain Name", key: "domain_name" },
                                { label: "Creation Date", key: "creation_date" },
                                { label: "Expiration Date", key: "expiration_date" },
                                { label: "Registrar", key: "registrar" },
                                {
                                  label: "Country",
                                  value: lookup.whois?.country || lookup.whois?.country_code || "N/A",
                                },
                                {
                                  label: "Address",
                                  key: "address",
                                },
                                {
                                  label: "Abuse email",
                                  value:
                                    lookup.whois?.email ||
                                    lookup.whois?.emails ||
                                    lookup.whois?.abuse_email ||
                                    "N/A",
                                },
                              ].map(({ label, key, value }) => (
                                <Box key={label} sx={{ mb: 1 }}>
                                  <Typography variant="body2">
                                    <strong>{label}:</strong> {value || lookup.whois?.[key] || "N/A"}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={sortedAndFilteredRows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50, 100]}
      />
    </Paper>
  );
};

export default AnalyzeResultsTable;


